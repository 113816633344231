import moment from 'moment'

import { IncidentActions, IncidentState } from '../../types/store/incident'

const initialState: IncidentState = {
    incidents: {
        data: [],
        rowCount: 0,
        totalDebited: '00:00',
        total: '00:00',
        isFilterOpen: false,
        contracts: [],
        applications: [],
        loading: false,
        filter: {
            startDate: moment().format('YYYY') + '-01-01',
            endDate: moment().format('YYYY-MM-DD'),
            contract: '',
            application: '',
            rowsPerPage: 10,
            page: 1,
            sortDirection: 'desc',
            sortField: 'date'
        },
    }
}

const incidentReducer = (state = initialState, action: IncidentActions): IncidentState => {
    switch (action.type) {
        case ('INCIDENT_HANDLE_FILTER_CHANGE'): {
            const {name, value} = action.payload

            return { 
                ...state,
                incidents: {
                    ...state.incidents,
                    filter: {
                        ...state.incidents.filter,
                        [name]: value
                    } 
                }
            }
        }

        case ('INCIDENT_HANDLE_PAGE_CHANGE'): {
            return { 
                ...state,
                incidents: {
                    ...state.incidents,
                    filter: {
                        ...state.incidents.filter,
                        page: action.payload + 1
                    } 
                }
            }
        }

        case ('INCIDENT_HANDLE_ROWS_PER_PAGE_CHANGE'): {
            return { 
                ...state,
                incidents: {
                    ...state.incidents,
                    filter: {
                        ...state.incidents.filter,
                        rowsPerPage: action.payload,
                        page: 1
                    } 
                }
            }
        }

        case('INCIDENT_LOAD_INCIDENTS_REQUEST'): {
            return {
                ...state,
                incidents: {
                    ...state.incidents,
                    loading: true
                }
            }
        }

        case ('INCIDENT_LOAD_INCIDENTS_SUCCESS'): {
            const { incidents, totalDebited, total, rowCount } = action.payload

            return {
                ...state,
                incidents: {
                    ...state.incidents,
                    data: incidents,
                    rowCount: rowCount,
                    totalDebited: totalDebited,
                    total: total,
                    loading: false
                }
            }
        }

        case ('INCIDENT_LOAD_INCIDENTS_ERROR'): {
            return {
                ...state,
                incidents: {
                    ...state.incidents,
                    loading: false
                }
            }
        }

        case ('INCIDENT_CLEAR_DATA'): {
            return initialState
        }

        case ('INCIDENT_LOAD_CONTRACTS'): {
            return {
                ...state,
                incidents: {
                    ...state.incidents,
                    contracts: action.payload
                }
            }
        }

        case ('INCIDENT_LOAD_APPLICATIONS'): {
            return {
                ...state,
                incidents: {
                    ...state.incidents,
                    applications: action.payload
                }
            }
        }

        case ('INCIDENT_TOGGLE_FILTER'): {
            return {
                ...state,
                incidents: {
                    ...state.incidents,
                    isFilterOpen: !state.incidents.isFilterOpen
                }
            }
        }

        case ('SET_LOADING'): {
            return {
                ...state,
                incidents: {
                    ...state.incidents,
                    loading: action.payload
                }
            }
        }

        case ('INCIDENT_ORDER'): {
            return {
                ...state,
                incidents: {
                    ...state.incidents,
                    filter: {
                        ...state.incidents.filter,
                        page: 1,
                        sortDirection: action.payload.sortDirection,
                        sortField: action.payload.sortField
                    }
                }
            }
        }

        default:
            return state
    }
}

export default incidentReducer