import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme, {variant: string}>((theme: Theme) => createStyles({
    paper: {
        [theme.breakpoints.down('xl')]: {
            marginTop: theme.spacing(2.5)
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2.5)
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1.1)
        }
    },
    icon: props => ({
        color: props.variant === 'footer' ? theme.palette.grey[600] : theme.palette.grey[300],
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.secondary.main
        }
    })
}))

export default useStyles