import { CONFIG, ENDPOINTS } from '../../constants'
import { UserDownloadResponse } from '../../types/services/userDownload'
import { download, request } from '../axios'

export const userDownloadService = {
    loadDownloads: async () => {
        const response = await request<UserDownloadResponse[]>({
            method: 'GET',
            url   : CONFIG.API_URL + ENDPOINTS.USER_DOWNLOAD
        })

        return response
    },

    download: async (id: number, downloadProgress?: ((progressEvent: ProgressEvent<EventTarget>) => void)) => {
        const response = await download({
            method : 'GET',
            url    : CONFIG.API_URL + ENDPOINTS.USER_DOWNLOAD_DOWNLOAD + '/' + id,
            headers: {
                responseType: 'arraybuffer'
            },
            onDownloadProgress: downloadProgress
        })

        return response
    }
}