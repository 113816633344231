import { makeStyles, createStyles, Theme } from '@material-ui/core'

import { WIDTHS } from '../../constants'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        top: 0,
        position: 'absolute',
        maxWidth: '100%',
        overflow: 'hidden'
    },
    main: {
        [theme.breakpoints.up(WIDTHS.SIDEBAR_OPEN_WINDOW)]: {
            width: 'calc(100vw - 313px)', // window width (100vw) - sidebar open width (296px) - scroll width (17px)
            minHeight: 'calc(100vh - 100px)',
            marginLeft: 296,
            paddingLeft: 17,
            paddingRight: 9,
            marginTop: 100,
            paddingTop: 20,
            paddingBottom: 510 // padding (110px) + footer (400px)
        },
        [theme.breakpoints.down(WIDTHS.SIDEBAR_OPEN_WINDOW)]: {
            width: 'calc(100vw - 116px)', // window width (100vw) - sidebar width (99px) - scroll width (17px)
            minHeight: 'calc(100vh - 100px)',
            marginLeft: 100,
            paddingLeft: 17,
            paddingRight: 9,
            marginTop: 100,
            paddingTop: 20,
            paddingBottom: 510 // padding (110px) + footer (400px)
        },
        [theme.breakpoints.down('md')]: {
            width: '100vw',
            minHeight: 'calc(100vh - 100px)',
            marginLeft: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 100,
            paddingTop: 20,
            paddingBottom: 510 // padding (110px) + footer (400px)
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            minHeight: 'calc(100vh - 80px)',
            marginLeft: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 80,
            paddingTop: 20,
            paddingBottom: 660 // padding (110px) + footer (550px)
        }
    },
    hover: {
        '&:hover': {
            color: '#00adef'
        }
    }
}))

export default useStyles