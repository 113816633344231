import React from 'react'
import clsx from 'clsx'

import { Button } from '@material-ui/core'

import { isDefined } from '../../utils'

import { F3MButtonProps } from './types'

import useStyles from './styles'

const F3MButton = (props: F3MButtonProps) => {

    let buttonProps: F3MButtonProps = {
        // CLASSNAME
        className: isDefined(props.className) ? props.className : '',

        // Default COLOR PRIMARY
        color: isDefined(props.color) ? props.color : 'primary',
    
        // Default NOT DISABLED
        disabled: isDefined(props.disabled) ? props.disabled : false,
    
        // Default DISABLE ELEVATION TRUE
        disableElevation: isDefined(props.disableElevation) ? props.disableElevation : false,
    
        // Default NOT DISABLE FOCUS RIPPLE
        disableFocusRipple: isDefined(props.disableFocusRipple) ? props.disableFocusRipple : false,
    
        // Default ENDICON UNDEFINED
        endIcon: isDefined(props.endIcon) ? props.endIcon : undefined,
    
        // Default FULLWIDTH
        fullWidth: isDefined(props.fullWidth) ? props.fullWidth : true,
    
        // Default HREF UNDEFINED
        href: isDefined(props.href) ? props.href : undefined,

        // ON CLICK
        onClick: props.onClick,
    
        // Default SIZE SMALL
        size: isDefined(props.size) ? props.size : 'small',
    
        // Default STARTICON UNDEFINED
        startIcon: isDefined(props.startIcon) ? props.startIcon : undefined,
    
        // Default VARIANT CONTAINED
        variant: isDefined(props.variant) ? props.variant : 'contained',
    
        // Default NOT BOLD
        bold: isDefined(props.bold) ? props.bold : false,
    
        // Defaul TYPE BUTTON
        type: isDefined(props.type) ? props.type : 'button'
    }

    const classes = useStyles(buttonProps)

    return(
        <Button
            className          = {clsx(buttonProps.className, classes.root)}
            disabled           = {buttonProps.disabled}
            disableElevation   = {buttonProps.disableElevation}
            disableFocusRipple = {buttonProps.disableFocusRipple}
            endIcon            = {buttonProps.endIcon}
            fullWidth          = {buttonProps.fullWidth}
            href               = {buttonProps.href}
            size               = {buttonProps.size}
            startIcon          = {buttonProps.startIcon}
            variant            = {buttonProps.variant}
            type               = {buttonProps.type}
            onClick            = {buttonProps.onClick}
            classes            = {{
                contained: classes.contained,
                outlined : classes.outlined,
                text     : classes.text,
                disabled : classes.disabled
            }}
        >
            {props.children}
        </Button>
    )
}

export default F3MButton
