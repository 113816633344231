import { request } from '..'
import { CONFIG, ENDPOINTS } from '../../constants'
import { ApplicationResponse } from '../../types/services/application'

export const applicationService = {
    loadApplications: async () => {
        const response = await request<ApplicationResponse[]>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.APPLICATIONS
        })

        return response
    },

    loadApplication: async (applicationName: string) => {
        const response = await request<ApplicationResponse>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.APPLICATIONS + '/' + applicationName
        })

        return response
    },

    loadApplicationVersions: async (applicationId: string) => {
        const response = await request<string[]>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.APPLICATIONVERSIONS,
            params: { applicationId: applicationId }
        })

        return response
    }
}