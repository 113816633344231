import { CONFIG, ENDPOINTS } from '../../constants'
import { SlideResponse, CarouselResponse } from '../../types/services/carousel'
import { AddUpdtSlide } from '../../types/services/carousel/AddUpdtSlide'
import { CarouselFilter } from '../../types/services/carousel/CarouselFilter'
import { request, download } from '../axios'

export const carouselService = {
    loadCarouselLogin: async () => {
        const response = await request<SlideResponse[]>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.CAROUSELLOGIN
        })

        return response
    },

    loadCarouselManagement: async (filter: CarouselFilter) => {
        const response = await request<CarouselResponse>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.CAROUSELMANAGEMENT,
            params: filter
        })

        return response
    },

    loadSlideImage: async (imagePath: string) => {
        const response = await download({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.SLIDEIMAGE,
            params: {
                imagePath: imagePath
            }
        })

        return response
    },

    addorUpdtSlide: async (slide: AddUpdtSlide) => {
        const formData: FormData = new FormData()

        if (slide.image !== null && slide.newImage === true) {
            formData.append('imageFile', slide.image[0])
        }

        formData.append('id', slide.id ? (slide.id).toString() : '')
        formData.append('status', (slide.status).toString())
        formData.append('order', slide.order ? (slide.order).toString() : '')
        formData.append('title', slide.title)
        formData.append('description', slide.description ? slide.description : '')
        formData.append('buttonText', slide.buttonText ? slide.buttonText : '')
        formData.append('buttonLink', slide.buttonLink ? slide.buttonLink : '')
        formData.append('buttonColor', slide.buttonColor ? slide.buttonColor : '')

        const response = await request({
            method: 'POST',
            url: CONFIG.API_URL + ENDPOINTS.CAROUSELADDORUPDTSLIDE,
            data: formData
        })

        return response
    },

    setSlideStatus: async (id: string, newStatus: boolean, rowOrder: number | null) => {
        const response = await request({
            method: 'PUT',
            url: CONFIG.API_URL + ENDPOINTS.SETSLIDESTATUS,
            data: {
                id: id,
                newStatus: newStatus,
                rowOrder: rowOrder
            }
        })

        return response
    },

    deleteSlide: async (id: string, isActive: boolean, order: number | null) => {
        const response = await request({
            method: 'DELETE',
            url: CONFIG.API_URL + ENDPOINTS.DELETESLIDE,
            data: {
                id: id,
                status: isActive,
                order: order
            }
        })

        return response
    }
}