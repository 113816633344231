import axios from 'axios'

const api = axios.create({
    headers: {
        'Cache-Control': 'no-store, no-cache, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
    }
})

export default api