import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { Typography } from '@material-ui/core'

import { F3MBreadcrumbs, F3MDownload, F3MDataGrid, F3MDataGridColumnProps, F3MDataGridRowData, F3MCard, F3MSnackbar, F3MSnackbarState } from '../../../../components'

import { ApplicationState } from '../../../../types/store'
import { downloadOperations } from '../../../../store/operations'

import i18n from '../../../../i18n'
import { isDefined } from '../../../../utils'

import { userDownloadService } from '../../../../api'

const mapStateToProps = (state: ApplicationState) => ({
    downloadState: state.downloads
})

const mapDispatchToProps = (dispatch: any) => ({
    loadDownloads: () => dispatch(downloadOperations.loadDownloads()),
    clearData: () => dispatch(downloadOperations.clearData())
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const Downloads = (props: Props) => {
    const [snackbar, setSnackbar] = useState<F3MSnackbarState>({
        open: false,
        severity: 'info',
        message: ''
    })
    const [isDownloading, setIsDownloading] = useState<boolean>(false)

    const { downloads } = props.downloadState!

    const handleError = (message: string) => {
        setSnackbar({ open: true, severity: 'error', message: message })
    }

    useEffect(() => {
        // On Mount
        props.loadDownloads()

        // On Unmount
        return () => {
            props.clearData()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    let columns: F3MDataGridColumnProps[] = [
        { field: 'description', headerName: i18n.t('common.description'), width: 50, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'date', headerName: i18n.t('common.date'), width: 20, sortable: true, type: 'date', align: 'right', headerAlign: 'right' },
        { field: 'download', headerName: i18n.t('common.download'), width: 30, sortable: false, type: 'node', align: 'center', headerAlign: 'center' }
    ]

    let data: F3MDataGridRowData[] = isDefined(downloads.data) ? downloads.data.map((r, i) => ({
        id: r.id,
        description: r.description,
        date: r.date,
        download: <F3MDownload handleError={handleError} filename={r.fileName} isLoading={isDownloading} setLoading={setIsDownloading} request={() => userDownloadService.download(r.id!)} />,
    })) : []

    return (
        <>
            <F3MBreadcrumbs path={i18n.t('paths.downloads.breadcrumb')} />
            <F3MCard loading={downloads.loading || isDownloading}>
                <Typography variant='h5' color='primary' gutterBottom>{i18n.t('downloads.availableDownloads')}</Typography>
                <F3MDataGrid
                    columns={columns}
                    rows={data}
                    loading={downloads.loading}
                    pagination={true}
                    paginationMode='client'
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    sortMode='client'
                    sortField='description'
                    sortDirection='asc'
                />
            </F3MCard>
            <F3MSnackbar
                setSnackbar={setSnackbar}
                snackbar={snackbar}
            />
        </>
    )
}

export default connector(Downloads)