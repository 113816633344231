import { ContractState, ContractActions } from '../../types/store/contract'

const initialState: ContractState = {
    updateContracts: {
        data: [],
        loading: false
    },
    assistanceContracts: {
        data: [],
        loading: false
    }
}

const contractReducer = (state = initialState, action: ContractActions): ContractState => {
    switch (action.type) {
        case ('CONTRACT_LOAD_SERVICE_CONTRACTS_REQUEST'): {
            return {
                ...state,
                updateContracts: {
                    ...state.updateContracts,
                    loading: true
                }
            }
        }

        case ('CONTRACT_LOAD_SERVICE_CONTRACTS_SUCCESS'): {
            return {
                ...state,
                updateContracts: {
                    ...state.updateContracts,
                    data: action.payload,
                    loading: false
                }
            }
        }

        case ('CONTRACT_LOAD_SERVICE_CONTRACTS_ERROR'): {
            return {
                ...state,
                updateContracts: {
                    ...state.updateContracts,
                    loading: false
                }
            }
        }

        case ('CONTRACT_LOAD_ASSISTANCE_CONTRACTS_REQUEST'): {
            return {
                ...state,
                assistanceContracts: {
                    ...state.assistanceContracts,
                    loading: true
                }
            }
        }

        case ('CONTRACT_LOAD_ASSISTANCE_CONTRACTS_SUCCESS'): {
            return {
                ...state,
                assistanceContracts: {
                    ...state.assistanceContracts,
                    data: action.payload,
                    loading: false
                }
            }
        }

        case ('CONTRACT_LOAD_ASSISTANCE_CONTRACTS_ERROR'): {
            return {
                ...state,
                assistanceContracts: {
                    ...state.assistanceContracts,
                    loading: false
                }
            }
        }

        case ('CONTRACT_CLEAR_DATA'): {
            return initialState
        }

        default: {
            return state
        }
    }
}

export default contractReducer