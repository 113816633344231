import { CommonState, CommonActions } from '../../types/store/common'


const initialState: CommonState = {
    sidebarOpen: false,
    sidebarSoftwareOpen: false,
    sidebarServicesOpen: false,
    sidebarBackofficeOpen: false,
    logoutConfirmationOpen: false
}

const commonReducer = (state = initialState, action: CommonActions): CommonState => {
    switch (action.type) {

        case ('COMMON_TOGGLE_SIDEBAR'): {
            return {
                ...state,
                sidebarOpen: !state.sidebarOpen
            }
        }

        case ('COMMON_TOGGLE_SOFTWARE_SIDEBAR'): {
            return {
                ...state,
                sidebarSoftwareOpen: !state.sidebarSoftwareOpen
            }
        }

        case ('COMMON_TOGGLE_SERVICE_SIDEBAR'): {
            return {
                ...state,
                sidebarServicesOpen: !state.sidebarServicesOpen
            }
        }

        case ('COMMON_TOGGLE_BACKOFFICE_SIDEBAR'): {
            return {
                ...state,
                sidebarBackofficeOpen: !state.sidebarBackofficeOpen
            }
        }

        case ('COMMON_TOGGLE_LOGOUT_CONFIRMATION'): {
            return {
                ...state,
                logoutConfirmationOpen: !state.logoutConfirmationOpen
            }
        }

        default:
            return state
    }
}

export default commonReducer