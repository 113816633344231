import { servCatalogService } from '../../api'
import { AppThunkAction } from '../../types/store'
import { ServCatalogActions } from '../../types/store/servCatalog'
import servCatalogActions from '../actions/servCatalogActions'

const {
    loadServCatalog
} = servCatalogActions

const servCatalogOperations = {
    loadServCatalogUrl: (): AppThunkAction<ServCatalogActions> => async (dispatch, getState) => {
            const response = await servCatalogService.getServCatalogUrl()

            dispatch(loadServCatalog(response))
    }
}

export default servCatalogOperations