import React, { Dispatch, SetStateAction } from 'react'

import { IconButton, Switch } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import { DownloadsManagerFields, SortDirection } from '../../../../types'
import { DownloadManagerFilter, DownloadsManagerResponse } from '../../../../types/services/downloadsManager'
import { F3MDataGrid, F3MDataGridColumnProps, F3MDataGridRowData } from '../../../../components'

import i18n from '../../../../i18n'

import globalSoftwareStyles from '../../styles'

interface ContentProps {
    fetchedDownloads: DownloadsManagerResponse
    handleChangeStatus: (checked: boolean, id: number) => Promise<void>
    handleDeleteBtn: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => void
    filter: DownloadManagerFilter
    setFilter: Dispatch<SetStateAction<DownloadManagerFilter>>
    loading: boolean
    handleAddUpdtModal: (type: string, event: React.MouseEvent<HTMLTableRowElement, MouseEvent> | null) => void
}

const Table = (props: ContentProps) => {
    const globalClasses = globalSoftwareStyles()

    const {
        fetchedDownloads,
        handleChangeStatus,
        handleDeleteBtn,
        filter,
        setFilter,
        loading,
        handleAddUpdtModal
    } = props

    const handleSortChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        const id = e.currentTarget.parentElement!.id

        const { sortDirection, sortField } = filter
        let newSortDirection = 'desc'
        let newField = 'date'

        if (sortField === id) {
            newField = sortField
            if (sortDirection === 'asc') {
                newSortDirection = 'desc'
            } else {
                newSortDirection = 'asc'
            }
        } else {
            newSortDirection = 'asc'
            newField = id
        }

        setFilter(prevState => ({
            ...prevState,
            sortDirection: newSortDirection as SortDirection,
            sortField: newField as DownloadsManagerFields
        }))
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        setFilter(prevState => ({
            ...prevState,
            page: page + 1
        }))
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilter(prevState => ({
            ...prevState,
            page: 1,
            rowsPerPage: parseInt(event.target.value)
        }))
    }

    let columns: F3MDataGridColumnProps[] = [
        { field: 'clientName', headerName: i18n.t('downloads.clientName'), width: 30, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'description', headerName: i18n.t('common.description'), width: 30, sortable: false, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'date', headerName: i18n.t('common.date'), width: 5, sortable: true, type: 'date', align: 'right', headerAlign: 'right' },
        { field: 'fileName', headerName: i18n.t('common.fileName'), width: 10, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'isActive', headerName: i18n.t('common.state'), width: 5, sortable: true, type: 'node', align: 'center', headerAlign: 'center' },
        { field: 'delete', headerName: i18n.t('button.remove'), width: 5, sortable: false, type: 'node', align: 'center', headerAlign: 'center' }
    ]

    let data: F3MDataGridRowData[] = fetchedDownloads.downloads.map((r, index) => ({
        id: index,
        downloadId: r.id,
        clientId: r.clientId,
        clientName: r.clientName,
        description: r.description,
        date: r.date,
        fileName: r.fileName,
        isActive: <span onClick={(e) => e.stopPropagation()}>
            <Switch checked={r.isActive} onChange={(e, c) => handleChangeStatus(c, r.id)} color='primary' size='small' />
            {i18n.t('common.active')}
        </span>,
        delete: <IconButton className={globalClasses.hover} onClick={(e) => handleDeleteBtn(e, r.id)} size='small'><DeleteIcon /></IconButton>
    }))

    return (
        <F3MDataGrid
            columns={columns}
            rows={data}
            loading={loading}
            hover={true}
            rowClick={(e) => handleAddUpdtModal('edit', e)}
            sortMode='server'
            sortDirection={filter.sortDirection}
            sortField={filter.sortField}
            sortChange={handleSortChange}
            pagination={true}
            paginationMode='server'
            rowCount={fetchedDownloads.rowCount}
            page={filter.page}
            pageSize={filter.rowsPerPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    )
}

export default Table