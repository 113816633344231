import thunk from 'redux-thunk'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { History } from 'history'

import { isDev } from '../utils'
import { ApplicationState } from '../types/store'

import {
    commonReducer,
    contractReducer,
    downloadReducer,
    incidentReducer,
    licensingReducer,
    loginReducer,
    reportReducer,
    servCatalogReducer,
    appDownloadReducer
} from './reducers'

const configureStore = (history: History, initialState?: ApplicationState) => {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ]

    const reducers = {
        common      : commonReducer,
        contracts   : contractReducer,
        downloads   : downloadReducer,
        incidents   : incidentReducer,
        licensing   : licensingReducer,
        login       : loginReducer,
        reports     : reportReducer,
        servCatalog : servCatalogReducer,
        appDownload : appDownloadReducer
    }

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    })

    const enhancers = []
    const windowIfDefined = typeof window === 'undefined' ? null : window as any
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ && isDev()) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 }))
    }

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    )
}

export default configureStore