import React, { Dispatch, SetStateAction } from 'react'

import { IconButton, Switch } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import { F3MDataGrid, F3MDataGridColumnProps, F3MDataGridRowData } from '../../../../../../components'

import { ApplicationDownloadFilter, ApplicationDownloadsResponse } from '../../../../../../types/services/applicationDownload'
import { ApplicationDownloadFields, SortDirection } from '../../../../../../types'

import i18n from '../../../../../../i18n'

import globalSoftwareStyles from '../../../../styles'

interface ContentProps {
    applicationDownloads: ApplicationDownloadsResponse
    handleChangeStatus: (checked: boolean, id: number) => Promise<void>
    handleDeleteBtn: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => void
    filter: ApplicationDownloadFilter
    setFilter: Dispatch<SetStateAction<ApplicationDownloadFilter>>
    loading: boolean
    handleAddUpdtModal: (type: string, event: React.MouseEvent<HTMLTableRowElement, MouseEvent> | null) => void
}

const Table = (props: ContentProps) => {
    const globalClasses = globalSoftwareStyles()

    const {
        applicationDownloads,
        handleChangeStatus,
        handleDeleteBtn,
        filter,
        setFilter,
        loading,
        handleAddUpdtModal
    } = props

    const handleSortChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        const id = e.currentTarget.parentElement!.id

        const { sortDirection, sortField } = filter
        let newSortDirection = 'desc'
        let newField = 'date'

        if (sortField === id) {
            newField = sortField
            if (sortDirection === 'asc') {
                newSortDirection = 'desc'
            } else {
                newSortDirection = 'asc'
            }
        } else {
            newSortDirection = 'asc'
            newField = id
        }

        setFilter(prevState => ({
            ...prevState,
            sortDirection: newSortDirection as SortDirection,
            sortField: newField as ApplicationDownloadFields
        }))
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        setFilter(prevState => ({
            ...prevState,
            page: page + 1
        }))
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilter(prevState => ({
            ...prevState,
            page: 1,
            rowsPerPage: parseInt(event.target.value)
        }))
    }

    let columns: F3MDataGridColumnProps[] = [
        { field: 'applicationName', headerName: i18n.t('common.application'), width: 20, sortable: false, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'version', headerName: i18n.t('common.version'), width: 10, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'date', headerName: i18n.t('common.date'), width: 10, sortable: true, type: 'date', align: 'right', headerAlign: 'right' },
        { field: 'fileName', headerName: i18n.t('common.fileName'), width: 10, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'downloadType', headerName: i18n.t('common.type'), width: 10, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'isActive', headerName: i18n.t('common.state'), width: 10, sortable: true, type: 'node', align: 'center', headerAlign: 'center' },
        { field: 'delete', headerName: i18n.t('button.remove'), width: 10, sortable: false, type: 'node', align: 'center', headerAlign: 'center' }
    ]

    let data: F3MDataGridRowData[] = applicationDownloads.applications.map((r, index) => ({
        id: index,
        applicationDownloadId: r.id,
        applicationId: r.applicationId,
        applicationName: r.applicationName,
        version: r.version,
        date: r.date,
        fileName: r.fileName,
        downloadType: i18n.t('enum.applicationDownload.type.' + r.downloadType),
        isActive: <span onClick={(e) => e.stopPropagation()}>
            <Switch checked={r.isActive} onChange={(e, c) => handleChangeStatus(c, r.id)} color='primary' size='small' />
            {i18n.t('common.active')}
        </span>,
        delete: <IconButton className={globalClasses.hover} onClick={(e) => handleDeleteBtn(e, r.id)} size='small'><DeleteIcon /></IconButton>
    }))

    return (
        <F3MDataGrid
            columns={columns}
            rows={data}
            loading={loading}
            hover={true}
            rowClick={(e) => handleAddUpdtModal('edit', e)}
            sortMode='server'
            sortDirection={filter.sortDirection}
            sortField={filter.sortField}
            sortChange={handleSortChange}
            pagination={true}
            paginationMode='server'
            rowCount={applicationDownloads.rowCount}
            page={filter.page}
            pageSize={filter.rowsPerPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    )
}

export default Table