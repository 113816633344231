import { push } from 'connected-react-router'

import { store } from '../..'
import { accountService, authService, carouselService } from '../../api'
import { AppThunkAction } from '../../types/store'
import { LoginActions } from '../../types/store/login'
import loginActions from '../actions/loginActions'

const {
    closeExpiredSnackbar,
    handleChange,
    loadCarouselError,
    loadCarouselRequest,
    loadCarouselSuccess,
    loginError,
    loginRequest,
    loginSuccess,
    logoutError,
    logoutRequest,
    logoutSuccess,
    refreshTokenError,
    refreshTokenRequest,
    refreshTokenSuccess,
    setUser,
    clearData
} = loginActions

const loginOperations = {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>): AppThunkAction<LoginActions> => async (dispatch, getState) => {
            const { name, value } = event.target
            
            dispatch(handleChange(name, value))
    },

    login: (event: React.FormEvent): AppThunkAction<LoginActions> => async (dispatch, getState) => {
        dispatch(loginRequest())

        try {
            event.preventDefault()
            const {username, password} = getState().login!
            const response = await authService.tokenCreate({username, password})
            
            if(response.isSuccess) {
                dispatch(loginSuccess(response.result))
            } else {
                dispatch(loginError(response.errorMessage))
            }
        } catch(e) {
            dispatch(loginError(e.response))
        }
    },

    refreshToken: (): AppThunkAction<LoginActions> => async (dispatch, getState) => {
        dispatch(refreshTokenRequest())

        try {
            const request = { refreshToken: getState().login?.tokenRefresh! }
            const response = await authService.tokenRefresh(request)

            if(response.isSuccess) {
                dispatch(refreshTokenSuccess(response.result))
            } else {
                dispatch(refreshTokenError(response.errorMessage))
            }
        } catch(e) {
            dispatch(refreshTokenError(e.response))
        }
    },

    logout: (): AppThunkAction<LoginActions> => async (dispatch, getState) =>{
        dispatch(logoutRequest())
        
        try {
            const response = await authService.tokenRevoke()

            if(response.isSuccess) {
                dispatch(logoutSuccess())
            } else {
                dispatch(logoutError(response.errorMessage))
            }
            store.dispatch(push('/'))
        } catch(e) {
            dispatch(logoutError(e.response))
            store.dispatch(push('/'))
        }
    },

    loadCarousel: (): AppThunkAction<LoginActions> => async (dispatch, getState) => {
        dispatch(loadCarouselRequest())

        try {
            const response = await carouselService.loadCarouselLogin()

            dispatch(loadCarouselSuccess(response.result))
        } catch(e) {
            dispatch(loadCarouselError(e.response))
        }
        
    },

    closeExpiredSnackbar: (e?: React.SyntheticEvent, r?: string): AppThunkAction<LoginActions> => async (dispatch, getState) => {
        if(r !== 'clickaway') {
            dispatch(closeExpiredSnackbar())
        }
    },

    setUser:  (): AppThunkAction<LoginActions> => async (dispatch, getState) => {
        try {
            const account = await accountService.accountInfo()

            if(account.isSuccess) {
                dispatch(setUser(account.result))
            } else {
                dispatch(setUser({name: '', role: ''}))
            }
        } catch(e) {
            dispatch(setUser({name: '', role: ''}))
        }
        
    },

    clearData: (): AppThunkAction<LoginActions> => async (dispatch, getState) => {
        dispatch(clearData())
    }
}

export default loginOperations