import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import clsx from 'clsx'

import { SwipeableDrawer } from '@material-ui/core'

import SidebarContent from '../Content'

import { ApplicationState } from '../../../types/store'
import commonOperations from '../../../store/operations/commonOperations'

import useStyles from './styles'

const mapStateToProps = (state: ApplicationState) => ({
    commonState: state.common
})

const mapDispatchToProps = (dispatch: any) => ({
    toggleSidebar: (e: any) => dispatch(commonOperations.toggleSidebar(e))
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const MobileSidebar = (props: Props) => {
    const classes = useStyles()

    const {sidebarOpen} = props.commonState!

    return (
        <SwipeableDrawer
            className = {clsx(classes.drawerRoot, {
                [classes.drawerClosed]: !sidebarOpen
            })}
            classes   = {{
                paper: clsx({
                    [classes.drawerRoot] : sidebarOpen,
                    [classes.drawerClosed]: !sidebarOpen
                })
            }}
            PaperProps = {{ elevation: 3 }}
            open       = {sidebarOpen}
            onClose    = {props.toggleSidebar}
            onOpen     = {props.toggleSidebar}
        >
            <SidebarContent/>
        </SwipeableDrawer>
    )
}

export default connector(MobileSidebar)