import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { push } from 'connected-react-router'

import { Button, Grid, Typography } from '@material-ui/core'

import useStyles from './styles'
import { ROUTES } from '../../../../constants';
import i18n from '../../../../i18n';
import { F3MCard } from '../../../../components';
import { contractsService } from '../../../../api';
import { AssistanceContractsResponse, ServiceContractsResponse } from '../../../../types/services/contracts';
import { ApiResponse } from '../../../../types/api';

const mapDispatchToProps = (dispatch: any) => ({
    push: (p: string) => dispatch(push(p))
})

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

interface Plan {
    planName: string
    ends: string
}

const QuickAccessBtns = (props: Props & { handleError: (message: string) => void }) => {
    const classes = useStyles()

    const [plans, setPlans] = useState<Plan[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const { SERVICES } = ROUTES

    const {
        handleError
    } = props

    const loadContracts = async () => {
        setLoading(true)
        try {
            const responseAssistCont: ApiResponse<AssistanceContractsResponse[]> = await contractsService.loadAssistanceContracts(3)

            if (responseAssistCont.isSuccess) {
                const assistContParsed: Plan[] = responseAssistCont.result.map(elem => {
                    return { planName: elem.plan, ends: elem.endDate.toString().split("T")[0] }
                })
                setPlans(prevState => [...prevState, ...assistContParsed])
            } else {
                handleError(i18n.t('error.unexpectedError'))
            }

            const planCount: number = responseAssistCont.result.length
            if (planCount < 3) {
                const responseServContr: ApiResponse<ServiceContractsResponse[]> = await contractsService.loadServiceContracts(3 - planCount)

                if (responseServContr.isSuccess) {
                    const assistContParsed: Plan[] = responseServContr.result.map(elem => {
                        return { planName: elem.description, ends: elem.endDate.toString().split("T")[0] }
                    })
                    setPlans(prevState => ([...prevState, ...assistContParsed]))

                } else {
                    handleError(i18n.t('error.unexpectedError'))
                }
            }

        }
        catch (e) {
            handleError(i18n.t('error.unexpectedError'))
        }
        finally {
            setLoading(false)
        }
    }

    const getBackColor = (date: string) => {
        const actMonth = new Date().getMonth() + 1
        const dateMonth = new Date(date).getMonth() + 1
        const actYear = new Date().getFullYear()
        const dateYear = new Date(date).getFullYear()
        return (dateYear < actYear || dateMonth <= actMonth) ?  '#f7931d' : '#dadada'
    }

    const getColor = (date: string) => {
        const actMonth = new Date().getMonth() + 1
        const dateMonth = new Date(date).getMonth() + 1
        const actYear = new Date().getFullYear()
        const dateYear = new Date(date).getFullYear()
        return (dateYear < actYear || dateMonth <= actMonth) ? '#ffffff' : '#666666'
    }

    useEffect(() => {
        loadContracts()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <F3MCard style={{ height: '350px', position: 'relative' }} loading={loading}>
            <Typography variant='h5' className={classes.cardTitleOrange} gutterBottom>{i18n.t('homepage.plans')}</Typography>
            <Grid style={{ padding: '16px 0 16px 0' }} container direction='column' alignItems='center'>
                <Grid style={{ paddingBottom: '1vmax' }} container justify='space-between' alignItems='center' spacing={2}>
                    <Grid item xs={6} md={7}>
                        <Typography className={classes.plansHeaders} variant='body1' gutterBottom>{i18n.t('homepage.planName')}</Typography>
                    </Grid>
                    <Grid style={{ textAlign: 'center' }} item xs={6} md={5}>
                        <Typography className={classes.plansHeaders} variant='body1' gutterBottom>{i18n.t('homepage.ends')}</Typography>
                    </Grid>
                </Grid>
                {plans.map((elem, index) => (
                    <div key={index} className={classes.plansContent}>
                        <Grid style={{ margin: '5px', width: 'initial' }} container justify='space-between' alignItems='center' spacing={2}>
                            <Grid item xs={6} md={7}>
                                <Typography className={classes.plansContentName} variant='body2' gutterBottom>{elem.planName}</Typography>
                            </Grid>
                            <Grid item xs={6} md={5} className={classes.plansContentEnds}
                                style={{ backgroundColor: getBackColor(elem.ends), color: getColor(elem.ends) }}>
                                <Typography style={{ lineHeight: '25px' }} variant='body2' gutterBottom>{elem.ends}</Typography>
                            </Grid>
                        </Grid>
                    </div>
                ))}
            </Grid>
            <Button className={classes.buttonSeeMore} onClick={() => props.push(SERVICES.CONTRACTS.PATH)}>
                {i18n.t('homepage.seeMore')}
            </Button>
        </F3MCard>
    )
}

export default connector(QuickAccessBtns)