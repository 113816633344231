import { makeStyles, createStyles, Theme } from '@material-ui/core'

import { WIDTHS } from '../../../constants'

const useStyles = makeStyles((theme: Theme) => createStyles({
    drawerRoot: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
        width: WIDTHS.DESKTOP_DRAWER,
        [theme.breakpoints.down('lg')]: {
            width: WIDTHS.TABLET_DRAWER
        },
        [theme.breakpoints.down('xs')]: {
            width: WIDTHS.MOBILE_DRAWER
        },
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.short
        }),
        boxShadow: theme.shadows[3],
        zIndex: theme.zIndex.drawer
    },
    drawerClosed: {
        overflowX: 'hidden',
        width: WIDTHS.DESKTOP_DRAWER_CLOSED,
        [theme.breakpoints.up(WIDTHS.SIDEBAR_OPEN_WINDOW)]: {
            width: WIDTHS.DESKTOP_DRAWER
        },
        [theme.breakpoints.down('sm')]: {
            width: 0
        },
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.short
        }),
        boxShadow: theme.shadows[2]
    },
    sidebarToggleFab: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        boxShadow: 'none',
        border: 'solid 1px ' + theme.palette.warning.main,
        position: 'fixed',
        transition: theme.transitions.create(['transform', 'translate'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.short
        }),
        [theme.breakpoints.up(WIDTHS.SIDEBAR_OPEN_WINDOW)]: {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)'
        }
    },
    sidebarToggleFabOpen: {
        transform: 'rotate(0deg) translate(277px, calc(50vh + 100px)) rotate(180deg)'
    },
    sidebarToggleFabClose: {
        transform: 'rotate(0deg) translate(80px, calc(50vh + 100px)) rotate(0deg)'
    },
    sidebarToggleIcon: {
        width: 32,
        height: 32,
        color: theme.palette.primary.main
    }
}))

export default useStyles