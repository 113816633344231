import { Theme, makeStyles, createStyles} from '@material-ui/core'

import { F3MSelectProps } from './types'

const useStyles = makeStyles<Theme, F3MSelectProps>((theme: Theme) => createStyles({
    formControl: props => ({
        marginBottom: props.variant === 'standard' ? '9px': 'unset',
        color       : theme.palette.grey[600],
        '&.Mui-focused': {
            backgroundColor: theme.palette.common.white
        }
    }),
    inputRoot: props => ({
        color     : theme.palette.grey[600],
        opcacity  : 0,
        fontWeight: props.bold ? 700 : 'normal'
    }),
    inputRootDisabled: {},
    select: props => ({
        color     : theme.palette.grey[600],
        fontWeight: props.bold ? 700 : 'normal',
        '&> .MuiSelect-select': {
            color: theme.palette.grey[700]
        },
        '&> .MuiSelect-select:focus': {
            backgroundColor: theme.palette.common.white
        }
    }),
    menuPaper: {
        maxHeight: 128 // menu padding top (8px) + 4 menu items (30px * 4)
    },
    menuItem: {
        '&.Mui-selected, &.Mui-selected:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main
        },
        '&:hover': {
            color: theme.palette.grey['A700'],
            backgroundColor: theme.palette.grey['200']
        }
      }
}))

export default useStyles