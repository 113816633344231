import { ServCatalogActions, ServCatalogState } from "../../types/store/servCatalog"

const initialState: ServCatalogState = {
    servCatalogBlob: new Blob()
}

const servCatalogReducer = (state = initialState, action: ServCatalogActions): ServCatalogState => {
    switch (action.type) {
        case ('SERVICES_CATALOG_URL'): {
            return {
                ...state,
                servCatalogBlob: action.payload
            }
        }

        default:
            return state
    }
}

export default servCatalogReducer