import React from 'react'

import { InputAdornment, Link, Typography, Grid, IconButton, Popover, Box } from '@material-ui/core'

import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

import { F3MButton, F3MTextField } from '../../../components/'

import i18n from '../../../i18n'

import LoginFormProps from './types'

import useStyles from './styles'


const LoginForm = (props: LoginFormProps) => {
    const classes = useStyles()

    const {username, password, handleChange, handleSubmit} = props

    const [passwordVisible, setPasswordVisibility] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const togglePassword = () => {
        setPasswordVisibility(!passwordVisible)
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <form noValidate onSubmit={handleSubmit} className={classes.form}>
            <F3MTextField
                id       = 'username'
                label    = {i18n.t('login.username')}
                name     = 'username'
                onChange = {handleChange}
                value    = {username}
            />
            <F3MTextField
                name        = 'password'
                label       = {i18n.t('login.password')}
                type        = {passwordVisible ? 'text' : 'password'}
                id          = 'password'
                onChange    = {handleChange}
                value       = {password}
                InputProps  = {{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton onClick={togglePassword} color='primary'>
                                {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <Grid container direction='row' justify='flex-end' alignItems='center'>
                <Grid item>
                    <Link
                        href      = '#'
                        onClick   = {handleClick}
                        align     = 'right'
                        color     = 'textSecondary'
                        underline = 'none'
                        variant   = 'body2'
                    >
                        {i18n.t('login.forgotPassword')}
                    </Link>
                    <Popover
                        open         = {open}
                        anchorEl     = {anchorEl}
                        onClose      = {handleClose}
                        anchorOrigin = {{
                            vertical  : 'bottom',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical  : 'top',
                            horizontal: 'left'
                        }}
                    >
                        <Box maxWidth={270} padding={2}>
                            <Typography>
                                {i18n.t('login.forgotPasswordPopover')}
                                <Typography color='primary' component='label'> contacto@f3m.pt</Typography>
                            </Typography>
                        </Box>
                    </Popover>
                </Grid>
            </Grid>
            <F3MButton
                className = {classes.button}
                type      = 'submit'
                size      = 'large'
            >
                {i18n.t('login.loginButton')}
            </F3MButton>
        </form>
    )
}

export default LoginForm