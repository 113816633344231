import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    icons: {
        'color': theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main
        }
    }
}))

export default useStyles