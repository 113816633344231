import i18n from "../i18n"
import { ApplicationDownloadUpdtCreate, FilesStruct as FilesStructAppDown } from "../types/services/applicationDownload"
import { BusinessArea } from "../types/services/backoffice"
import { DownloadCategoryUpdtCreate } from "../types/services/downloadCategories"
import { DownloadCategoryDownloadsUpdtCreate, FilesStruct as FilesStructDownCat } from "../types/services/downloadCategoryDownloads"
import { DownloadManagerUpdtCreate } from "../types/services/downloadsManager"

const getAllBusAreasName = (businessAreas: BusinessArea[]) => {
    return businessAreas.map(busArea => i18n.t(busArea.name)).join(' + ')
}

const getAllBusAreasCode = (businessAreas: BusinessArea[]) => {
    return businessAreas.map(busArea => busArea.id).join('+')
}

export const buildBusAreasItemsToSelect = (businessAreas: [BusinessArea[]]) => {
    return businessAreas.map(busArea => { return { id: getAllBusAreasCode(busArea), label: getAllBusAreasName(busArea) } })
}

const isCategoryNameDefined = (name: string | undefined) => {
    return name !== undefined && name.length > 0
}

const isBusinessAreaDefined = (businessArea: BusinessArea[] | undefined) => {
    return businessArea !== undefined && businessArea.length > 0
}

const isDownloadTypeDefined = (downloadType: number | undefined) => {
    return downloadType !== undefined
}

const isCardTypeDefined = (cardType: number | undefined) => {
    return cardType !== undefined
}

export const isCategoryDownloadValid = (addOrUpdt: DownloadCategoryUpdtCreate) => {
    return (isCategoryNameDefined(addOrUpdt.name)
        && isBusinessAreaDefined(addOrUpdt.businessAreas)
        && isDownloadTypeDefined(addOrUpdt.downloadType)
        && isCardTypeDefined(addOrUpdt.cardType))
}

const isCategoryDefined = (categoryId: number | null) => {
    return categoryId !== null
}

const isFileAndDescDefined = (isNewFile: boolean, files: FilesStructDownCat | null) => {
    return isNewFile === false || (files !== null && Object.keys(files).length > 0)
}

export const isDownloadCategoryDownloadValid = (addOrUpdt: DownloadCategoryDownloadsUpdtCreate) => {
    return (isCategoryDefined(addOrUpdt.categoryId)
        && isFileAndDescDefined(addOrUpdt.isNewFile, addOrUpdt.files))
}

const isFileDefined = (isNewFile: boolean | undefined, file: File | undefined, files: FilesStructAppDown | undefined) => {
    return (isNewFile === false
        || file !== undefined
        || (files !== undefined && Object.keys(files).length > 0))
}

const isApplicationDefined = (applicationId: string | undefined, applicationName: string | undefined) => {
    return applicationId !== undefined && applicationName !== undefined
}

const isVersionDefined = (version: string | undefined) => {
    return version !== undefined && version !== ''
}

export const isApplicationDownloadValid = (addOrUpdt: ApplicationDownloadUpdtCreate) => {
    return (isFileDefined(addOrUpdt.isNewFile, addOrUpdt.file, addOrUpdt.files)
        && isApplicationDefined(addOrUpdt.applicationId, addOrUpdt.applicationName)
        && isVersionDefined(addOrUpdt.version))
}

export const getFileVersion = (fileName: string) => {
    const nameSplitToVersion = (/^(\d+(\.\d+)*)_/g).exec(fileName)
    if (nameSplitToVersion === null) {
        return ''
    }
    else {
        return nameSplitToVersion[1]
    }
}

const isFileDownloadDefined = (isNewFile: boolean | undefined, file: File | undefined) => {
    return isNewFile === false || file !== undefined
}

const isClientDefined = (clientId: string | undefined) => {
    return clientId !== undefined && clientId !== ''
}

const isDescriptionDefined = (description: string | undefined) => {
    return description !== undefined && description !== ''
}

export const isDownloadValid = (addOrUpdt: DownloadManagerUpdtCreate) => {
    return (isFileDownloadDefined(addOrUpdt.isNewFile, addOrUpdt.file)
        && isClientDefined(addOrUpdt.clientId)
        && isDescriptionDefined(addOrUpdt.description))
}