import {
    ServCatalog
} from '../../types/store/servCatalog'

const servCatalogActions = {
    loadServCatalog: (servCatalogResp: Blob): ServCatalog => {
        return { type: 'SERVICES_CATALOG_URL', payload: servCatalogResp }
    }
}

export default servCatalogActions