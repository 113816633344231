import { makeStyles, createStyles, Theme } from '@material-ui/core'

import { WIDTHS } from '../../../constants'

const useStyles = makeStyles((theme: Theme) => createStyles({
    drawerRoot: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
        width: WIDTHS.DESKTOP_DRAWER,
        [theme.breakpoints.down('lg')]: {
            width: WIDTHS.TABLET_DRAWER
        },
        [theme.breakpoints.down('xs')]: {
            width: WIDTHS.MOBILE_DRAWER
        },
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.short
        }),
        boxShadow: theme.shadows[3],
        zIndex: theme.zIndex.drawer
    },
    drawerClosed: {
        overflowX: 'hidden',
        width: WIDTHS.DESKTOP_DRAWER_CLOSED,
        [theme.breakpoints.up(WIDTHS.SIDEBAR_OPEN_WINDOW)]: {
            width: WIDTHS.DESKTOP_DRAWER
        },
        [theme.breakpoints.down('sm')]: {
            width: 0
        },
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.short
        }),
        boxShadow: theme.shadows[2]
    }
}))

export default useStyles