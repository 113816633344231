import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { Grid } from '@material-ui/core'

import { F3MAddUpdt, F3MButton, F3MDialog, F3MApplicationChoice, F3MSnackbar, F3MSnackbarState, F3MIsActive, F3MAppDownFilesInd, F3MAppDownFilesMulti, F3MAddUpdtSection, F3MFilterSearch } from '../../../../../../components'

import i18n from '../../../../../../i18n'
import { applicationDownloadService } from '../../../../../../api'

import { ApplicationDownloadFilter, ApplicationDownloadResponse, ApplicationDownloadsResponse, ApplicationDownloadUpdtCreate } from '../../../../../../types/services/applicationDownload'
import Table from './Table'
import { appDownloadOperations } from '../../../../../../store/operations'
import { isApplicationDownloadValid } from '../../../../../../utils'

interface ApplicationDownloadProps {
    setLoading: (b: boolean) => void
    loading: boolean
}

interface DeleteState {
    open: boolean
    id: number
}

const mapDispatchToProps = (dispatch: any) => ({
    setVersion: (version: string) => dispatch(appDownloadOperations.setVersion(version))
})

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const ApplicationDownload = (props: ApplicationDownloadProps & Props) => {
    const [snackbar, setSnackbar] = useState<F3MSnackbarState>({
        open: false,
        severity: 'info',
        message: ''
    })

    const [fetchedApplicationDownloads, setApplicationDownloads] = useState<ApplicationDownloadsResponse>({ rowCount: 0, applications: [] })
    const [filter, setFilter] = useState<ApplicationDownloadFilter>({
        applicationName: null,
        page: 1,
        rowsPerPage: 10,
        sortDirection: 'desc',
        sortField: 'date'
    })

    const [del, setDel] = useState<DeleteState>({ open: false, id: 0 })

    const [compSections, setCompSections] = useState<F3MAddUpdtSection[]>([])
    const [addUpdtModal, setAddUpdtModal] = useState<boolean>(false)
    const [addUpdtModalTitle, setAddUpdtModalTitle] = useState<string>('')
    const [addUpdtTitleVariant, setAddUpdtTitleVariant] = useState<string>('')

    const [addOrUpdt, setAddOrUpdt] = useState<ApplicationDownloadUpdtCreate>({})

    const [callApiToSave, setCallApiToSave] = useState<(object: any) => any>(() => applicationDownloadService.createApplicationDownload)
    const [update, setUpdate] = useState<boolean>(false)

    const loadApplicationDownloads = async () => {
        props.setLoading(true)
        try {
            const response = await applicationDownloadService.getApplicationDownloads(filter)

            if (response.isSuccess) {
                setApplicationDownloads(response.result)
                props.setLoading(false)
            } else {
                props.setLoading(false)
            }
        }
        catch (e) {
            handleError(i18n.t('error.unexpectedError'))
        }
        finally {
            props.setLoading(false)
            setDel({ open: false, id: 0 })
        }
    }

    const handleDelDownCatDown = async () => {
        props.setLoading(true)
        try {
            const response = await applicationDownloadService.deleteApplicationDownload(del.id)

            if (response.isSuccess) {
                await loadApplicationDownloads()
                handleSuccess(i18n.t('snackbar.deleteSuccess'))
            } else {
                handleError(i18n.t('snackbar.deleteError'))
            }
        }
        catch (e) {
            handleError(i18n.t('snackbar.deleteError'))
        }
        finally {
            props.setLoading(false)
            setDel({ open: false, id: 0 })
        }
    }

    const handleChangeStatus = async (checked: boolean, id: number) => {
        props.setLoading(true)
        try {
            const response = await applicationDownloadService.updateApplicationDownload({ id: id, isActive: checked })

            if (response.isSuccess) {
                await loadApplicationDownloads()
                handleSuccess(i18n.t('snackbar.updateSuccess'))
            } else {
                handleError(i18n.t('snackbar.updateError'))
            }
        }
        catch (e) {
            handleError(i18n.t('snackbar.updateError'))
        }
        finally {
            props.setLoading(false)
        }
    }

    const handleDeleteBtn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
        e.preventDefault()
        e.stopPropagation()
        setDel({ open: true, id: id })
    }

    const handleAddUpdtModal = (type: string, event: React.MouseEvent<HTMLTableRowElement, MouseEvent> | null) => {
        let addOrUpdtToComp: ApplicationDownloadUpdtCreate = addOrUpdt

        let fileName: string[] = []
        let gainsName: string[] = []

        let appDownFilesToPresent: F3MAddUpdtSection = {}

        if (type === 'create') {
            setAddUpdtTitleVariant('add')
            setAddUpdtModalTitle(i18n.t('applicationDownload.addUploadByProduct'))
            setCallApiToSave(() => applicationDownloadService.createApplicationDownload)
            appDownFilesToPresent = { component: <F3MAppDownFilesMulti required={true} addOrUpdt={addOrUpdt} setAddOrUpdt={setAddOrUpdt} /> }
        }
        else {
            const rowIndex: number | undefined = event ? +event.currentTarget.id : undefined

            if (rowIndex !== undefined) {
                const applicationDownload: ApplicationDownloadResponse = fetchedApplicationDownloads.applications[rowIndex]
                addOrUpdtToComp = {
                    id: applicationDownload.id,
                    applicationId: applicationDownload.applicationId,
                    applicationName: applicationDownload.applicationName,
                    isActive: applicationDownload.isActive,
                    version: applicationDownload.version,
                    isManualVersion: false,
                    isNewGains: false,
                    isNewFile: false
                }
                fileName = [applicationDownload.fileName]
                gainsName = [applicationDownload.gainsName]

                props.setVersion(applicationDownload.version)

                setAddOrUpdt(addOrUpdtToComp)
                setAddUpdtTitleVariant('edit')
                const appDownType: string = i18n.t('enum.applicationDownload.type.' + applicationDownload.downloadType)
                setAddUpdtModalTitle(i18n.t('applicationDownload.editUploadByProduct') + ' - ' + appDownType)
                setCallApiToSave(() => applicationDownloadService.updateApplicationDownload)
                appDownFilesToPresent = {
                    component: <F3MAppDownFilesInd
                        required={true}
                        addOrUpdt={addOrUpdtToComp}
                        setAddOrUpdt={setAddOrUpdt}
                        fileName={fileName}
                        gainsName={gainsName}
                        useOriginalVersion={true} />
                }
            }
        }
        setCompSections([
            appDownFilesToPresent,
            {
                title: 'applicationDownload.product', required: true,
                component: <F3MApplicationChoice
                    addOrUpdt={addOrUpdtToComp}
                    setAddOrUpdt={setAddOrUpdt}
                    setAddUpdtModal={setAddUpdtModal}
                    handleError={handleError} />
            },
            { title: 'applicationDownload.active', required: true, component: <F3MIsActive addOrUpdt={addOrUpdtToComp} setAddOrUpdt={setAddOrUpdt} /> }
        ])
        setAddUpdtModal(true)
    }

    const handleSuccess = (message: string) => {
        setSnackbar({ open: true, severity: 'success', message: message })
    }

    const handleError = (message: string) => {
        setSnackbar({ open: true, severity: 'error', message: message })
    }

    const resetAddOrUpdt = () => {
        setAddOrUpdt({})
        setCompSections([])
    }

    const handleTextFilterChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFilter(prevState => ({ ...prevState, applicationName: e.currentTarget.value }))
    }

    useEffect(() => {
        loadApplicationDownloads()
    }, [filter, update]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid container justify='space-between' alignItems='center' spacing={1}>
                <Grid item xs={12} sm={8} md={10}>
                    <F3MFilterSearch handleTextFilterChange={handleTextFilterChange} placeholder={`${i18n.t('common.application')}...`} />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <F3MButton size='medium' onClick={() => handleAddUpdtModal('create', null)}>
                        {i18n.t('button.add')}
                    </F3MButton>
                </Grid>
            </Grid>
            <Table
                applicationDownloads={fetchedApplicationDownloads}
                handleChangeStatus={handleChangeStatus}
                handleDeleteBtn={handleDeleteBtn}
                filter={filter}
                setFilter={setFilter}
                loading={props.loading}
                handleAddUpdtModal={handleAddUpdtModal}
            />
            <F3MAddUpdt
                title={addUpdtModalTitle}
                titleVariant={addUpdtTitleVariant}
                open={addUpdtModal}
                setOpen={setAddUpdtModal}
                sections={compSections}
                addOrUpdt={addOrUpdt}
                setSnackBar={setSnackbar}
                update={update}
                setUpdate={setUpdate}
                resetAddOrUpdt={resetAddOrUpdt}
                isValid={isApplicationDownloadValid}
                callApiToSave={callApiToSave}
                maxWidth={'lg'}
            />
            <F3MDialog
                open={del.open}
                variant='confirmation'
                newOnClose={() => setDel((prevState: any) => ({ ...prevState, open: false }))}
                DialogTitleProps={{ children: i18n.t('confirmation.remove.title') }}
                DialogContentProps={{ children: i18n.t('confirmation.remove.message') }}
                DialogActionsProps={{ label: i18n.t('button.remove'), newOnClick: () => handleDelDownCatDown() }}
            />
            <F3MSnackbar
                setSnackbar={setSnackbar}
                snackbar={snackbar}
            />
        </>
    )
}

export default connector(ApplicationDownload)