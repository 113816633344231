import React, { useState } from 'react'

import { FormControlLabel, Switch } from '@material-ui/core'

import i18n from '../../../../i18n'
import useStyles from './styles'

import { ContentPropsDownCatDonwlsUpdtCreate } from '../../../F3MAddUpdt/types'

const F3MStateSection = (props: ContentPropsDownCatDonwlsUpdtCreate) => {

    const classes = useStyles()

    const {
        addOrUpdt,
        setAddOrUpdt
    } = props

    const [isActive, setIsAcive] = useState<boolean>(addOrUpdt.isActive)

    const handleChangeIsActive = () => {
        if (!isActive === false) {
            setIsAcive(!isActive)
            setAddOrUpdt(prevState => ({ ...prevState, isActive: !isActive }))
        }
        else {
            setIsAcive(!isActive)
            setAddOrUpdt(prevState => ({ ...prevState, isActive: !isActive }))
        }
    }

    return (
        <div className={classes.root}>
            <FormControlLabel
                control={
                    <Switch
                        id='add-updt-isActive'
                        size='small'
                        checked={isActive}
                        onChange={() => handleChangeIsActive()}
                        color='primary'
                    />
                }
                label={i18n.t('common.active')}
            />
        </div>
    )
}

export default F3MStateSection