import React, { useState } from 'react'

import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from '@material-ui/core'

import i18n from '../../../../i18n'
import useStyles from './styles'

import { ContentPropsAddUpdtSlide } from '../../../F3MAddUpdt/types'

const F3MStateSection = (props: ContentPropsAddUpdtSlide) => {

    const classes = useStyles()

    const {
        addOrUpdt,
        setAddOrUpdt
    } = props

    const getMaxNumberOfOrder = () => {
        return addOrUpdt.status === true ? addOrUpdt.activeRows + 2 : addOrUpdt.activeRows + 3
    }

    const getNumberOfOrder = () => {
        return addOrUpdt.status === true ? addOrUpdt.activeRows + 1 : addOrUpdt.activeRows + 2
    }

    const originalOrder: number | null = addOrUpdt.order
    const [isActive, setIsAcive] = useState<boolean>(addOrUpdt.status)
    const [order, setOrder] = useState<string | undefined>(addOrUpdt.order?.toString())
    const possibleOrder: number[] | undefined = addOrUpdt.activeRows === undefined ? undefined : [...Array(getMaxNumberOfOrder()).keys()].slice(1)

    const handleChangeIsActive = () => {
        if (!isActive === false) {
            setIsAcive(!isActive)
            setOrder(undefined)
            setAddOrUpdt(prevState => ({ ...prevState, status: !isActive, order: null }))
        }
        else {
            setIsAcive(!isActive)
            const newOrder = originalOrder !== null ? originalOrder.toString() : (getNumberOfOrder()).toString()
            setOrder(newOrder)
            setAddOrUpdt(prevState => ({ ...prevState, status: !isActive, order: +newOrder }))
        }
    }

    const handleChangeOrder = (value: string) => {
        setOrder(value as string)
        setAddOrUpdt(prevState => ({ ...prevState, order: +value }))
    }

    return (
        <div className={classes.root}>
            <FormControlLabel
                control={
                    <Switch
                        id='add-updt-isActive'
                        size='small'
                        checked={isActive}
                        onChange={() => handleChangeIsActive()}
                        color='primary'
                    />
                }
                label={i18n.t('common.active')}
            />
            {isActive &&
                <FormControl className={classes.formControl}>
                    <InputLabel shrink id='add-updt-order-label'>
                        {i18n.t('carousel.ordering')}
                    </InputLabel>
                    <Select
                        labelId='add-updt-order-label'
                        id='add-updt-order'
                        value={order}
                        onChange={(e) => handleChangeOrder(e.target.value as string)}
                        displayEmpty
                        className={classes.selectEmpty}
                    >
                        {possibleOrder?.map((order) => (
                            <MenuItem key={order} value={order}>{order}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
        </div>
    )
}

export default F3MStateSection