import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core"
import React, { Dispatch, SetStateAction, useState } from "react"
import { connect, ConnectedProps } from "react-redux"
import { F3MButton, F3MSnackbarState } from ".."
import i18n from "../../i18n"
import { appDownloadOperations } from "../../store/operations"
import useStyles from "./styles"

import { AddUpdtVariant, F3MAddUpdtSection } from "./types"

interface ContentProps {
    title: string
    titleVariant: string
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    sections: Array<F3MAddUpdtSection>
    addOrUpdt: AddUpdtVariant
    setSnackBar: Dispatch<SetStateAction<F3MSnackbarState>>
    update: boolean
    setUpdate: Dispatch<SetStateAction<boolean>>
    resetAddOrUpdt: () => void
    isValid: (addOrUpdt: any) => boolean
    callApiToSave: (object: any) => any
    maxWidth?: 'lg'
}

const mapDispatchToProps = (dispatch: any) => ({
    clearVersion: () => dispatch(appDownloadOperations.clearVersion())
})

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const F3MAddUpdt = (props: ContentProps & Props) => {
    const classes = useStyles()

    const {
        title,
        titleVariant,
        open,
        setOpen,
        sections,
        addOrUpdt,
        setSnackBar,
        update,
        setUpdate,
        resetAddOrUpdt,
        isValid,
        callApiToSave,
        maxWidth,
        clearVersion
    } = props

    const [loading, setLoading] = useState<boolean>(false)

    const handleSave = async () => {
        setLoading(true)
        if (isValid(addOrUpdt)) {
            await callApiToSave(addOrUpdt).then(() => {
                resetAddOrUpdt()
                setUpdate(!update)
                setOpen(!open)
                if (titleVariant === 'add') {
                    setSnackBar({ open: true, severity: 'success', message: i18n.t('snackbar.createSuccess') })
                }
                else {
                    setSnackBar({ open: true, severity: 'success', message: i18n.t('snackbar.updateSuccess') })
                }
                clearVersion()
            }).catch((error: any) => {
                let message: string = i18n.t('snackbar.errorOccurred')
                if (error.status === 409) {
                    message = i18n.t('snackbar.fileExists') + " Ficheiro: " + error.data.applicationMessages[0]
                }
                setSnackBar({ open: true, severity: 'error', message: message })
            })
        }
        else {
            setSnackBar({ open: true, severity: 'error', message: i18n.t('snackbar.mandatoryFields') })
        }
        setLoading(false)
    }

    const handleCancel = () => {
        setOpen(!open)
        resetAddOrUpdt()
        clearVersion()
    }

    return (
        <>
            <Dialog
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                fullWidth={true}
                maxWidth={maxWidth ? maxWidth : 'md'}
                open={open}
                scroll='paper'
                className={classes.root}
                
            >
                <DialogTitle
                    color='textPrimary'
                    className={classes.modalTitle}>
                    {i18n.t(title)}
                </DialogTitle>
                <div className={classes.modalContent} style={{ zIndex: loading ? -1 : 'unset' }}>
                    {sections.map((section, index) => (
                        <DialogContent key={index}>
                            {section.title !== undefined &&
                                <Typography variant='h6' color='primary' gutterBottom>
                                    {i18n.t(section.title)}{section.required === true && <span className={classes.required}>&ensp;*</span>}
                                </Typography>
                            }
                            {section.component !== undefined &&
                                section.component
                            }
                        </DialogContent>
                    ))}
                </div>
                <DialogActions className={classes.modalFooter} style={{ zIndex: loading ? -1 : 'unset' }}>
                    <F3MButton color='secondary' onClick={() => handleCancel()} fullWidth={false} variant='text'>
                        {i18n.t('button.cancel')}
                    </F3MButton>
                    <F3MButton onClick={async () => await handleSave()} fullWidth={false}>
                        {i18n.t('button.save')}
                    </F3MButton>
                </DialogActions>
                {loading &&
                    <CircularProgress style={{ position: 'absolute', top: '50%', right: '50%' }} />
                }
            </Dialog>
        </>
    )
}

export default connector(F3MAddUpdt)