import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    description: {
        paddingLeft: 20,
        paddingRight: 20,
        verticalAlign: 'middle'
    }
}))

export default useStyles