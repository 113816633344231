import React from 'react'

import { Popover } from '@material-ui/core'

import { F3MPopoverProps } from './types'

import useStyles from './styles'

const F3MPopover = (props: F3MPopoverProps) => {
    const classes = useStyles()

    return <Popover {...props} classes={{paper: classes.paper}} />
}

export default F3MPopover