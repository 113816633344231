import { makeStyles, createStyles, Theme } from '@material-ui/core'

import { WIDTHS } from '../../constants'

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        backgroundColor: theme.palette.grey[200],
        position: 'absolute',
        bottom: 0,
        width: '100vw',
        height: 400,
        paddingTop: 60,
        paddingRight: 40,
        [theme.breakpoints.up(WIDTHS.SIDEBAR_OPEN_WINDOW)]: {
            paddingLeft: WIDTHS.DESKTOP_DRAWER + 40
        },
        [theme.breakpoints.down(WIDTHS.SIDEBAR_OPEN_WINDOW)]: {
            paddingLeft: WIDTHS.DESKTOP_DRAWER_CLOSED + 40
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 20,
            paddingRight: 20
        },
        [theme.breakpoints.down('xs')]: {
            height: 550
        }
    },
    columnsGrid: {
        height: 'calc(400px - 50px - 50px - 170px)', // footer (400px) - paddingTop (60px) - space between grid and icons (60px) - icons + image (170px)
        [theme.breakpoints.down('xs')]: {
            height: 'calc(550px - 60px - 60px - 170px)'
        }
    },
    footerTitle: {
        fontWeight: 700,
        paddingBottom: 5
    },
    footerLink: {
        color: theme.palette.text.secondary,
        textDecoration: 'none',
        lineHeight: '22px',
        '&:hover': {
            textDecoration: 'none',
            color: theme.palette.text.secondary
        }
    },
    iconAndImageGrid: {
        height: 'calc(60px + 170px)',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1)
    },
    image: {
        maxWidth: '100%'
    }
}))

export default useStyles