import {
    SetVersion,
    ClearVersion
} from '../../types/store/appDownload'

const appDownloadActions = {
    setVersion: (version: string): SetVersion => {
        return { type: 'SET_VERSION', payload: version }
    },

    clearVersion: (): ClearVersion => {
        return { type: 'CLEAR_VERSION' }
    }
}

export default appDownloadActions