import { CONFIG, ENDPOINTS } from '../../constants'
import { ApplicationDownloadResponse, ApplicationDownloadsResponse, ApplicationDownloadUpdtCreate, InstallationResponse, UpdateResponse, UpgradeResponse } from '../../types/services/applicationDownload'
import { ApplicationDownloadFilter } from '../../types/services/applicationDownload/ApplicationDownloadFilter'
import { isDefined } from '../../utils'
import { download, request } from '../axios'

export const applicationDownloadService = {
    loadInstallations: async () => {
        const response = await request<InstallationResponse[]>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.APPLICATION_DOWNLOAD_INSTALLATION
        })

        return response
    },

    loadUpgrades: async () => {
        const response = await request<UpgradeResponse[]>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.APPLICATION_DOWNLOAD_UPGRADE
        })

        return response
    },

    loadUpdates: async () => {
        const response = await request<UpdateResponse[]>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.APPLICATION_DOWNLOAD_UPDATE
        })

        return response
    },

    downloadApplication: async (id: number) => {
        const response = await download({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.APPLICATION_DOWNLOAD_DOWNLOAD + '/' + id,
            headers: {
                responseType: 'arraybuffer'
            }
        })

        return response
    },

    downloadGains: async (id: number | undefined) => {
        const response = await download({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.APPLICATION_DOWNLOAD_GAINS + '/' + id,
            headers: {
                responseType: 'arraybuffer'
            }
        })

        return response
    },

    getApplicationDownloads: async (filter: ApplicationDownloadFilter) => {
        const response = await request<ApplicationDownloadsResponse>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.APPLICATION_DOWNLOAD,
            params: filter
        })

        return response
    },

    getApplicationDownload: async (id: number) => {
        const response = await request<ApplicationDownloadResponse>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.APPLICATION_DOWNLOAD + '/' + id
        })

        return response
    },

    createApplicationDownload: async (application: ApplicationDownloadUpdtCreate) => {
        const formData = new FormData()

        formData.append('applicationId', application.applicationId ? application.applicationId : ''!)
        formData.append('applicationName', application.applicationName ? application.applicationName : ''!)
        formData.append('version', application.version ? application.version : '')
        formData.append('isManualVersion', (application.isManualVersion === true).toString())
        if(application.files){
            formData.append('installationFile', application.files['installation'] ? application.files['installation'] : '')
            formData.append('updateFile', application.files['update'] ? application.files['update'] : '')
            formData.append('upgradeFile', application.files['upgrade'] ? application.files['upgrade'] : '')
        }
        formData.append('gainsFile', application.gains ? application.gains : '')
        formData.append('isActive', application.isActive !== undefined ? application.isActive.toString() : true.toString())

        const response = await request<ApplicationDownloadResponse>({
            method: 'POST',
            url: CONFIG.API_URL + ENDPOINTS.APPLICATION_DOWNLOAD,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })

        return response
    },

    updateApplicationDownload: async (application: ApplicationDownloadUpdtCreate) => {
        const formData = new FormData()

        if (!isDefined(application.id)) {
            throw Error
        }

        formData.append('id', application.id ? application.id.toString() : '')
        formData.append('applicationId', application.applicationId ? application.applicationId : ''!)
        formData.append('applicationName', application.applicationName ? application.applicationName : ''!)
        formData.append('version', application.version ? application.version : '')
        formData.append('isManualVersion', (application.isManualVersion === true).toString())
        formData.append('isActive', (application.isActive === true).toString())

        if (application.isNewFile) {
            formData.append('file', application.file ? application.file : '')
        }
        if (application.isNewGains) {
            formData.append('gains', application.gains ? application.gains : '')
        }

        const response = await request<ApplicationDownloadResponse>({
            method: 'PUT',
            url: CONFIG.API_URL + ENDPOINTS.APPLICATION_DOWNLOAD,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })

        return response
    },

    deleteApplicationDownload: async (id: number) => {
        const response = await request<ApplicationDownloadResponse>({
            method: 'DELETE',
            url: CONFIG.API_URL + ENDPOINTS.APPLICATION_DOWNLOAD + '/' + id
        })

        return response
    }
}