import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import i18n from "../../../../i18n";

import {
  F3MAddUpdt,
  F3MAddUpdtSection,
  F3MBreadcrumbs,
  F3MButton,
  F3MCard,
  F3MClientChoice,
  F3MDialog,
  F3MDownManDescp,
  F3MDropzoneSection,
  F3MFilterSearch,
  F3MIsActive,
  F3MSnackbar,
  F3MSnackbarState,
} from "../../../../components";
import {
  ClientModel,
  DownloadManagerFilter,
  DownloadManagerResponse,
  DownloadManagerUpdtCreate,
  DownloadsManagerResponse,
} from "../../../../types/services/downloadsManager";
import { downloadManagerService } from "../../../../api";

import Table from "./Table";
import { isDownloadValid } from "../../../../utils";
import { FILESIZE } from "../../../../constants";

interface DeleteState {
  open: boolean;
  id: number;
}

const DownloadManager = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [snackbar, setSnackbar] = useState<F3MSnackbarState>({
    open: false,
    severity: "info",
    message: "",
  });

  const [fetchedDownloads, setDownloads] = useState<DownloadsManagerResponse>({
    rowCount: 0,
    downloads: [],
  });
  const [filter, setFilter] = useState<DownloadManagerFilter>({
    clientNameNumber: null,
    page: 1,
    rowsPerPage: 10,
    sortDirection: "desc",
    sortField: "date",
  });

  const [compSections, setCompSections] = useState<F3MAddUpdtSection[]>([]);
  const [addUpdtModal, setAddUpdtModal] = useState<boolean>(false);
  const [addUpdtModalTitle, setAddUpdtModalTitle] = useState<string>("");
  const [addUpdtTitleVariant, setAddUpdtTitleVariant] = useState<string>("");

  const [addOrUpdt, setAddOrUpdt] = useState<DownloadManagerUpdtCreate>({
    isNewFile: true,
    isActive: true,
  });

  const [callApiToSave, setCallApiToSave] = useState<(object: any) => any>(
    () => downloadManagerService.createDownload
  );
  const [update, setUpdate] = useState<boolean>(false);

  const [del, setDel] = useState<DeleteState>({ open: false, id: 0 });

  const loadDownloads = async () => {
    setLoading(true);
    try {
      const response = await downloadManagerService.loadDownloads(filter);

      if (response.isSuccess) {
        setDownloads(response.result);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      handleError(i18n.t("error.unexpectedError"));
    } finally {
      setLoading(false);
      setDel({ open: false, id: 0 });
    }
  };

  const handleDelDownload = async () => {
    setLoading(true);
    try {
      const response = await downloadManagerService.deleteDownload(del.id);

      if (response.isSuccess) {
        await loadDownloads();
        handleSuccess(i18n.t("snackbar.deleteSuccess"));
      } else {
        handleError(i18n.t("snackbar.deleteError"));
      }
    } catch (e) {
      handleError(i18n.t("snackbar.deleteError"));
    } finally {
      setLoading(false);
      setDel({ open: false, id: 0 });
    }
  };

  const handleChangeStatus = async (checked: boolean, id: number) => {
    setLoading(true);
    try {
      const response = await downloadManagerService.updateDownload({
        id: id,
        isActive: checked,
      });

      if (response.isSuccess) {
        await loadDownloads();
        handleSuccess(i18n.t("snackbar.updateSuccess"));
      } else {
        handleError(i18n.t("snackbar.updateError"));
      }
    } catch (e) {
      handleError(i18n.t("snackbar.updateError"));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteBtn = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setDel({ open: true, id: id });
  };

  const handleFileChange = (files: File[]) => {
    setAddOrUpdt((prevState) => ({
      ...prevState,
      file: files.length > 0 ? files[0] : undefined,
    }));
  };

  const getFileAddedMessage = (fileName: string) => {
    setAddOrUpdt((prevState) => ({ ...prevState, isNewFile: true }));
    return i18n.t("dropzone.fileAdded").replace("FILE_NAME", fileName);
  };

  const handleAddUpdtModal = (
    type: string,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent> | null
  ) => {
    let addOrUpdtToComp: DownloadManagerUpdtCreate = addOrUpdt;

    let fileName: string[] = [];
    let defaultClient: ClientModel | null = null;

    if (type === "create") {
      setAddUpdtTitleVariant("add");
      setAddUpdtModalTitle(i18n.t("downloads.addDownload"));
      setCallApiToSave(() => downloadManagerService.createDownload);
    } else {
      const rowIndex: number | undefined = event
        ? +event.currentTarget.id
        : undefined;

      if (rowIndex !== undefined) {
        const download: DownloadManagerResponse =
          fetchedDownloads.downloads[rowIndex];
        addOrUpdtToComp = {
          id: download.id,
          clientId: download.clientId,
          description: download.description,
          isNewFile: false,
          isActive: download.isActive,
        };
        defaultClient = {
          clientId: download.clientId,
          clientName: download.clientName,
        };
        fileName = [download.fileName];

        setAddOrUpdt(addOrUpdtToComp);
        setAddUpdtTitleVariant("edit");
        setAddUpdtModalTitle(i18n.t("downloads.editDownload"));
        setCallApiToSave(() => downloadManagerService.updateDownload);
      }
    }
    setCompSections([
      {
        title: "downloadCategoryDownload.fileUpload",
        required: true,
        component: (
          <F3MDropzoneSection
            handleFileChange={handleFileChange}
            getFileAddedMessage={getFileAddedMessage}
            showPreviews={true}
            showPreviewsInDropzone={false}
            useChipsForPreview
            initialFiles={fileName}
            previewGridProps={{
              container: { spacing: 1, direction: "row" },
            }}
            maxFileSize={FILESIZE["2GB"]}
            filesLimit={1}
          />
        ),
      },
      {
        title: "downloads.selectClient",
        required: true,
        component: (
          <F3MClientChoice
            addOrUpdt={addOrUpdtToComp}
            setAddOrUpdt={setAddOrUpdt}
            setAddUpdtModal={setAddUpdtModal}
            handleError={handleError}
            defaultClient={defaultClient}
          />
        ),
      },
      {
        title: "common.description",
        required: true,
        component: (
          <F3MDownManDescp
            addOrUpdt={addOrUpdtToComp}
            setAddOrUpdt={setAddOrUpdt}
            handleError={handleError}
          />
        ),
      },
      {
        title: "applicationDownload.active",
        required: true,
        component: (
          <F3MIsActive
            addOrUpdt={addOrUpdtToComp}
            setAddOrUpdt={setAddOrUpdt}
          />
        ),
      },
    ]);
    setAddUpdtModal(true);
  };

  const handleSuccess = (message: string) => {
    setSnackbar({ open: true, severity: "success", message: message });
  };

  const handleError = (message: string) => {
    setSnackbar({ open: true, severity: "error", message: message });
  };

  const handleTextFilterChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFilter((prevState) => ({
      ...prevState,
      clientNameNumber: e.currentTarget.value,
    }));
  };

  const resetAddOrUpdt = () => {
    setAddOrUpdt({ isNewFile: true, isActive: true });
    setCompSections([]);
  };

  useEffect(() => {
    loadDownloads();
  }, [filter, update]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <F3MBreadcrumbs path={i18n.t("paths.downloadManager.breadcrumb")} />
      <F3MCard loading={loading}>
        <Grid container justify="space-between" alignItems="center" spacing={1}>
          <Grid item xs={12} sm={8} md={10}>
            <F3MFilterSearch
              handleTextFilterChange={handleTextFilterChange}
              placeholder={`${i18n.t("downloads.searchNameCodeClient")}...`}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <F3MButton
              size="medium"
              onClick={() => handleAddUpdtModal("create", null)}
            >
              {i18n.t("button.add")}
            </F3MButton>
          </Grid>
        </Grid>
        <Table
          fetchedDownloads={fetchedDownloads}
          handleChangeStatus={handleChangeStatus}
          handleDeleteBtn={handleDeleteBtn}
          filter={filter}
          setFilter={setFilter}
          loading={loading}
          handleAddUpdtModal={handleAddUpdtModal}
        />
        <F3MAddUpdt
          title={addUpdtModalTitle}
          titleVariant={addUpdtTitleVariant}
          open={addUpdtModal}
          setOpen={setAddUpdtModal}
          sections={compSections}
          addOrUpdt={addOrUpdt}
          setSnackBar={setSnackbar}
          update={update}
          setUpdate={setUpdate}
          resetAddOrUpdt={resetAddOrUpdt}
          isValid={isDownloadValid}
          callApiToSave={callApiToSave}
        />
        <F3MDialog
          open={del.open}
          variant="confirmation"
          newOnClose={() =>
            setDel((prevState: any) => ({ ...prevState, open: false }))
          }
          DialogTitleProps={{ children: i18n.t("confirmation.remove.title") }}
          DialogContentProps={{
            children: i18n.t("confirmation.remove.message"),
          }}
          DialogActionsProps={{
            label: i18n.t("button.remove"),
            newOnClick: () => handleDelDownload(),
          }}
        />
        <F3MSnackbar setSnackbar={setSnackbar} snackbar={snackbar} />
      </F3MCard>
    </>
  );
};

export default DownloadManager;
