import { ticketsService } from '../../api'
import { ReportFields, SortDirection } from '../../types'
import { AppThunkAction } from '../../types/store'
import { ReportActions } from '../../types/store/report'
import reportActions from '../actions/reportActions'

const {
    clearData,
    handleFilterChange,
    handlePageChange,
    handleRowsPerPageChange,
    handleYearChange,
    loadStatisticsError,
    loadStatisticsRequest,
    loadStatisticsSuccess,
    loadTicketsError,
    loadTicketsRequest,
    loadTicketsSuccess,
    loadYearsError,
    loadYearsRequest,
    loadYearsSuccess,
    order,
    toggleFilter
} = reportActions

const reportOperations = {
    handleYearChange: (event :React.ChangeEvent<{ name?: string; value: unknown }>): AppThunkAction<ReportActions> => async (dispatch, getState) => {         
        dispatch(handleYearChange(event.target.value as number))
    },

    handleFilterChange: (name: string, value: string): AppThunkAction<ReportActions> => async (dispatch, getState) => {          
        dispatch(handleFilterChange(name, value))
    },

    handleChangePage: (event: any, page: number): AppThunkAction<ReportActions> => async (dispatch, getState) => {
        dispatch(handlePageChange(page))
    },

    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): AppThunkAction<ReportActions> => async (dispatch, getState) => {
        dispatch(handleRowsPerPageChange(parseInt(event.target.value)))
    },

    loadStatistics: (): AppThunkAction<ReportActions> => async (dispatch, getState) => {
        dispatch(loadStatisticsRequest())

        try {
            const response = await ticketsService.loadTicketsStatistics(getState().reports?.statistics.year)
            
            if(response.isSuccess) {
                dispatch(loadStatisticsSuccess(response.result))
            } else {
                dispatch(loadStatisticsError(response.errorMessage))
            }
        } catch(e) {
            dispatch(loadStatisticsError(e.response))
        }
    },

    loadTickets: (): AppThunkAction<ReportActions> => async (dispatch, getState) => {
        dispatch(loadTicketsRequest())

        try {
            const response = await ticketsService.loadTickets(getState().reports?.reports.filter!)
            
            if(response.isSuccess) {
                dispatch(loadTicketsSuccess(response.result))
            } else {
                dispatch(loadTicketsError(response.errorMessage))
            }
        } catch(e) {
            dispatch(loadTicketsError(e.response))
        }
    },

    clearData: (): AppThunkAction<ReportActions> => async (dispatch, getState) => {
        dispatch(clearData())
    },

    loadYears: (): AppThunkAction<ReportActions> => async (dispatch, getState) => {
        dispatch(loadYearsRequest())

        try {
            const response = await ticketsService.loadYears()

            if(response.isSuccess) {
                dispatch(loadYearsSuccess(response.result))
            } else {
                dispatch(loadYearsError(response.errorMessage))
            }
            
        } catch(e) {
            dispatch(loadYearsError(e.response))
        }
    },

    toggleFilter: (): AppThunkAction<ReportActions> => async (dispatch, getState) => {
        dispatch(toggleFilter())
    },

    order: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): AppThunkAction<ReportActions> => async (dispatch, getState) => {
        e.preventDefault()
        const id = e.currentTarget.parentElement!.id

        const {sortDirection, sortField} = getState().reports?.reports.filter!
        let newSortDirection = 'desc'
        let newField = 'date'

        if(sortField === id) {
            newField = sortField
            if(sortDirection === 'asc') {
                newSortDirection = 'desc'
            } else {
                newSortDirection = 'asc'
            }
        } else {
            newSortDirection = 'asc'
            newField = id
        }

        dispatch(order(newSortDirection as SortDirection, newField as ReportFields))
    }
}

export default reportOperations