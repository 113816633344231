import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: '100vh',
        backgroundColor: theme.palette.common.white
    },
    container: {
        paddingTop: 80
    },
    paper: {
        width: 330,
        marginTop: theme.spacing(9),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(3)
        }
    },
    loading: {
        [theme.breakpoints.up('xs')]: {
            height: '350px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '270px'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    paperRemoteAssist: {
        textAlign: 'center',
        [theme.breakpoints.down('xl')]: {
            marginTop: theme.spacing(6.5)
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(6.5)
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2.9)
        }
    }
}))

export default useStyles