import { request } from '../axios'
import { CONFIG, ENDPOINTS } from '../../constants'
import { AccountResponse } from '../../types/services/account'

export const accountService = {
    accountInfo: async () => {
        const response = await request<AccountResponse>({
            method : 'GET',
            url    : CONFIG.API_URL + ENDPOINTS.ACCOUNT
        })

        return response
    }
}