import React, { ChangeEvent } from 'react'

import { InputBase } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import useStyles from './styles'

interface F3MFilterSearchProps {
    handleTextFilterChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    placeholder: string
}

const F3MFilterSearch = (props: F3MFilterSearchProps) => {

    const classes = useStyles()

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon color='primary' />
            </div>
            <InputBase
                onChange={(e) => props.handleTextFilterChange(e)}
                placeholder={props.placeholder}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
            />
        </div>
    )
}

export default F3MFilterSearch