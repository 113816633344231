import React, { useState } from 'react'

import { TextField } from '@material-ui/core'

import useStyles from './styles'
import { ContentPropsAddUpdtSlide } from '../../../F3MAddUpdt/types'
import i18n from '../../../../i18n'
import inputLimit from '../../../../utils/inputLimit'

const F3MInfoFields = (props: ContentPropsAddUpdtSlide & { handleError: (message: string) => void }) => {

    const classes = useStyles()

    const {
        addOrUpdt,
        setAddOrUpdt,
        handleError
    } = props

    const [title, setTitle] = useState<string>(addOrUpdt.title ? addOrUpdt.title : '')
    const [desc, setDesc] = useState<string>(addOrUpdt.description ? addOrUpdt.description : '')

    const handleTitleChange = (value: string) => {
        const titleLimit = inputLimit.common.title
        if (value.length > titleLimit) {
            handleError(i18n.t('common.maxCharSecField', { field: i18n.t('carousel.title'), section: i18n.t('carousel.info'), maxChar: titleLimit }))
        }
        else {
            setTitle(value)
            setAddOrUpdt(prevState => ({ ...prevState, title: value }))
        }
    };

    const handleDescChange = (value: string) => {
        const descriptionLimit = inputLimit.common.description
        if (value.length > descriptionLimit) {
            handleError(i18n.t('common.maxCharSecField', { field: i18n.t('carousel.description'), section: i18n.t('carousel.info'), maxChar: descriptionLimit }))
        }
        else {
            setDesc(value)
            setAddOrUpdt(prevState => ({ ...prevState, description: value }))
        }
    }

    return (
        <div className={classes.root}>
            <TextField
                id='add-updt-info-title'
                label={i18n.t('carousel.title')}
                value={title}
                onChange={(e) => handleTitleChange(e.target.value)}
                required={true}
                style={{ margin: 8 }}
                fullWidth
                margin='normal'
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                id='add-updt-info-description'
                label={i18n.t('carousel.description')}
                value={desc}
                onChange={(e) => handleDescChange(e.target.value)}
                style={{ margin: 8 }}
                fullWidth
                margin='normal'
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>
    )
}

export default F3MInfoFields