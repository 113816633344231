import { request } from '..'
import { CONFIG, ENDPOINTS } from '../../constants'
import { DownloadManagerFilter, DownloadsManagerResponse, DownloadManagerUpdtCreate, ClientModel } from '../../types/services/downloadsManager'
import { isDefined } from '../../utils'

export const downloadManagerService = {
    loadDownloads: async (filter: DownloadManagerFilter) => {
        const response = await request<DownloadsManagerResponse>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.DOWNLOADMANAGER,
            params: filter
        })

        return response
    },

    loadClients: async (clientCodeName: string) => {
        const response = await request<ClientModel[]>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.CLIENTS,
            params: { clientCodeName: clientCodeName }
        })

        return response
    },

    createDownload: async (download: DownloadManagerUpdtCreate) => {
        const formData = new FormData()

        formData.append('clientId', download.clientId ? download.clientId : ''!)
        formData.append('description', download.description ? download.description : '')
        formData.append('file', download.file ? download.file : '')
        formData.append('isActive', download.isActive.toString())

        const response = await request<DownloadsManagerResponse>({
            method: 'POST',
            url: CONFIG.API_URL + ENDPOINTS.DOWNLOADMANAGER,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })

        return response
    },

    updateDownload: async (download: DownloadManagerUpdtCreate) => {
        const formData = new FormData()

        if (!isDefined(download.id)) {
            throw Error
        }

        formData.append('id', download.id ? download.id.toString() : '')
        formData.append('clientId', download.clientId ? download.clientId : '')
        formData.append('description', download.description ? download.description : '')
        
        if (download.isNewFile === true) {
            formData.append('file', download.file ? download.file : '')
        }

        formData.append('isActive', (download.isActive === true).toString())

        const response = await request<DownloadsManagerResponse>({
            method: 'PUT',
            url: CONFIG.API_URL + ENDPOINTS.DOWNLOADMANAGER,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })

        return response
    },

    deleteDownload: async (id: number) => {
        const response = await request<DownloadsManagerResponse>({
            method: 'DELETE',
            url: CONFIG.API_URL + ENDPOINTS.DOWNLOADMANAGER + '/' + id
        })

        return response
    }
}