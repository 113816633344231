import { Theme, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
    parent: {
        textTransform: 'uppercase',
        fontSize: 14
    },
    page: {
        fontSize: 14
    }
}))

export default useStyles