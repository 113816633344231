import moment from 'moment'
import { isArray, isDefined, isNumber, isStringEmpty } from '../../utils'

import { Alignment, ColType, F3MDataGridRowData, SortDirection } from './types'

const getWidth = (width: number | string | undefined): string => {
    if (isDefined(width)) {
        if (isNumber(width!)) {
            return width! + '%'
        }
        return width! as string
    }
    return 'auto'
}

const alignCell = (align: Alignment | undefined, type: ColType | undefined): Alignment => {
    if (isDefined(align)) {
        return align!
    } else if (isDefined(type)) {
        if (type! === 'number' || type! === 'date' || type! === 'datetime') {
            return 'right'
        } else if (type! === 'node') {
            return 'center'
        }
        return 'left'
    }
    return 'left'
}

const getClassName = (className: string | string[] | undefined): string => {
    if (isDefined(className)) {
        if (isArray(className)) {
            return (className as string[]).join(' ')
        } else {
            return className as string
        }
    }
    return ''
}

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

const getComparator =  <Key extends keyof any>(order: SortDirection, orderBy: Key): (a: {[key in Key]: number | string}, b: {[key in Key]: number | string}) => number => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = (array: F3MDataGridRowData[], comparator: (a: F3MDataGridRowData, b: F3MDataGridRowData) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as[F3MDataGridRowData, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

const formatField = (field: any, type: ColType | undefined): any => {
    if (!isDefined(field) || isStringEmpty(field.toString())) {
        return '-'
    } else if (type === 'date') {
        return moment(field).format('DD/MM/YYYY')
    } else if (type === 'datetime') {
        return moment(field).utc().format('DD/MM/YYYY HH:mm')
    }
    return field
}

export {
    getWidth,
    alignCell,
    getClassName,
    getComparator,
    stableSort,
    formatField
}