import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    hidden: {
        display: 'none'
    },
    filter: {
        transition: theme.transitions.create(['max-height'], {
            duration: theme.transitions.duration.short,
            easing: 'ease-in-out'
        }),
        maxHeight: 1000,
        overflow: 'hidden'
    },
    filterHidden: {
        maxHeight: 0
    },
    icons: {
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    totals: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            maxWidth: 400
        },
        '& > p': {
            color: theme.palette.common.white,
            fontWeight: 700,
            fontSize: 16
        }
    },
    buttonSeeMore: {
        alignSelf: 'center',
        textAlign: 'right',
        '@media (max-width:958px)': {
            paddingTop: '0 !important'
        }
    },
    textButtonSeeMore: {
        color: theme.palette.primary.main,
        fontSize: '12px',
        maxWidth: '69px',
        '@media (max-width:958px)': {
            maxWidth: '112px'
        }
    }
}))

export default useStyles