import React from 'react'

import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, IconButton, Typography, DialogContentText } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { F3MButton } from '..'

import { isDefined } from '../../utils'
import i18n from '../../i18n'

import { F3MDialogProps } from './types'
import useStyles from './styles'

const F3MDialog = (props: F3MDialogProps) => {
    const classes = useStyles()

    const isDialog = props.variant === 'dialog'
    const isConfirmation = props.variant === 'confirmation'

    let dialogProps: F3MDialogProps = {

        // CHILDREN
        children: props.children,

        // DIALOG TITLE PROPS
        DialogTitleProps: {
            ...props.DialogTitleProps,
            disableTypography: props.DialogTitleProps.children ? true : false
        },

        // DIALOG CONTENT PROPS
        DialogContentProps: {
            ...props.DialogContentProps,
            dividers: isDialog ? true : (isConfirmation ? false : true)
        },

        // DIALOG ACTIONS PROPS
        DialogActionsProps: isDefined(props.DialogActionsProps) ? props.DialogActionsProps : undefined,

        // Default DISABLE ESCAPE KEY DOWN if modal false, if confirmation true
        disableEscapeKeyDown: isDefined(props.disableEscapeKeyDown) ? props.disableEscapeKeyDown : (isDialog ? false : (isConfirmation ? true : false)),

        // Default NOT FULLSCREEN
        fullScreen: isDefined(props.fullScreen) ? props.fullScreen : false,

        // Default FULL WIDTH
        fullWidth: isDefined(props.fullWidth) ? props.fullWidth : true,

        // Default MAX WIDTH MD
        maxWidth: isDefined(props.maxWidth) ? props.maxWidth : (isDialog ? 'md' : (isConfirmation ? 'sm' : 'md')),

        // NEW ON CLOSE
        newOnClose: props.newOnClose,


        // ON CLOSE
        onClose: props.onClose,

        // OPEN
        open: props.open,

        // PAPER COMPONENT
        PaperComponent: props.PaperComponent,

        // PAPER PROPS
        PaperProps: props.PaperProps,

        // Default SCROLL PAPER
        scroll: isDefined(props.scroll) ? props.scroll : 'paper',

        // TRANSITION COMPONENT
        TransitionComponent: props.TransitionComponent,

        // TRANSITION DURATION
        transitionDuration: props.transitionDuration,

        // TRANSITION PROPS
        TransitionProps: props.TransitionProps,

        // Default VARIANT DIALOG
        variant: isDefined(props.variant) ? props.variant : 'dialog'
    }

    const { DialogActionsProps, DialogContentProps, DialogTitleProps } = dialogProps

    return (
        <Dialog
            disableEscapeKeyDown={dialogProps.disableEscapeKeyDown}
            fullScreen={dialogProps.fullScreen}
            fullWidth={dialogProps.fullWidth}
            maxWidth={dialogProps.maxWidth}
            onClose={dialogProps.newOnClose}
            open={dialogProps.open}
            PaperComponent={dialogProps.PaperComponent}
            PaperProps={dialogProps.PaperProps}
            scroll={dialogProps.scroll}
            TransitionComponent={dialogProps.TransitionComponent}
            transitionDuration={dialogProps.transitionDuration}
            TransitionProps={dialogProps.TransitionProps}
        >
            <DialogTitle disableTypography={DialogTitleProps.disableTypography}>
                <Grid container alignItems='center' justify='space-between'>
                    <Grid item xs={11}>
                        <Typography
                            variant={isDialog ? 'h2' : isConfirmation ? 'h3' : 'h2'}
                            color='textPrimary'
                            style={{ fontWeight: 700 }}
                        >
                            {DialogTitleProps.children}
                        </Typography>
                    </Grid>
                    {
                        isDialog &&
                        <Grid item container xs={1} justify='flex-end'>
                            <IconButton onClick={dialogProps.newOnClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </DialogTitle>
            <DialogContent dividers={DialogContentProps.dividers}>
                {React.isValidElement(DialogContentProps.children) ? DialogContentProps.children : <DialogContentText>{DialogContentProps.children}</DialogContentText>}
            </DialogContent>
            <DialogActions>
                {
                    isConfirmation &&
                    <F3MButton className={classes.goBack} color='secondary' onClick={dialogProps.newOnClose} fullWidth={false} variant='text'>
                        {i18n.t('button.goBack')}
                    </F3MButton>
                }
                {
                    DialogActionsProps !== undefined &&
                    <F3MButton className={classes.action} onClick={DialogActionsProps.newOnClick} fullWidth={false}>
                        {DialogActionsProps.label}
                    </F3MButton>
                }
            </DialogActions>
        </Dialog>
    )
}

export default F3MDialog