import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: 20,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[200],
        borderRadius: 4
    },
    dropzoneRoot: {
        minHeight: 210
    }
}))

export default useStyles