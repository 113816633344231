import { isDefined } from './'
import { decrypt, encrypt } from './'

export const setLocalStorage = (n: string, v: any): void => 
	localStorage.setItem(n, encrypt(JSON.stringify(v)))

export const getLocalStorage = (n: string): any => 
	isDefined(localStorage.getItem(n)) ? JSON.parse(decrypt(localStorage.getItem(n)!)) : ''

export const removeLocalStorage = (n: string): void =>
	localStorage.removeItem(n)