import { makeStyles, createStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => createStyles({
    form: {
        width: "100%",
        marginTop: theme.spacing(1)
    },
    button: {
        height: 40,
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1)
        }
    }
}))

export default useStyles