import axios, { AxiosResponse } from 'axios'

import api from './api'

import { CONFIG, STORAGE } from '../../constants'
import { getLocalStorage } from '../../utils'
import { store } from '../..'

import loginOperations from '../../store/operations/loginOperations'

/**
 * Check https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
 */
let isRefreshing = false
let failedQueue: any = []

const processQueue = (error: any, token = null) => {
    failedQueue.forEach((prom: any) => {
        if (error) {
            prom.reject(error)
        } else {
            prom.resolve(token)
        }
    })

    failedQueue = []
}

const responseSuccess = (response: AxiosResponse<any>) => response

const responseFailure = async (error: any) => {
    const request = error.config

    if (request.url.startsWith(CONFIG.API_URL) && error.response.status === 401 && !request._retry) {

        if (isRefreshing) {
            return new Promise(function(resolve, reject) {
                failedQueue.push({resolve, reject})
            }).then(token => {
                request.headers['Authorization'] = 'Bearer ' + token
                return axios(request)
            }).catch(err => {
                return Promise.reject(err)
            })
        }

        request._retry = true
        isRefreshing = true

        await store.dispatch(loginOperations.refreshToken() as any)
        
        const token = getLocalStorage(STORAGE.ACCESS_TOKEN)
        request.headers.Authorization =  'Bearer ' + token
        processQueue(null, token)

        isRefreshing = false

        return api(request)
    }
    
    return new Promise((resolve, reject) => {
        return reject(error.response)
    })
}

// Add a request interceptor
api.interceptors.request.use((request) => {
    if(request.url!.startsWith(CONFIG.API_URL)) {
        request.headers.Authorization =  'Bearer ' + getLocalStorage(STORAGE.ACCESS_TOKEN)
    }
    
    return request
})

// Add a response interceptor
api.interceptors.response.use(responseSuccess, responseFailure)

export { api }