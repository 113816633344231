import React from "react"
import i18n from "../../../i18n"

import useStyles from "./styles"
import { IButtonStyle } from "./types"

const F3MCarouselButton = (props: { buttonLink: string | undefined, buttonColor: string | undefined, buttonText: string | undefined }) => {
    const {
        buttonLink,
        buttonColor,
        buttonText
    } = props

    const buttonStyle: IButtonStyle = {
        color: buttonColor
    }

    const classes = useStyles(buttonStyle)

    return (
        <a className={classes.button} href={buttonLink} target='_blank' rel='noreferrer'>
            <span className={classes.textButton}>
                {buttonText ? buttonText : i18n.t('login.carouselButton')}
            </span>
        </a>
    )
}

export default F3MCarouselButton