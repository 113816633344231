import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    sidebarToggleIcon: {
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    logo: {
        verticalAlign: 'middle',
        [theme.breakpoints.up('sm')]: {
            width: '120px',
            height: '39px'
        },
        [theme.breakpoints.down('xs')]: {
            width: '110px',
            height: '36px'
        }
    },
    contentContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 0,
        marginRight: 0,
        width: '100%'
    },
    avatar: {
        marginLeft: theme.spacing(1),
        width: 30,
        height: 30,
        '& > img': {
            border: '1px solid ' + theme.palette.primary.main,
            borderRadius: '50%'
        }
        
    },
    menuExpand: {
        padding: 0,
        transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.short
        })
    },
    menuExpandClose: {
        transform: 'rotate(-180deg)'
    },
    menuPaper: {
        maxWidth: 240,
        width: 240,
        minWidth: 240
    },
    menuTitle: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    item: {
        marginTop: '12px',
        marginBottom: '12px'
    }
}))

export default useStyles