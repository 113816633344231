import { licenseService } from '../../api'
import { LicensingProductFields, SortDirection } from '../../types'
import { AppThunkAction } from '../../types/store'
import { LicensingActions } from '../../types/store/licensing'
import licensingActions from '../actions/licensingActions'

const {
    clearData,
    handleProductPageChange,
    handleProductRowsPerPageChange,
    loadProductsError,
    loadProductsRequest,
    loadProductsSuccess,
    loadWebProductsError,
    loadWebProductsRequest,
    loadWebProductsSuccess,
    orderProduct
} = licensingActions


const licensingOperations = {
    handleProductChangePage: (event: any, page: number): AppThunkAction<LicensingActions> => async (dispatch, getState) => {
        dispatch(handleProductPageChange(page))
    },

    handleProductChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): AppThunkAction<LicensingActions> => async (dispatch, getState) => {
        dispatch(handleProductRowsPerPageChange(parseInt(event.target.value)))
    },

    loadProducts: (): AppThunkAction<LicensingActions> => async (dispatch, getState) => {
        dispatch(loadProductsRequest())

        try {
            const response = await licenseService.loadProducts(getState().licensing?.products.filter!)
            
            if(response.isSuccess) {
                dispatch(loadProductsSuccess(response.result))
            } else {
                dispatch(loadProductsError(response.errorMessage))
            }
        } catch(e) {
            dispatch(loadProductsError(e.response))
        }
    },

    loadWebProducts: (): AppThunkAction<LicensingActions> => async (dispatch, getState) => {
        dispatch(loadWebProductsRequest())

        try {
            const response = await licenseService.loadWebProducts()
            
            if(response.isSuccess) {
                dispatch(loadWebProductsSuccess(response.result))
            } else {
                dispatch(loadWebProductsError(response.errorMessage))
            }
        } catch(e) {
            dispatch(loadWebProductsError(e.response))
        }
    },

    clearData: (): AppThunkAction<LicensingActions> => async (dispatch, getState) => {
        dispatch(clearData())
    },

    order: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): AppThunkAction<LicensingActions> => async (dispatch, getState) => {
        e.preventDefault()
        const id = e.currentTarget.parentElement!.id

        const {sortDirection, sortField} = getState().licensing?.products.filter!
        let newSortDirection = 'desc'
        let newField = 'date'

        if(sortField === id) {
            newField = sortField
            if(sortDirection === 'asc') {
                newSortDirection = 'desc'
            } else {
                newSortDirection = 'asc'
            }
        } else {
            newSortDirection = 'asc'
            newField = id
        }

        dispatch(orderProduct(newSortDirection as SortDirection, newField as LicensingProductFields))
    }
}

export default licensingOperations