import { convertBytesToMbsOrKbsOrGb } from "."
import i18n from "../i18n"
import { AddUpdtSlide } from "../types/services/carousel"

const isSlideImageDefined = (image: (File | string)[] | null) => {
    return image !== null && image.length > 0
}

const isSlideTitleDefined = (title: string) => {
    return title.length > 0
}

const isSlideButtonDefined = (buttonText: string, buttonLink: string) => {
    return (!buttonText || buttonText.length === 0) || (buttonText.length > 0 && buttonLink.length > 0)
}

export const isSlideValid = (addOrUpdt: AddUpdtSlide) => {
    return isSlideImageDefined(addOrUpdt.image) && isSlideTitleDefined(addOrUpdt.title) && isSlideButtonDefined(addOrUpdt.buttonText, addOrUpdt.buttonLink)
}

export const getFileLimitExceedMessage = (filesLimit: number) => {
    return i18n.t('dropzone.filesLimitExceed').replace('FILE_LIMIT', filesLimit ? filesLimit.toString() : '')
}

export const getFileRemovedMessage = (fileName: string) => {
    return i18n.t('dropzone.fileRemoved').replace('FILE_NAME', fileName)
}

export const getDropRejectMessage = (rejectedFile: File, acceptedFiles: string[], maxFileSize: number) => {
    let message = i18n.t('dropzone.fileRejected').replace('FILE_NAME', rejectedFile.name)

    if (acceptedFiles.length > 0 && !acceptedFiles.includes(rejectedFile.type))
        message += i18n.t('dropzone.fileNotSupported')

    else if (rejectedFile.size > maxFileSize)
        message += i18n.t('dropzone.fileSizeLimit').replace('FILE_SIZE', convertBytesToMbsOrKbsOrGb(maxFileSize))

    return message
}