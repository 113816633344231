import React, { useState } from 'react'

import { TextField } from '@material-ui/core'

import useStyles from './styles'

import { ContentPropsDownCatUpdtCreate } from '../../../F3MAddUpdt/types'

import i18n from '../../../../i18n'
import inputLimit from '../../../../utils/inputLimit'

const F3MCategoryInfo = (props: ContentPropsDownCatUpdtCreate & { handleError: (message: string) => void }) => {

    const classes = useStyles()

    const {
        addOrUpdt,
        setAddOrUpdt,
        handleError
    } = props

    const [name, setName] = useState<string>(addOrUpdt.name ? addOrUpdt.name : '')
    const [description, setDescription] = useState<string>(addOrUpdt.description ? addOrUpdt.description : '')

    const handleChangeName = (value: string) => {
        const nameLimit = inputLimit.common.title
        if (value.length > nameLimit) {
            handleError(i18n.t('common.maxCharSecField', { field: i18n.t('downloadCategories.name'), section: i18n.t('downloadCategories.category'), maxChar: nameLimit }))
        }
        else {
            setName(value)
            setAddOrUpdt(prevState => ({ ...prevState, name: value }))
        }
    };

    const handleChangeDescrp = (value: string) => {
        const descriptionLimit = inputLimit.common.description
        if (value.length > descriptionLimit) {
            handleError(i18n.t('common.maxCharSecField', { field: i18n.t('downloadCategories.description'), section: i18n.t('downloadCategories.category'), maxChar: descriptionLimit }))
        }
        else {
            setDescription(value)
            setAddOrUpdt(prevState => ({ ...prevState, description: value }))
        }
    };

    return (
        <div className={classes.root}>
            <TextField
                id='add-updt-name'
                label={i18n.t('downloadCategories.name')}
                value={name}
                required
                onChange={(e) => handleChangeName(e.target.value)}
                style={{ margin: 8 }}
                fullWidth
                margin='normal'
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                id='add-updt-description'
                label={i18n.t('downloadCategories.description')}
                value={description}
                onChange={(e) => handleChangeDescrp(e.target.value)}
                style={{ margin: 8 }}
                fullWidth
                margin='normal'
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div>
    )
}

export default F3MCategoryInfo