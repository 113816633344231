import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import Login from './views/login/'
import Home from './views/home'
import { ApplicationState } from './types/store'

const mapStateToProps = (state: ApplicationState) => ({
    loginState: state.login
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const App = (props: Props) => {
    return (
        <>
            <meta name="viewport" content="width=device-width, user-scalable=no" />
            {props.loginState?.authenticated ? <Home /> : <Login />}
        </>
    )
}

export default connector(App)
