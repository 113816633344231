import React from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { push } from 'connected-react-router'

import { Button, Grid } from '@material-ui/core'
import { Cached, GetApp, Build } from '@material-ui/icons';

import useStyles from './styles'
import { ROUTES } from '../../../../constants';
import i18n from '../../../../i18n';

const mapDispatchToProps = (dispatch: any) => ({
    push: (p: string) => dispatch(push(p))
})

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const QuickAccessBtns = (props: Props) => {
    const classes = useStyles()

    const { SOFTWARE, SERVICES } = ROUTES

    return (
        <Grid className={classes.quickAccess} container direction='column' alignItems='center'>
            <Button className={classes.quickAccessBtns} onClick={() => props.push(SOFTWARE.INSTALLATION.PATH)}>
                <Build className={classes.icon} /> {i18n.t('menus.software.installations')}
            </Button>
            <Button style={{ margin: '15px 0 15px 0' }} className={classes.quickAccessBtns} onClick={() => props.push(SOFTWARE.UPDATES.PATH)}>
                <Cached className={classes.icon} /> {i18n.t('menus.software.updates')}
            </Button>
            <Button className={classes.quickAccessBtns} onClick={() => props.push(SERVICES.DOWNLOADS.PATH)}>
                <GetApp className={classes.icon} /> {i18n.t('menus.services.downloads')}
            </Button>
        </Grid>
    )
}

export default connector(QuickAccessBtns)