import React, { useState } from 'react'

import useStyles from './styles'

import { ContentPropsAppDownUpdtCreate, ContentPropsDownCatUpdtCreate, ContentPropsDownManUpdtCreate } from '../../F3MAddUpdt/types'
import { FormControlLabel, Switch } from '@material-ui/core'
import i18n from '../../../i18n'

const F3MIsActive = (props: ContentPropsDownCatUpdtCreate | ContentPropsAppDownUpdtCreate | ContentPropsDownManUpdtCreate) => {

    const classes = useStyles()

    const {
        addOrUpdt,
        setAddOrUpdt
    } = props

    const [isActive, setIsActive] = useState<boolean>(addOrUpdt.isActive !== undefined ? addOrUpdt.isActive : true)

    const handleIsActive = async (checked: boolean) => {
        setIsActive(checked)
        setAddOrUpdt((prevState: any) => ({ ...prevState, isActive: checked }))
    }

    return (
        <div className={classes.root}>
            <FormControlLabel
                control={
                    <Switch checked={isActive} onChange={(e, checked) => handleIsActive(checked)} color='primary' size='medium' />
                }
                label={i18n.t('common.active')}
            />
        </div>
    )
}

export default F3MIsActive