import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { api } from './interceptors'

import { ApiResponse } from '../../types/api'

export const request = <T>(config: AxiosRequestConfig): Promise<ApiResponse<T>> => {
    return api.request(config)
        .then(response => {
            const { isSuccess, result, errorMessage } = response.data

            return { isSuccess, result, errorMessage }
        })
        .catch(error => {
            throw error
        })
}

export const download = async (config: AxiosRequestConfig): Promise<AxiosResponse<any>> => {
    try {
        return await api.request({
            responseType: 'arraybuffer',
            ...config
        })
    } catch (error) {
        return error.response
    }
}