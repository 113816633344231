import { createMuiTheme } from '@material-ui/core/styles'

import i18n from '../../i18n'

const defaultTheme = createMuiTheme({
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl'],
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    },
    direction: 'ltr',
    mixins: {
        toolbar: {
            minHeight: 56,
            '@media (min-width:0px) and (orientation: landscape)': {
                minHeight: 48
            },
            '@media (min-width:600px)': {
                minHeight: 64
            }
        }
    },
    palette: {
        common: {
            black: '#000',
            white: '#fff'
        },
        type: 'light',
        primary: {
            light       : '#dbf5ff',
            main        : '#00adef',
            dark        : '#00b8f0', // button hover
            contrastText: '#fff'
        },
        secondary: {
            light       : '#fff',
            main        : '#666666',
            dark        : '#000',
            contrastText: '#fff'
        },
        error: {
            light       : '#fff',
            main        : '#f44336',
            dark        : '#000',
            contrastText: '#fff'
        },
        warning: {
            light       : 'rgba(247, 147, 29, 0.2)',
            main        : '#f7931d',
            dark        : '#f4883c',
            contrastText: '#fff'
        },
        info: {
            light       : 'rgba(0, 63, 110, 0.2)',
            main        : '#003f6e',
            dark        : '#005a9e',
            contrastText: '#fff'
        },
        success: {
            light       : '#81c784',
            main        : '#4caf50',
            dark        : '#388e3c',
            contrastText: '#fff'
        },
        grey: {
            '50'  : '#f5f5f5',
            '100' : '#f2f2f0',
            '200' : '#e6e6e6',
            '300' : '#dadada',
            '400' : '#b8b8b8',
            '500' : '#9e9e9e',
            '600' : '#989898',
            '700' : '#666666',
            '800' : '#494949',
            '900' : '#444444',
            'A100': '#f2f2f0',
            'A200': '#e6e6e6',
            'A400': '#b8b8b8',
            'A700': '#666666'
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
            primary  : '#666666',
            secondary: '#989898',
            disabled : 'rgba(0, 0, 0, 0.38)',
            hint     : 'rgba(0, 0, 0, 0.38)'
        },
        divider: '#dadada',
        background: {
            paper  : '#fff',
            default: '#fff'
        },
        action: {
            active            : 'rgba(0, 0, 0, 0.54)',
            hover             : 'rgba(0, 0, 0, 0.04)',
            hoverOpacity      : 0.04,
            selected          : 'rgba(0, 0, 0, 0.08)',
            selectedOpacity   : 0.08,
            disabled          : 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            disabledOpacity   : 0.38,
            focus             : 'rgba(0, 0, 0, 0.12)',
            focusOpacity      : 0.12,
            activatedOpacity  : 0.12
        }
    },
    props: {
        MuiTablePagination: {
            labelRowsPerPage: i18n.t('common.itemsPerPage'),
            backIconButtonText: i18n.t('common.previousPage'),
            nextIconButtonText: i18n.t('common.nextPage'),
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${i18n.t('common.of')} ${count}`
        },
        MuiCard: {
            elevation: 2
        },
        MuiAppBar: {
            elevation: 2
        }
    },
    shadows: [
        'none',
        '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
        '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
        '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
        '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
        '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
        '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
        '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
        '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
        '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
        '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
        '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
        '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
        '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
        '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
        '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
        '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
        '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
        '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
        '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
        '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
        '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)'
    ],
    typography: {
        htmlFontSize: 16,
        fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        h1: {
            fontFamily   : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            fontWeight   : 900,
            fontSize     : 28,
            lineHeight   : 1.214,
            letterSpacing: '-0.01562em',
            textTransform: 'uppercase'
        },
        h2: {
            fontFamily   : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            fontWeight   : 700,
            fontSize     : 24,
            lineHeight   : 1.25,
            letterSpacing: '-0.00833em',
            textTransform: 'uppercase'
        },
        h3: {
            fontFamily   : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            fontWeight   : 500,
            fontSize     : 20,
            lineHeight   : 1.3,
            letterSpacing: '0em',
            textTransform: 'uppercase'
        },
        h4: {
            fontFamily   : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            fontWeight   : 700,
            fontSize     : 18,
            lineHeight   : 1.222,
            letterSpacing: '0.00735em',
            textTransform: 'uppercase'
        },
        h5: {
            fontFamily   : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            fontWeight   : 700,
            fontSize     : 16,
            lineHeight   : 1.25,
            letterSpacing: '0em',
            textTransform: 'uppercase'
        },
        h6: { //Not being used
            fontFamily   : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            fontWeight   : 500,
            fontSize     : 14,
            lineHeight   : 1.286,
            letterSpacing: '0.0075em',
            textTransform: 'uppercase'
        },
        subtitle1: {
            fontFamily   : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            fontWeight   : 500,
            fontSize     : 18,
            lineHeight   : 1.167,
            letterSpacing: '0.00938em'
        },
        subtitle2: { //Not being used
            fontFamily   : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            fontWeight   : 500,
            fontSize     : '0.875rem',
            lineHeight   : 1.57,
            letterSpacing: '0.00714em'
        },
        body1: {
            fontFamily   : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            fontWeight   : 400,
            fontSize     : 14,
            lineHeight   : 1.286,
            letterSpacing: '0.00938em'
        },
        body2: {
            fontFamily   : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            fontWeight   : 400,
            fontSize     : 12,
            lineHeight   : 1.2,
            letterSpacing: '0.01071em'
        },
        button: {
            fontFamily   : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            fontWeight   : 500,
            fontSize     : 14,
            lineHeight   : 1.286,
            letterSpacing: '0.02857em',
            textTransform: 'uppercase'
        },
        caption: { //Not being used
            fontFamily   : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            fontWeight   : 400,
            fontSize     : '0.75rem',
            lineHeight   : 1.66,
            letterSpacing: '0.03333em'
        },
        overline: { //Not being used
            fontFamily   : ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
            ontWeight    : 400,
            fontSize     : '0.75rem',
            lineHeight   : 2.66,
            letterSpacing: '0.08333em',
            textTransform: 'uppercase'
        }
    },
    shape: {
        borderRadius: 4
    },
    transitions: {
        easing: {
            easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
            easeOut  : 'cubic-bezier(0.0, 0, 0.2, 1)',
            easeIn   : 'cubic-bezier(0.4, 0, 1, 1)',
            sharp    : 'cubic-bezier(0.4, 0, 0.6, 1)'
        },
        duration: {
            shortest      : 150,
            shorter       : 200,
            short         : 250,
            standard      : 300,
            complex       : 375,
            enteringScreen: 225,
            leavingScreen : 195
        }
    },
    zIndex: {
        mobileStepper: 1000,
        speedDial    : 1050,
        drawer       : 1100,
        appBar       : 1200,
        modal        : 1300,
        snackbar     : 1400,
        tooltip      : 1500
    }
})

const { breakpoints, palette, zIndex, spacing } = defaultTheme

const theme = {
    ...defaultTheme,
    overrides: {
        MuiTypography: {
            h1: {
                [breakpoints.down('xs')]: {
                    fontSize: 22
                }
            },
            h2: {
                [breakpoints.down('xs')]: {
                    fontSize: 18
                }
            },
            h3: {
                [breakpoints.down('xs')]: {
                    fontSize: 14
                }
            },
            h4: {
                [breakpoints.down('xs')]: {
                    fontSize: 14
                }
            },
            h5: {
                [breakpoints.down('xs')]: {
                    fontSize: 14
                }
            },
            h6: {
                [breakpoints.down('xs')]: {
                    fontSize: 14
                }
            },
            body1: {
                [breakpoints.down('xs')]: {
                    fontSize: 12
                }
            },
            body2: {
                [breakpoints.down('xs')]: {
                    fontSize: 12
                }
            },
            button: {
                [breakpoints.down('xs')]: {
                    fontSize: 12
                }
            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: palette.grey['300'],
                borderWidth: 1,
                '&:hover': {
                    borderColor: palette.secondary.main
                }
            }
        },
        MuiInputAdornment: {
            root: {
                color: palette.primary.main,
                '& > button': {
                    padding: 0
                }
            }
        },
        MuiCheckbox: {
            root: {
                color: palette.text.primary
            }
        },
        MuiLink: {
            root: {
                '&:hover': {
                    color: palette.primary.main
                }
            }
        },
        MuiAppBar: {
            root: {
                height: 80
            },
            colorPrimary: {
                backgroundColor: palette.common.white
            }
        },
        MuiDrawer: {
            root: {
                zIndex: zIndex.drawer + ' !important'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: '80px !important'
            },
            gutters: {
                [breakpoints.up('lg')]: {
                    padding: spacing(0, 2, 0, 17)
                },
                [breakpoints.down('sm')]: {
                    padding: spacing(0, 2, 0, 2)
                }
            }
        },
        MuiCard: {
            root: {
                marginTop: spacing(4),
                padding: spacing(2, 2, 2, 2)
            }
        },
        MuiTableContainer: {
            root: {
                paddingTop: spacing(2)
            }
        },
        MuiTable: {
            root: {
                border: '1px solid ' + palette.grey[300],
                borderRadius: 4,
                borderCollapse: 'separate'
            }
        },
        MuiTableHead: {
            root: {
                backgroundColor: palette.primary.light,
                borderBottom: '1px solid'
            }
        },
        MuiTableRow: {
            root: {
                '&:nth-child(even)': {
                    backgroundColor: palette.grey[50]
                },
                '&:nth-child(odd)': {
                    backgroundColor: palette.common.white
                },
                '&:not(:last-child) > td': {
                    borderBottom: '1px solid ' + palette.grey[300]
                }
            },
            head: {
                backgroundColor: palette.primary.light + ' !important',
                borderTopWidth: 1,
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4
            },
            hover: {
                cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: '#EBF9FF !important'
                    }
            }
        },
        MuiTableCell: {
            root: {
                minHeight: 50,
                borderBottom: 'none',
                padding: 15
            },
            head: {
                textTransform: 'uppercase',
                borderBottom: '1px solid ' + palette.grey[300],
                [breakpoints.up('md')]: {
                    fontSize: 14
                },
                [breakpoints.down('xs')]: {
                    fontSize: 13
                },
                color: palette.primary.main,
                fontWeight: 500
                
            },
            body: {
                [breakpoints.up('md')]: {
                    fontSize: 16
                },
                [breakpoints.down('xs')]: {
                    fontSize: 14
                },
                color: palette.text.primary
            }
        },
        MuiTablePagination: {
            root: {},
            toolbar: {
                paddingLeft: 0,
                paddingRight: 0
            },
            spacer: {},
            caption: {
                color: palette.text.primary
            },
            input: {
                color: palette.text.primary
            },
            menuItem: {
                color: palette.text.primary
            }
        },
        MuiTableSortLabel: {
            root: {
                color: 'inherit',
                '&:hover': {
                    color: palette.primary.dark
                }
            },
            icon: {
                color: 'inherit !important'
            },
            active: {
                color: 'inherit !important'
            }
        },
        MuiMenuItem: {
            root: {
                '&:hover': {
                    backgroundColor: palette.common.white,
                    color: palette.primary.main
                }
            }
        },
        MuiDialogTitle: {
            root: {
                color: palette.text.secondary
            }
        },
        MuiDialogContent: {
            dividers: {
                borderTopColor: '#D2D2D2',
                borderBottomColor: '#D2D2D2'
            }
        },
        MuiDialogActions: {
            root: {
                padding: spacing(1, 3)
            }
        },
        MuiSvgIcon: {
            fontSizeLarge: {
                fontSize: 30
            }
        },
        MuiAvatar: {
            root: {
                maxHeight: '35px',
                width: '35px',
                display: 'inline-block'
            },
            rounded: {
                borderRadius: 2
            }
        }
    }
}

export default theme