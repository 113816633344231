import { AssistanceContractsResponse, ServiceContractsResponse } from '../../types/services/contracts'
import {
    LoadServiceContractsRequest,
    LoadServiceContractsSuccess,
    LoadServiceContractsError,
    LoadAssistanceContractsRequest,
    LoadAssitanceContractsSuccess,
    LoadAssitanceContractsError,
    ClearData
} from '../../types/store/contract'

const contractActions = {
    loadServiceContractsRequest: (): LoadServiceContractsRequest => {
        return {type: 'CONTRACT_LOAD_SERVICE_CONTRACTS_REQUEST'}
    },

    loadServiceContractsSuccess: (data: ServiceContractsResponse[]): LoadServiceContractsSuccess => {
        return {type: 'CONTRACT_LOAD_SERVICE_CONTRACTS_SUCCESS', payload: data}
    },

    loadServiceContractsError: (data: string): LoadServiceContractsError => {
        return {type: 'CONTRACT_LOAD_SERVICE_CONTRACTS_ERROR', payload: data}
    },

    loadAssistanceContractsRequest: (): LoadAssistanceContractsRequest => {
        return {type: 'CONTRACT_LOAD_ASSISTANCE_CONTRACTS_REQUEST'}
    },

    loadAssistanceContractsSuccess: (data: AssistanceContractsResponse[]): LoadAssitanceContractsSuccess => {
        return {type: 'CONTRACT_LOAD_ASSISTANCE_CONTRACTS_SUCCESS', payload: data}
    },

    loadAssistanceContractsError: (data: string): LoadAssitanceContractsError => {
        return {type: 'CONTRACT_LOAD_ASSISTANCE_CONTRACTS_ERROR', payload: data}
    },

    clearData: (): ClearData => {
        return {type: 'CONTRACT_CLEAR_DATA'}
    }
}

export default contractActions