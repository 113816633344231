import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import clsx from 'clsx'

import { ClickAwayListener, Fab, Drawer } from '@material-ui/core'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import SidebarContent from '../Content'

import { ApplicationState } from '../../../types/store'
import commonOperations from '../../../store/operations/commonOperations'

import useStyles from './styles'

const mapStateToProps = (state: ApplicationState) => ({
    commonState: state.common
})

const mapDispatchToProps = (dispatch: any) => ({
    toggleSidebar:  (e: React.MouseEvent<Document | HTMLButtonElement, MouseEvent>) => dispatch(commonOperations.toggleSidebar(e))
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const DesktopSidebar = (props: Props) => {
    const classes = useStyles()

    const {sidebarOpen} = props.commonState!

    const clickAway =  (e: React.MouseEvent<Document | HTMLButtonElement, MouseEvent>): void => {
        if(sidebarOpen) {
            props.toggleSidebar(e)
        }
    }
    
    return (
        <>
            <ClickAwayListener onClickAway={clickAway}>
                <div>
                    <Fab
                        className = {clsx(classes.sidebarToggleFab, sidebarOpen ? classes.sidebarToggleFabOpen : classes.sidebarToggleFabClose)}
                        size      = 'small'
                        onClick   = {props.toggleSidebar}
                    >
                        <ChevronRightIcon className={classes.sidebarToggleIcon} />
                    </Fab>

                    <Drawer
                        variant='permanent'
                        className={clsx(classes.drawerRoot, {
                            [classes.drawerClosed]: !sidebarOpen
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerRoot]: sidebarOpen,
                                [classes.drawerClosed]: !sidebarOpen
                            })
                        }}
                        PaperProps={{ elevation: 3 }}
                    >
                        <SidebarContent/>
                    </Drawer>
                </div>
            </ClickAwayListener>
        </>
    )
}

export default connector(DesktopSidebar)