import { CONFIG, ENDPOINTS } from '../../constants'
import { ApplicationResponse } from '../../types/services/application'
import { ContractResponse, IncidentsResponse } from '../../types/services/incidents'
import { IncidentsRequest } from '../../types/services/incidents/IncidentsRequest'
import { download, request } from '../axios'

export const incidentsService = {
    getIncidentPDF: async (id: string) => {
        const response = await download({
            method : 'GET',
            url    : CONFIG.API_URL + ENDPOINTS.INCIDENT_PDF + '/' + id
        })

        return response
    },

    loadIncidents: async (params: IncidentsRequest) => {
        const response = await request<IncidentsResponse>({
            method : 'GET',
            url    : CONFIG.API_URL + ENDPOINTS.INCIDENT,
            params   : params
        })

        return response
    },

    loadContracts: async () => {
        const response = await request<ContractResponse[]>({
            method : 'GET',
            url    : CONFIG.API_URL + ENDPOINTS.INCIDENT_CONTRACTS
        })

        return response
    },

    loadApplications: async () => {
        const response = await request<ApplicationResponse[]>({
            method : 'GET',
            url    : CONFIG.API_URL + ENDPOINTS.INCIDENT_APPLICATION
        })

        return response
    },
    
    getExcel: async (params: IncidentsRequest) => {
        const response = await download({
            method : 'GET',
            url    : CONFIG.API_URL + ENDPOINTS.INCIDENT_EXCEL,
            params : params
        })

        return response
    }
}