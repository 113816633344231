import React, { useState } from 'react'

import { Box, Tab, Tabs, useTheme } from '@material-ui/core'

import { F3MBreadcrumbs, F3MCard } from '../../../../components'

import { ApplicationDownload, DownloadCategories, DownloadCategoryDownloads } from './Cards'
import i18n from '../../../../i18n'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const ApplicationsManager = () => {
    const theme = useTheme()
    const [value, setValue] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <F3MBreadcrumbs path={i18n.t('paths.applicationDownload.breadcrumb')} />

            <F3MCard loading={loading}>
                <Box style={{
                    backgroundColor: theme.palette.background.paper,
                    marginLeft: -16,
                    marginTop: -16,
                    marginRight: -16,
                    marginBottom: 32
                }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor='primary'
                        textColor='primary'
                        variant='fullWidth'
                        style={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)' }}
                    >
                        <Tab label={i18n.t('installationsAndUpdates.uploadByProduct')} />
                        <Tab label={i18n.t('installationsAndUpdates.categoriesByBusinessArea')} />
                        <Tab label={i18n.t('installationsAndUpdates.uploadByCategory')} />
                    </Tabs>
                </Box>

                <TransitionGroup component={undefined}>
                    <CSSTransition
                        key={value}
                        classNames='fade'
                        appear={true}
                        timeout={{ enter: 750, exit: 0 }}
                    >
                        <>
                            {value === 0 && <ApplicationDownload setLoading={setLoading} loading={loading} />}
                            {value === 1 && <DownloadCategories setLoading={setLoading} loading={loading} />}
                            {value === 2 && <DownloadCategoryDownloads setLoading={setLoading} loading={loading} />}
                        </>
                    </CSSTransition>
                </TransitionGroup>
            </F3MCard>
        </>
    )
}

export default ApplicationsManager