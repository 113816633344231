import React, { useState } from 'react'

import useStyles from './styles'

import { ContentPropsDownCatUpdtCreate } from '../../../F3MAddUpdt/types'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import i18n from '../../../../i18n'

const F3MCardType = (props: ContentPropsDownCatUpdtCreate) => {

    const classes = useStyles()

    const {
        addOrUpdt,
        setAddOrUpdt
    } = props

    const [cardType, setCardType] = useState<string | null>(addOrUpdt.cardType !== undefined ? addOrUpdt.cardType.toString() : null)

    const handleChangeCardType = (cardType: string) => {
        setCardType(cardType)
        setAddOrUpdt(prevState => ({...prevState, cardType: +cardType}))
    };

    return (
        <div className={classes.root}>
            <FormControl component='fieldset'>
                <RadioGroup row aria-label='cardType' name='cardType' value={cardType} onChange={(e) => handleChangeCardType(e.target.value)}>
                    <FormControlLabel disabled value='0' control={<Radio color='primary' />} label={i18n.t('enum.cardTypes.type.0')} />
                    <FormControlLabel value='1' control={<Radio color='primary' />} label={i18n.t('enum.cardTypes.type.1')} />
                </RadioGroup>
            </FormControl>
        </div>
    )
}

export default F3MCardType