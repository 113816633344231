import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { push } from 'connected-react-router'

import { Button, Typography } from '@material-ui/core'

import VisibilityIcon from '@material-ui/icons/Visibility'

import { F3MDownload, F3MDataGrid, F3MDataGridColumnProps, F3MDataGridRowData, F3MCard } from '../../../../components'

import { ApplicationState } from '../../../../types/store'
import { licensingOperations } from '../../../../store/operations'

import i18n from '../../../../i18n'
import { isDefined } from '../../../../utils'
import { licenseService } from '../../../../api'

import useStyles from './styles'

import { ROUTES } from '../../../../constants'

const mapStateToProps = (state: ApplicationState) => ({
    licensingState: state.licensing
})

const mapDispatchToProps = (dispatch: any) => ({
    handleProductChangePage: (e: any, p: number) => dispatch(licensingOperations.handleProductChangePage(e, p)),
    handleProductChangeRowsPerPage: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => dispatch(licensingOperations.handleProductChangeRowsPerPage(e)),
    loadProducts: () => dispatch(licensingOperations.loadProducts()),
    clearData: () => dispatch(licensingOperations.clearData()),
    order: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => dispatch(licensingOperations.order(e)),
    push: (p: string) => dispatch(push(p))
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const TableProducts = (props: Props & { handleError: (message: string) => void, isHomePage?: boolean }) => {
    const classes = useStyles()

    const { SOFTWARE } = ROUTES

    const { products } = props.licensingState!

    const [isGettingPDF, setIsGettingPDF] = useState<boolean>(false)

    useEffect(() => {
        // On Mount
        props.loadProducts()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const order = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        props.order(e)
        props.loadProducts()
    }

    const handleChangePage = (e: any, p: number) => {
        props.handleProductChangePage(e, p)
        props.loadProducts()
    }

    const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.handleProductChangeRowsPerPage(e)
        props.loadProducts()
    }

    let productColumns: F3MDataGridColumnProps[] = [
        { field: 'applicationName', headerName: i18n.t('common.application'), width: 40, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'version', headerName: i18n.t('common.version'), width: 10, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'network', headerName: i18n.t('product.network'), width: 10, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'salesDate', headerName: i18n.t('common.date'), width: 10, sortable: true, type: 'date', align: 'right', headerAlign: 'right' },
        { field: 'download', headerName: i18n.t('product.download'), width: 15, sortable: false, type: 'node', align: 'center', headerAlign: 'center' },
        { field: 'view', headerName: i18n.t('product.view'), width: 15, sortable: false, type: 'node', align: 'center', headerAlign: 'center' }
    ]

    let productData: F3MDataGridRowData[] = isDefined(products.data) ? products.data.map((r, i) => (
        {
            id: r.id,
            applicationName: r.applicationName.toUpperCase(),
            version: r.version,
            network: r.network ? i18n.t('common.yes') : i18n.t('common.no'),
            salesDate: r.salesDate,
            download: r.hasLicense && <F3MDownload handleError={props.handleError} filename={i18n.t('product.productFile') + ' - ' + r.applicationName + '.xml'}
                isLoading={isGettingPDF} setLoading={setIsGettingPDF} request={() => licenseService.downloadProductLicense(r.id)} />,
            view: r.hasLicense && <F3MDownload
                handleError={props.handleError} filename={i18n.t('product.productFile') + ' - ' + r.applicationName + '.pdf'}
                isLoading={isGettingPDF} setLoading={setIsGettingPDF} icon={<VisibilityIcon />} request={() => licenseService.getProductLicensePDF(r.id)} />
        }
    )) : []

    return (
        <F3MCard loading={products.loading || isGettingPDF}>
            <Typography variant='h5' color='primary' gutterBottom>{i18n.t('product.products')}</Typography>
            <F3MDataGrid
                columns={productColumns}
                rows={productData}
                loading={products.loading}
                sortField={products.filter.sortField}
                sortDirection={products.filter.sortDirection}
                sortChange={order}
                pagination={props.isHomePage !== true ? true : false}
                paginationMode='server'
                rowCount={props.isHomePage !== true ? products.rowCount : undefined}
                pageSize={props.isHomePage !== true ? products.filter.rowsPerPage : undefined}
                page={props.isHomePage !== true ? products.filter.page : undefined}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={props.isHomePage !== true ? [5, 10, 15, 20] : []}
            />
            { props.isHomePage === true &&
                <Button className={classes.button} onClick={() => props.push(SOFTWARE.LICENSING.PATH)}>
                    {i18n.t('homepage.seeMore')}
                </Button>
            }
        </F3MCard>
    )
}

export default connector(TableProducts)