import { LicensingProductFields, SortDirection } from '../../types'
import { ProductsResponse, WebProductResponse } from '../../types/services/license'
import {
    ClearData,
    HandleProductPageChange,
    HandleProductRowsPerPageChange,
    LoadProductsError,
    LoadProductsRequest,
    LoadProductsSuccess,
    LoadWebProductsError,
    LoadWebProductsRequest,
    LoadWebProductsSuccess,
    OrderProduct
} from '../../types/store/licensing'

const licensingActions = {
    loadProductsRequest: (): LoadProductsRequest => {
        return {type: 'LICENSING_LOAD_PRODUCTS_REQUEST'}
    },

    loadProductsSuccess: (products: ProductsResponse): LoadProductsSuccess => {
        return {type: 'LICENSING_LOAD_PRODUCTS_SUCCESS', payload: products}
    },

    loadProductsError: (error: string): LoadProductsError => {
        return {type: 'LICENSING_LOAD_PRODUCTS_ERROR', payload: error}
    },

    loadWebProductsRequest: (): LoadWebProductsRequest => {
        return {type: 'LICENSING_LOAD_WEB_PRODUCTS_REQUEST'}
    },

    loadWebProductsSuccess: (products: WebProductResponse[]): LoadWebProductsSuccess => {
        return {type: 'LICENSING_LOAD_WEB_PRODUCTS_SUCCESS', payload: products}
    },

    loadWebProductsError: (error: string): LoadWebProductsError => {
        return {type: 'LICENSING_LOAD_WEB_PRODUCTS_ERROR', payload: error}
    },

    clearData: (): ClearData => {
        return {type: 'LICENSING_CLEAR_DATA'}
    },

    handleProductPageChange: (page: number): HandleProductPageChange => {
        return {type: 'LICENSING_HANDLE_PRODUCTS_PAGE_CHANGE', payload: page}
    },

    handleProductRowsPerPageChange: (rowsPerPage: number): HandleProductRowsPerPageChange => {
        return {type: 'LICENSING_HANDLE_PRODUCTS_ROWS_PER_PAGE_CHANGE', payload: rowsPerPage}
    },

    orderProduct: (sortDirection: SortDirection, sortField: LicensingProductFields): OrderProduct => {
        return {type: 'LICENSING_PRODUCTS_ORDER', payload: {sortDirection, sortField}}
    }
}

export default licensingActions