import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    listRoot: {
        paddingTop: 0,
        paddingBottom: 0,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 170
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 140
        }
    },
    listItemRoot: {
        paddingTop: 20,
        paddingBottom: 20,
        borderLeft: 'solid 3px transparent',
        color: theme.palette.grey[500],
        '&.Mui-selected': {
            color: theme.palette.primary.main + ' !important',
            fontWeight: 500,
            backgroundColor: theme.palette.common.white
        },
        '&.Mui-selected:hover': {
            color: theme.palette.primary.main + ' !important',
            fontWeight: 500,
            backgroundColor: theme.palette.common.white
        },
        '&:hover': {
            color: theme.palette.primary.main + ' !important',
            backgroundColor: theme.palette.common.white + ' !important'
        }
    },
    listItemSelected: {
        backgroundColor: theme.palette.common.white + ' !important',
        color: theme.palette.primary.main + ' !important',
        fontWeight: 500,
        borderLeft: 'solid 3px ' + theme.palette.primary.main,
    },
    listItemIcon: {
        marginLeft: 31, //border (3px)
        marginRight: theme.spacing(1),
        '&.MuiListItemIcon-root': {
            color: 'inherit'
        }
    },
    listItemIconIcon: {
        color: 'inherit !important',
        overflow: 'visible',
        width: 32,
        height: 32
    },
    listItemExpandIcon: {
        marginRight: theme.spacing(2),
        transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.short
        })
    },
    listItemExpandMoreIcon: {
        color: 'inherit',
        transform: 'rotate(90deg)'
    },
    listItemExpandLessIcon: {
        color: 'inherit',
        transform: 'rotate(-90deg)'
    },
    listItemText: {
        textTransform: 'uppercase',
        fontSize: 15,
        fontWeight: 500,
        color: 'inherit',
        marginTop: 0,
        marginBottom: 0
    },
    collapseListItem: {
        padding: theme.spacing(0.5, 0, 0.5, 12),
        fontSize: 16,
        color: theme.palette.text.primary,
        fontWeight: 400,
        '&.Mui-selected': {
            color: theme.palette.primary.main,
            fontWeight: 500,
            backgroundColor: theme.palette.common.white
        },
        '&.Mui-selected:hover': {
            color: theme.palette.primary.main,
            fontWeight: 500,
            backgroundColor: theme.palette.common.white
        },
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.common.white + ' !important'
        }
    }
}))

export default useStyles