import { makeStyles, createStyles } from '@material-ui/core'
import { IButtonStyle } from '.'

const useStyles = makeStyles(() => createStyles({
    button: {
        width: '110px',
        height: '30px',
        borderRadius: '4px',
        textDecoration: 'none',
        backgroundColor: (props: IButtonStyle) => props.color ? props.color : '#f7931d'
    },
    textButton: {
        fontWeight: 500,
        fontFamily: 'Roboto',
        lineHeight: '30px',
        fontSize: '12px',
        color: '#ffffff',
        textAlign: 'center'
    }
}))

export default useStyles