import { CONFIG, ENDPOINTS } from '../../constants'
import { download } from '../axios'

export const remoteAssistanceService = {
    downloadRemoAssistExe: async () => {
        const response = await download({
            method : 'GET',
            url    : CONFIG.API_URL + ENDPOINTS.REMOTEASSISTANCE
        })

        return response
    }
}