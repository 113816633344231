import React from 'react'

import { DropzoneArea } from 'material-ui-dropzone'

import i18n from '../../../i18n'

import { getDropRejectMessage, getFileLimitExceedMessage, getFileRemovedMessage, isDefined } from '../../../utils'

import { AddUpdtDropzone } from '../../F3MAddUpdt/types'

import useStyles from './styles'

const F3MDropzoneSection = (props: AddUpdtDropzone) => {

    const classes = useStyles()

    const {
        handleFileChange,
        getFileAddedMessage,
        initialFiles,
        filesLimit,
        acceptedFiles,
        maxFileSize,
        showPreviews,
        showPreviewsInDropzone,
        useChipsForPreview,
        previewGridProps,
        previewChipProps,
        centerInformation,
        detailsMessage
    } = props

    return (
        <>
            <div className={classes.root}>
                <DropzoneArea
                    alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}
                    dropzoneClass={classes.dropzoneRoot}
                    dropzoneText={centerInformation ? centerInformation : i18n.t('dropzone.dropzoneText')}
                    getDropRejectMessage={getDropRejectMessage}
                    getFileAddedMessage={getFileAddedMessage}
                    getFileLimitExceedMessage={getFileLimitExceedMessage}
                    getFileRemovedMessage={getFileRemovedMessage}
                    previewText={i18n.t('common.files')}
                    filesLimit={filesLimit}
                    acceptedFiles={acceptedFiles}
                    initialFiles={initialFiles}
                    maxFileSize={maxFileSize}
                    onChange={(files) => handleFileChange(files)}
                    showPreviews={showPreviews}
                    showPreviewsInDropzone={showPreviewsInDropzone}
                    useChipsForPreview={useChipsForPreview}
                    previewGridProps={previewGridProps}
                    previewChipProps={previewChipProps}
                />
            </div>
            {isDefined(detailsMessage) &&
                <p>{detailsMessage}</p>
            }
        </>
    )
}

export default F3MDropzoneSection
