import { request } from '..'
import { CONFIG, ENDPOINTS } from '../../constants'
import { BusinessArea } from '../../types/services/backoffice'

export const backofficeService = {
    loadBusinessAreas: async () => {
        const response = await request<BusinessArea[]>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.BUSINESSAREAS
        })

        return response
    }
}