import { useState, useEffect } from 'react'

interface IWindowSize {
	width?: number
	height?: number
}

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState<IWindowSize>({width: undefined, height: undefined})
  
	useEffect(() => {
	  // Handler to call on window resize
		const handleResize = () => setWindowSize({width: window.innerWidth, height: window.innerHeight})
		window.addEventListener('resize', handleResize)
	  	handleResize()
	  
	  	return () => window.removeEventListener('resize', handleResize)
	}, [])
  
	return windowSize
}
