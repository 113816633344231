import React from 'react'
import clsx from 'clsx'

import { Box, Card, CircularProgress } from '@material-ui/core'

import { isDefined } from '../../utils'

import { F3MCardProps } from './types'

import useStyles from './styles'

const F3MCard = (props: F3MCardProps) => {
    const classes = useStyles()

    let cardProps: F3MCardProps = {
        // CHILDREN
        children: props.children,

        // CLASS NAME
        className: isDefined(props.className) ? clsx(props.className, classes.root) : classes.root,

        // COMPONENT
        component: props.component,

        // ELEVATION
        elevation: props.elevation,

        // LOADING
        loading: props.loading,

        // RAISED
        raised: props.raised,

        // SQUARE
        square: props.square,

        // STYLE
        style: props.style,

        // VARIANT
        variant: props.variant
    }

    return (
        <Card
            className = {cardProps.className}
            component = {cardProps.component}
            elevation = {cardProps.elevation}
            raised    = {cardProps.raised}
            square    = {cardProps.square}
            style     = {cardProps.style}
            variant   = {cardProps.variant}
        >
            {cardProps.loading &&
                <Box className={classes.loading}>
                    <CircularProgress/>
                </Box>
            }
            {cardProps.children}
        </Card>
    )
}

export default F3MCard