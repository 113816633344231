import { CONFIG, ENDPOINTS } from '../../constants'
import { DownCatBusinessAreasResponse, DownloadCategoriesFilter, DownloadCategoriesResponse, DownloadCategoryResponse, DownloadCategoryUpdtCreate } from '../../types/services/downloadCategories'
import { request } from '../axios'

export const downloadCategoriesService = {
    loadDownloadCategories: async (filter: DownloadCategoriesFilter) => {
        const response = await request<DownloadCategoriesResponse>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.DOWNLOADCATEGORY,
            params: filter
        })

        return response
    },

    loadDownCatBusAreas: async () => {
        const response = await request<DownCatBusinessAreasResponse>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.DOWNCATBUSINESSAREAS
        })

        return response
    },

    createDownloadCategory: async (downloadCategory: DownloadCategoryUpdtCreate) => {
        const response = await request<DownloadCategoryResponse>({
            method: 'POST',
            url: CONFIG.API_URL + ENDPOINTS.DOWNLOADCATEGORY,
            data: downloadCategory
        })

        return response
    },

    updateDownloadCategory: async (downloadCategory: DownloadCategoryUpdtCreate, isChangeStatus: boolean | undefined) => {
        const response = await request<DownloadCategoryResponse>({
            method: 'PUT',
            url: CONFIG.API_URL + ENDPOINTS.DOWNLOADCATEGORY,
            data: { ...downloadCategory, IsChangeStatus: isChangeStatus === undefined ? false : true }
        })

        return response
    },

    deleteDownloadCategory: async (id: number) => {
        const response = await request<DownloadCategoriesResponse>({
            method: 'DELETE',
            url: CONFIG.API_URL + ENDPOINTS.DOWNLOADCATEGORY + '/' + id
        })

        return response
    }
}