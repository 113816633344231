import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('xs')]: {
            height: '350px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '270px'
        }
    },
    container: {
        width: 'calc(30.775 * (1vw + 1vh - 1vmin))',
        [theme.breakpoints.up('xs')]: {
            height: '350px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '270px'
        },
        fill: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    text: {
        color: theme.palette.common.white,
        textAlign: 'center'
    }
}))

export default useStyles