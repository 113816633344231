import Dashboard from '../views/home/other/dashboard'
import Login from '../views/login/'
import Contracts from '../views/home/services/contracts'
import Incidents from '../views/home/services/incidents'
import Reports from '../views/home/services/reports'
import Downloads from '../views/home/software/downloads'
import Installation from '../views/home/software/installation'
import Licensing from '../views/home/software/licensing'
import Updates from '../views/home/software/updates'
import CarouselManager from '../views/home/backoffice/carousel'
import ApplicationsManager from '../views/home/backoffice/applications'
import DownloadManager from '../views/home/backoffice/downloads'

export const ROUTES = {
    LOGIN: {
        PATH: '/',
        COMPONENT: Login
    },
    HOMEPAGE: {
        PATH: '/',
        COMPONENT: Dashboard
    },
    SOFTWARE: {
        BASE: '/software',
        LICENSING: {
            PATH: '/software/licensing',
            COMPONENT: Licensing
        },
        INSTALLATION: {
            PATH: '/software/installation',
            COMPONENT: Installation
        },
        UPDATES: {
            PATH: '/software/updates',
            COMPONENT: Updates
        }
    },
    SERVICES: {
        BASE: '/services',
        INCIDENTS: {
            PATH: '/services/incidents',
            COMPONENT: Incidents
        },
        CONTRACTS: {
            PATH: '/services/contracts',
            COMPONENT: Contracts
        },
        REPORTS: {
            PATH: '/services/reports',
            COMPONENT: Reports
        },
        DOWNLOADS: {
            PATH: '/services/downloads',
            COMPONENT: Downloads
        }
    },
    TRAINING: {
        REDIRECT: 'https://trainingcentre.f3m.pt/',
        TARGET: '_blank'
    },
    BACKOFFICE: {
        CAROUSEL: {
            PATH: '/backoffice/Carousel',
            COMPONENT: CarouselManager
        },
        APPLICATIONS: {
            PATH: '/backoffice/applications',
            COMPONENT: ApplicationsManager
        },
        DOWNLOADS: {
            PATH: '/backoffice/downloads',
            COMPONENT: DownloadManager
        }
    }
}