import React, { ReactNode, useEffect, useState } from 'react'

import { CircularProgress, TextField } from '@material-ui/core'

import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteCloseReason, AutocompleteInputChangeReason, AutocompleteRenderInputParams } from '@material-ui/lab'

import { F3MAutocompleteAsyncProps, LookupObject } from './types'

const F3MAutocompleteAsync = (props: F3MAutocompleteAsyncProps) => {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState<LookupObject[]>([])
    const loading = open && options.length === 0
  
    useEffect(() => {
        let active = true
    
        if (!loading) {
            return undefined
        }

        const lookup = async () => {
            try {
                const response = await props.lookup(props.search)

                if(active)
                    setOptions(response.result)
            } catch (e) {
                setOptions([])
            }
        }
    
        lookup()
    
        return () => {
            active = false
        }
    }, [loading, props])

    const onOpen = (event: React.ChangeEvent<{}>) => {
        setOpen(true)
    }

    const onClose = (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
        setOpen(false)
    }

    const getOptionSelected = (option: LookupObject, value: LookupObject) => {
        return option.id === value.id
    }

    const getOptionLabel = (option: LookupObject) => {
        return option.label
    }

    const onChange = (event: React.ChangeEvent<{}>, value: LookupObject | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<LookupObject> | undefined) => {
        props.onChange(value)
    }

    const onInputChange = (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
        setOptions([])
        props.onInputChange(value)
    }

    const Input = (params: AutocompleteRenderInputParams): ReactNode => {
        return (
            <TextField
                {...params}
                label      = {props.label}
                variant    = {props.variant ?? 'outlined'}
                fullWidth  = {true}
                size       = 'small'
                InputProps = {{
                    ...params.InputProps,
                    endAdornment: (
                    <>
                        {loading ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </>
                    )
                }}
            />
        )
        
    }
  
    return (
      <Autocomplete
        getOptionSelected = {getOptionSelected}
        getOptionLabel    = {getOptionLabel}
        loading           = {loading}
        open              = {open}
        onChange          = {onChange}
        onClose           = {onClose}
        onInputChange     = {onInputChange}
        onOpen            = {onOpen}
        options           = {options}
        renderInput       = {Input}
      />
    )
}

export default F3MAutocompleteAsync