import { Theme, makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'relative'
    },
    loading: {
        position       : 'absolute',
        display        : 'flex',
        top            : 0,
        left           : 0,
        right          : 0,
        bottom         : 0,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        alignContent   : 'center',
        alignItems     : 'center',
        justifyContent : 'center'
    }
}))

export default useStyles