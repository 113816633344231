import { isDev } from '../utils'

export const PROD = {
    API_URL: 'https://' + window.location.hostname + '/api'
}
export const DEV = {
    API_URL: 'https://localhost:44380/api'
}
export const CONFIG = isDev() ? DEV : PROD

export const ENDPOINTS = {
    TOKEN_CREATE                     : '/Token/Create',
    TOKEN_REFRESH                    : '/Token/Refresh',
    TOKEN_REVOKE                     : '/Token/Revoke',
    INCIDENT                         : '/Incident',
    INCIDENT_PDF                     : '/Incident/PDF',
    INCIDENT_CONTRACTS               : '/Incident/Contracts',
    INCIDENT_APPLICATION             : '/Incident/Applications',
    INCIDENT_EXCEL                   : '/Incident/Excel',
    REPORT                           : '/Ticket',
    REPORT_STATISTICS                : '/Ticket/Stats',
    REPORT_STATS_LAST_X_MONTHS       : '/Ticket/Stats/LastMonths',
    REPORT_YEARS                     : '/Ticket/Years',
    REPORT_EXCEL                     : '/Ticket/Excel',
    CONTRACT_SERVICE_CONTRACTS       : '/Contract/ServiceContracts',
    CONTRACT_ASSISTANCE_CONTRACTS    : '/Contract/AssistanceContracts',
    LICENSING_PRODUCTS               : '/License/Applications',
    LICENSING_WEB_PRODUCTS           : '/License/WebApplications',
    LICENSING_PRODUCT_LICENSE        : '/License/Application',
    LICENSING_PRODUCT_LICENSE_PDF    : '/License/Application/PDF',
    LICENSING_WEB_PRODUCT_LICENSE    : '/License/WebApplication',
    APPLICATION_DOWNLOAD             : '/ApplicationDownload',
    APPLICATION_DOWNLOAD_INSTALLATION: '/ApplicationDownload/Installation',
    APPLICATION_DOWNLOAD_UPGRADE     : '/ApplicationDownload/Upgrade',
    APPLICATION_DOWNLOAD_UPDATE      : '/ApplicationDownload/Update',
    APPLICATION_DOWNLOAD_DOWNLOAD    : '/ApplicationDownload/Download',
    APPLICATION_DOWNLOAD_GAINS       : '/ApplicationDownload/Gains',
    USER_DOWNLOAD                    : '/UserDownload',
    USER_DOWNLOAD_DOWNLOAD           : '/UserDownload/Download',
    ACCOUNT                          : '/Account',
    APPLICATIONS                     : '/Applications',
    APPLICATIONVERSIONS              : '/Application/Versions',
    CAROUSELLOGIN                    : '/Carousel',
    CAROUSELMANAGEMENT               : '/Carousel/Management',
    CAROUSELADDORUPDTSLIDE           : '/Carousel/Slide',
    SETSLIDESTATUS                   : '/Carousel/Management/SetSlideStatus',
    DELETESLIDE                      : '/Carousel/Management/Delete',
    SLIDEIMAGE                       : '/Carousel/Slide/Image',
    SERVCATALOG                      : '/ServicesCatalog',
    DOWNLOADCATEGORY                 : '/DownloadCategory',
    DOWNCATBUSINESSAREAS             : '/DownloadCategory/BusinessAreas',
    DOWNCATDOWNS                     : '/DownloadCategoryDownload',
    DOWNCATDOWNBUSAREA               : '/DownloadCategoryDownload/BusinessArea',
    DOWNCATBUSAREASCATEG             : '/DownloadCategoryDownload/BusinessAreas/Categories',
    BUSINESSAREAS                    : '/BusinessAreas',
    DOWNLOADMANAGER                  : '/DownloadManager',
    CLIENTS                          : '/Clients',
    REMOTEASSISTANCE                 : '/RemoteAssistance'
}
