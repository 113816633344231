import { AccountResponse } from '../../types/services/account'
import { TokenCreateResponse, TokenRefreshResponse } from '../../types/services/auth'
import {
    Carousel,
    CloseExpiredSnackbar,
    HandleChange,
    LoadCarouselError,
    LoadCarouselRequest,
    LoadCarouselSuccess,
    LoginError,
    LoginRequest,
    LoginSuccess,
    LogoutError,
    LogoutRequest,
    LogoutSuccess,
    RefreshTokenError,
    RefreshTokenRequest,
    RefreshTokenSuccess,
    SetUser,
    ClearData
} from '../../types/store/login'

const loginActions = {
    handleChange: (name: string, value: string): HandleChange => {
        return {type: 'LOGIN_HANDLE_CHANGE', payload: {name, value}}
    },

    loginRequest: (): LoginRequest => {
        return {type: 'LOGIN_REQUEST'}
    },

    loginSuccess: (response: TokenCreateResponse): LoginSuccess => {
        return {type: 'LOGIN_SUCCESS', payload: response}
    },

    loginError: (error: string): LoginError => {
        return {type: 'LOGIN_ERROR', payload: error}
    },

    logoutRequest: (): LogoutRequest => {
        return {type: 'LOGIN_LOGOUT_REQUEST'}
    },

    logoutSuccess: (): LogoutSuccess => {
        return {type: 'LOGIN_LOGOUT_SUCCESS'}
    },

    logoutError: (error: string): LogoutError => {
        return {type: 'LOGIN_LOGOUT_ERROR'}
    },

    refreshTokenRequest: (): RefreshTokenRequest => {
        return {type: 'LOGIN_REFRESH_TOKEN_REQUEST'}
    },

    refreshTokenSuccess: (response: TokenRefreshResponse): RefreshTokenSuccess => {
        return {type: 'LOGIN_REFRESH_TOKEN_SUCCESS', payload: response}
    },

    refreshTokenError: (error: string): RefreshTokenError => {
        return {type: 'LOGIN_REFRESH_TOKEN_ERROR', payload: error}
    },

    loadCarouselRequest: (): LoadCarouselRequest => {
        return {type: 'LOGIN_LOAD_CAROUSEL_REQUEST'}
    },

    loadCarouselSuccess: (response: Carousel[]): LoadCarouselSuccess => {
        return {type: 'LOGIN_LOAD_CAROUSEL_SUCCESS', payload: response}
    },

    loadCarouselError: (error: string): LoadCarouselError => {
        return {type: 'LOGIN_LOAD_CAROUSEL_ERROR', payload: error}
    },

    closeExpiredSnackbar: (): CloseExpiredSnackbar => {
        return {type: 'LOGIN_CLOSE_EXPIRED_SNACKBAR'}
    },

    setUser: (user: AccountResponse): SetUser => {
        return {type: 'LOGIN_SET_USER', payload: user}
    },

    clearData: (): ClearData => {
        return {type: 'LOGIN_CLEAR_DATA'}
    }
}

export default loginActions
