import React from 'react'
import clsx from 'clsx'
import moment from 'moment'

import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import { isDefined } from '../../utils'
import i18n from '../../i18n'

import { F3MDatePickerProps } from './types'

const F3MDatePicker = (props: F3MDatePickerProps) => {

    const hasError = !moment(props.value).isValid()

    let datePickerProps: KeyboardDatePickerProps = {
        // Default CLASSNAME
        className: clsx('keyboardDatePicker', props.className),

        // Default DISABLE TOOLBAR
        disableToolbar: true,

        // ERROR
        error: hasError,

        // Default FORMAT dd/MM/yyyy
        format: isDefined(props.format) ? props.format : 'dd/MM/yyyy',

        // Default FULL WIDTH
        fullWidth: true,

        // HELPER TEXT
        helperText: hasError ? i18n.t('error.date') : undefined,

        // LABEL
        label: props.label,

        // Default INPUT VARIANT OUTLINED
        inputVariant: isDefined(props.variant) ? props.variant : 'outlined' as any,

        // Default MARGIN DENSE
        margin: isDefined(props.margin) ? props.margin : 'dense',

        // NAME
        name: props.name,

        // VALUE
        value: props.value,

        // Default VARIANT DIALOG
        variant: isDefined(props.variant) ? props.variant : 'dialog',

        // ON CHANGE
        onChange: props.handleChange
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker {...datePickerProps}/>
        </MuiPickersUtilsProvider>
        
    )
}

export default F3MDatePicker