import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { push } from 'connected-react-router'
import clsx from 'clsx'

import { List, ListItem, ListItemIcon, ListItemText, Collapse, Link } from '@material-ui/core'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { SoftwareIcon, ServicesIcon, TrainingIcon, BackofficeIcon } from '../../../assets/icons'

import i18n from '../../../i18n'
import { ApplicationState } from '../../../types/store'
import commonOperations from '../../../store/operations/commonOperations'
import { ROUTES } from '../../../constants'

import useStyles from './styles'
import { openOrDownload } from '../../../utils'

const mapStateToProps = (state: ApplicationState) => ({
    commonState: state.common,
    routerState: state.router,
    loginState: state.login,
    servCatalogState: state.servCatalog
})

const mapDispatchToProps = (dispatch: any) => ({
    toggleSidebar: (e: any) => dispatch(commonOperations.toggleSidebar(e)),
    toggleSoftwareSidebar: () => dispatch(commonOperations.toggleSoftwareSidebar()),
    toggleServiceSidebar: () => dispatch(commonOperations.toggleServiceSidebar()),
    toggleBackofficeSidebar: () => dispatch(commonOperations.toggleBackofficeSidebar()),
    push: (p: string) => dispatch(push(p))
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const SidebarContent = (props: Props) => {
    const classes = useStyles()

    const { sidebarSoftwareOpen, sidebarServicesOpen, sidebarBackofficeOpen } = props.commonState!
    const { pathname } = props.routerState?.location!
    const { user } = props.loginState!
    const servCatalogBlob = props.servCatalogState?.servCatalogBlob
    const { SOFTWARE, SERVICES, TRAINING, BACKOFFICE } = ROUTES

    const isSoftwareSelected = (): boolean => [
        SOFTWARE.LICENSING.PATH,
        SOFTWARE.INSTALLATION.PATH,
        SOFTWARE.UPDATES.PATH
    ].includes(pathname)

    const isServicesSelected = (): boolean => [
        SERVICES.INCIDENTS.PATH,
        SERVICES.CONTRACTS.PATH,
        SERVICES.REPORTS.PATH,
        SERVICES.DOWNLOADS.PATH
    ].includes(pathname)

    const isBackofficeSelected = (): boolean => [
        BACKOFFICE.CAROUSEL.PATH,
        BACKOFFICE.APPLICATIONS.PATH,
        BACKOFFICE.DOWNLOADS.PATH
    ].includes(pathname)

    const redirect = (event: any, path: string): void => {
        props.push(path)
        props.toggleSidebar(event)
    }

    return (
        <List className={classes.listRoot}>
            <ListItem
                button
                disableGutters
                className={clsx(classes.listItemRoot, isSoftwareSelected() && classes.listItemSelected)}
                onClick={props.toggleSoftwareSidebar}
                selected={isSoftwareSelected()}
            >
                <ListItemIcon className={classes.listItemIcon} >
                    <SoftwareIcon className={classes.listItemIconIcon} viewBox='0 0 32 32' />
                </ListItemIcon>
                <ListItemText
                    className={classes.listItemText}
                    primary={i18n.t('menus.software.parent')}
                    primaryTypographyProps={{ variant: 'inherit' }} />
                <ChevronRightIcon
                    className={clsx(
                        classes.listItemExpandIcon,
                        sidebarSoftwareOpen ? classes.listItemExpandLessIcon : classes.listItemExpandMoreIcon
                    )}
                />
            </ListItem>
            <Collapse in={sidebarSoftwareOpen} timeout='auto'>
                <List component='div' disablePadding>
                    <ListItem
                        button
                        className={classes.collapseListItem}
                        selected={pathname === SOFTWARE.LICENSING.PATH}
                        onClick={e => redirect(e, SOFTWARE.LICENSING.PATH)}
                    >
                        <ListItemText primary={i18n.t('menus.software.licensing')} primaryTypographyProps={{ variant: 'inherit' }} />
                    </ListItem>
                    <ListItem
                        button
                        className={classes.collapseListItem}
                        selected={pathname === SOFTWARE.INSTALLATION.PATH}
                        onClick={e => redirect(e, SOFTWARE.INSTALLATION.PATH)}
                    >
                        <ListItemText primary={i18n.t('menus.software.installations')} primaryTypographyProps={{ variant: 'inherit' }} />
                    </ListItem>
                    <ListItem
                        button
                        className={classes.collapseListItem}
                        selected={pathname === SOFTWARE.UPDATES.PATH}
                        onClick={e => redirect(e, SOFTWARE.UPDATES.PATH)}
                    >
                        <ListItemText primary={i18n.t('menus.software.updates')} primaryTypographyProps={{ variant: 'inherit' }} />
                    </ListItem>
                </List>
            </Collapse>
            <ListItem
                button
                disableGutters
                className={clsx(classes.listItemRoot, isServicesSelected() && classes.listItemSelected)}
                onClick={props.toggleServiceSidebar}
                selected={isServicesSelected()}
            >
                <ListItemIcon className={classes.listItemIcon} >
                    <ServicesIcon className={classes.listItemIconIcon} viewBox='0 0 32 32' />
                </ListItemIcon>
                <ListItemText
                    className={classes.listItemText}
                    primary={i18n.t('menus.services.parent')}
                    primaryTypographyProps={{ variant: 'inherit' }}
                />
                <ChevronRightIcon
                    className={clsx(
                        classes.listItemExpandIcon,
                        sidebarServicesOpen ? classes.listItemExpandLessIcon : classes.listItemExpandMoreIcon
                    )}
                />
            </ListItem>
            <Collapse in={sidebarServicesOpen} timeout='auto'>
                <List component='div' disablePadding>
                    <ListItem
                        button
                        className={classes.collapseListItem}
                        selected={pathname === SERVICES.INCIDENTS.PATH}
                        onClick={e => redirect(e, SERVICES.INCIDENTS.PATH)}
                    >
                        <ListItemText primary={i18n.t('menus.services.incidents')} primaryTypographyProps={{ variant: 'inherit' }} />
                    </ListItem>
                    <ListItem
                        button
                        className={classes.collapseListItem}
                        selected={pathname === SERVICES.CONTRACTS.PATH}
                        onClick={e => redirect(e, SERVICES.CONTRACTS.PATH)}
                    >
                        <ListItemText primary={i18n.t('menus.services.contracts')} primaryTypographyProps={{ variant: 'inherit' }} />
                    </ListItem>
                    <ListItem
                        button
                        className={classes.collapseListItem}
                        onClick={() => openOrDownload(undefined, undefined, servCatalogBlob)}
                    >
                        <ListItemText primary={i18n.t('menus.services.catalog')} primaryTypographyProps={{ variant: 'inherit' }} />
                    </ListItem>
                    <ListItem
                        button
                        className={classes.collapseListItem}
                        selected={pathname === SERVICES.REPORTS.PATH}
                        onClick={e => redirect(e, SERVICES.REPORTS.PATH)}
                    >
                        <ListItemText primary={i18n.t('menus.services.report')} primaryTypographyProps={{ variant: 'inherit' }} />
                    </ListItem>
                    <ListItem
                        button
                        className={classes.collapseListItem}
                        selected={pathname === SERVICES.DOWNLOADS.PATH}
                        onClick={e => redirect(e, SERVICES.DOWNLOADS.PATH)}
                    >
                        <ListItemText primary={i18n.t('menus.services.downloads')} primaryTypographyProps={{ variant: 'inherit' }} />
                    </ListItem>
                </List>
            </Collapse>
            <ListItem
                button
                disableGutters
                className={classes.listItemRoot}
                component={Link}
                href={TRAINING.REDIRECT}
                target={TRAINING.TARGET}
            >
                <ListItemIcon className={classes.listItemIcon} >
                    <TrainingIcon className={classes.listItemIconIcon} viewBox='0 0 32 32' />
                </ListItemIcon>
                <ListItemText className={classes.listItemText} primary={i18n.t('menus.training.parent')} primaryTypographyProps={{ variant: 'inherit' }} />
            </ListItem>
            {user?.role === 'F3M' &&
                <>
                    <ListItem
                        button
                        disableGutters
                        className={clsx(classes.listItemRoot, isBackofficeSelected() && classes.listItemSelected)}
                        onClick={props.toggleBackofficeSidebar}
                        selected={isBackofficeSelected()}
                    >
                        <ListItemIcon className={classes.listItemIcon} >
                            <BackofficeIcon className={classes.listItemIconIcon} viewBox='0 0 32 32' />
                        </ListItemIcon>
                        <ListItemText
                            className={classes.listItemText}
                            primary={i18n.t('menus.backoffice.parent')}
                            primaryTypographyProps={{ variant: 'inherit' }}
                        />
                        <ChevronRightIcon
                            className={clsx(
                                classes.listItemExpandIcon,
                                sidebarBackofficeOpen ? classes.listItemExpandLessIcon : classes.listItemExpandMoreIcon
                            )}
                        />
                    </ListItem>
                    <Collapse in={sidebarBackofficeOpen} timeout='auto'>
                        <List component='div' disablePadding>
                            <ListItem
                                button
                                className={classes.collapseListItem}
                                selected={pathname === BACKOFFICE.CAROUSEL.PATH}
                                onClick={e => redirect(e, BACKOFFICE.CAROUSEL.PATH)}
                            >
                                <ListItemText primary={i18n.t('menus.backoffice.carousel')} primaryTypographyProps={{ variant: 'inherit' }} />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.collapseListItem}
                                selected={pathname === BACKOFFICE.APPLICATIONS.PATH}
                                onClick={e => redirect(e, BACKOFFICE.APPLICATIONS.PATH)}
                            >
                                <ListItemText primary={i18n.t('menus.backoffice.applications')} primaryTypographyProps={{ variant: 'inherit' }} />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.collapseListItem}
                                selected={pathname === BACKOFFICE.DOWNLOADS.PATH}
                                onClick={e => redirect(e, BACKOFFICE.DOWNLOADS.PATH)}
                            >
                                <ListItemText primary={i18n.t('menus.backoffice.downloads')} primaryTypographyProps={{ variant: 'inherit' }} />
                            </ListItem>
                        </List>
                    </Collapse>
                </>
            }
        </List>
    )
}

export default connector(SidebarContent)