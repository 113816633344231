import React, { useState } from "react";

import useStyles from "./styles";

import { ContentPropsDownCatDonwlsUpdtCreate } from "../../../F3MAddUpdt/types";
import { F3MDropzoneSection } from "../..";

import i18n from "../../../../i18n";
import { Button, Chip, TextField } from "@material-ui/core";
import {
  FileNameAndDesc,
  FilesStruct,
} from "../../../../types/services/downloadCategoryDownloads";
import inputLimit from "../../../../utils/inputLimit";
import { FILESIZE } from "../../../../constants";

const F3MUploadFiles = (
  props: ContentPropsDownCatDonwlsUpdtCreate & {
    fileNameAndDesc: FileNameAndDesc;
    handleError: (message: string) => void;
  }
) => {
  const classes = useStyles();

  const { setAddOrUpdt, fileNameAndDesc, handleError } = props;

  const isOriginalFileChange: boolean =
    fileNameAndDesc.fileName.length > 0 &&
    fileNameAndDesc.description.length > 0;
  const [originalFile, setOriginalFile] = useState<{
    name: string;
    description: string;
  }>({
    name: fileNameAndDesc.fileName,
    description: fileNameAndDesc.description,
  });

  const [fileInAddition, setFileInAddition] = useState<File | undefined>(
    undefined
  );

  const [files, setFiles] = useState<FilesStruct>({});

  const [dropAreaReset, setDropAreaReset] = useState<number>(0);
  const [desc, setDesc] = useState<string>("");

  const handleFileChange = (files: File[]) => {
    if (files.length > 0) {
      setFileInAddition(files[0]);
    } else {
      setFileInAddition(undefined);
    }
  };

  const handleDescChange = (value: string) => {
    const descriptionLimit = inputLimit.common.description;
    if (value.length > descriptionLimit) {
      handleError(
        i18n.t("common.maxCharSecField", {
          field: i18n.t("downloadCategoryDownload.description"),
          section: i18n.t("downloadCategoryDownload.fileUpload"),
          maxChar: descriptionLimit,
        })
      );
    } else {
      setDesc(value);
    }
  };

  const getFileAddedMessage = (fileName: string) => {
    setAddOrUpdt((prevState) => ({ ...prevState, isNewFile: true }));
    return i18n.t("dropzone.fileAdded").replace("FILE_NAME", fileName);
  };

  const isFileNameAlreadySubmitted = (fileToAddName: string) => {
    return (
      (files !== null &&
        Object.values(files).some(
          (elem) => elem.file.name === fileToAddName
        )) ||
      originalFile.name === fileToAddName
    );
  };

  const addNewFile = () => {
    if (fileInAddition !== undefined && desc.length > 0) {
      if (isFileNameAlreadySubmitted(fileInAddition.name)) {
        handleError(i18n.t("downloadCategoryDownload.sameNameAddedFile"));
        return;
      }

      if (isOriginalFileChange) {
        setAddOrUpdt((prevState) => ({
          ...prevState,
          files: { 0: { file: fileInAddition, description: desc } },
        }));
        setOriginalFile({ name: fileInAddition.name, description: desc });
      } else {
        const index: number = Object.keys(files).length;
        let newFileToAdd: FilesStruct = {};
        newFileToAdd[index] = {
          file: fileInAddition,
          description: desc,
        };
        setFiles((prevState) => ({ ...prevState, ...newFileToAdd }));
        setAddOrUpdt((prevState) => ({
          ...prevState,
          files: { ...prevState.files, ...newFileToAdd },
        }));
      }
      setDropAreaReset((prevState) => prevState + 1);
      setDesc("");
    } else {
      handleError(i18n.t("downloadCategoryDownload.mandatoryFileAndDesc"));
    }
  };

  const onDelete = (id?: number) => {
    if (isOriginalFileChange) {
      setAddOrUpdt((prevState) => ({ ...prevState, files: null }));
      setOriginalFile({ name: "", description: "" });
    } else {
      let newFiles: FilesStruct = JSON.parse(JSON.stringify(files));
      if (id !== undefined) {
        delete newFiles[id];
        setAddOrUpdt((prevState) => ({ ...prevState, files: newFiles }));
        setFiles(newFiles);
      }
    }
  };

  return (
    <div className={classes.root}>
      <F3MDropzoneSection
        key={dropAreaReset}
        handleFileChange={handleFileChange}
        getFileAddedMessage={getFileAddedMessage}
        showPreviews={true}
        showPreviewsInDropzone={false}
        useChipsForPreview
        previewGridProps={{ container: { spacing: 1, direction: "row" } }}
        maxFileSize={FILESIZE["2GB"]}
        filesLimit={1}
      />
      <TextField
        id="add-updt-description"
        label={i18n.t("downloadCategoryDownload.description")}
        value={desc}
        onChange={(e) => handleDescChange(e.target.value)}
        required={true}
        style={{ margin: 8 }}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button onClick={addNewFile}>
        {i18n.t("downloadCategoryDownload.addFile")}
      </Button>
      {!isOriginalFileChange && (
        <>
          <h3>{i18n.t("downloadCategoryDownload.files")}</h3>
          {Object.keys(files).map((elem) => (
            <Chip
              key={elem}
              label={files[+elem].file.name + "-" + files[+elem].description}
              onDelete={() => onDelete(+elem)}
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px", marginBottom: "10px" }}
            />
          ))}
        </>
      )}
      {isOriginalFileChange && (
        <>
          <h3>{i18n.t("downloadCategoryDownload.file")}</h3>
          {originalFile.name.length > 0 &&
            originalFile.description.length > 0 && (
              <Chip
                key={0}
                label={originalFile.name + "-" + originalFile.description}
                onDelete={() => onDelete()}
                variant="outlined"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px" }}
              />
            )}
        </>
      )}
    </div>
  );
};

export default F3MUploadFiles;
