import { CONFIG, ENDPOINTS } from '../../constants'
import { TicketsLastXMonthsResponse, TicketsRequest, TicketsResponse, TicketsStatisticsResponse } from '../../types/services/tickets'
import { download, request } from '../axios'

export const ticketsService = {
    loadTickets: async (params: TicketsRequest) => {
        const response = await request<TicketsResponse>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.REPORT,
            params: params
        })

        return response
    },

    loadNumberTicketsByMonthLastXMonths: async (numberOfMonths: number) => {
        const response = await request<TicketsLastXMonthsResponse>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.REPORT_STATS_LAST_X_MONTHS,
            params: { numberOfMonths: numberOfMonths }
        })

        return response
    },

    loadTicketsStatistics: async (year?: number) => {
        const response = await request<TicketsStatisticsResponse>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.REPORT_STATISTICS,
            params: { year: year }
        })

        return response
    },

    loadYears: async () => {
        const response = await request<number[]>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.REPORT_YEARS
        })

        return response
    },

    getExcel: async (params: TicketsRequest) => {
        const response = await download({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.REPORT_EXCEL,
            params: params
        })

        return response
    }
}