import {
    CONFIG,
    ENDPOINTS
} from '../../constants'
import {
    BusinessArea
} from '../../types/services/backoffice'
import {
    BusinessAreasCategoriesResponse,
    DownCatDownBusAreaResponse,
    DownloadCategoryDownloadsFilter,
    DownloadCategoryDownloadsResponse,
    DownloadCategoryDownloadsUpdtCreate,
    FileStruct
} from '../../types/services/downloadCategoryDownloads'
import {
    download,
    request
} from '../axios'

export const downloadCategoryDownloadsService = {
    loadDownloadCategoryDownloads: async (filter: DownloadCategoryDownloadsFilter) => {
        const response = await request<DownloadCategoryDownloadsResponse>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.DOWNCATDOWNS,
            params: filter
        })

        return response
    },

    loadDownCatDownBusArea: async (downloadType: number[]) => {
        const response = await request<DownCatDownBusAreaResponse>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.DOWNCATDOWNBUSAREA,
            params: {
                downloadType: downloadType
            }
        })

        return response
    },

    getBusinessAreasCategories: async (businessAreas: BusinessArea[]) => {
        const response = await request<BusinessAreasCategoriesResponse>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.DOWNCATBUSAREASCATEG,
            params: {
                businessAreas: businessAreas.map(busArea => busArea.id)
            }
        })

        return response
    },

    createDownloadCategoryDownload: async (downloadCategory: DownloadCategoryDownloadsUpdtCreate) => {
        const formData = new FormData()

        formData.append('categoryId', downloadCategory.categoryId ? downloadCategory.categoryId.toString() : '')
        formData.append('isActive', downloadCategory.isActive.toString())

        if (downloadCategory.files) {
            Object.values(downloadCategory.files).forEach((file: FileStruct) => {
                formData.append(file.description, file.file)
            })
        }

        return await request<DownloadCategoryDownloadsResponse>({
            method: 'POST',
            url: CONFIG.API_URL + ENDPOINTS.DOWNCATDOWNS,
            data: formData
        }).catch(error => {
            throw error
        })
    },

    updateDownloadCategoryDownload: async (downloadCategory: DownloadCategoryDownloadsUpdtCreate) => {
        const formData = new FormData()

        formData.append('id', downloadCategory.id ? downloadCategory.id.toString() : '')
        formData.append('categoryId', downloadCategory.categoryId ? downloadCategory.categoryId.toString() : '')
        formData.append('description', downloadCategory.files ? downloadCategory.files[0].description : '')
        formData.append('isActive', downloadCategory.isActive.toString())

        if (downloadCategory.isNewFile === true) {
            formData.append('file', downloadCategory.files ? downloadCategory.files[0].file : '')
        }

        const response = await request<DownloadCategoryDownloadsResponse>({
            method: 'PUT',
            url: CONFIG.API_URL + ENDPOINTS.DOWNCATDOWNS,
            data: formData
        })

        return response
    },

    deleteDownloadCategoryDownload: async (id: number) => {
        const response = await request<DownloadCategoryDownloadsResponse>({
            method: 'DELETE',
            url: CONFIG.API_URL + ENDPOINTS.DOWNCATDOWNS + '/' + id
        })

        return response
    },

    downloadCatDownload: async (downCatDownId: number) => {
        const response = await download({
            method : 'GET',
            url    : CONFIG.API_URL + ENDPOINTS.DOWNCATDOWNS + '/' + downCatDownId
        })

        return response
    }
}