const InputFields = {
    "common": {
        "title": 50,
        "description": 150
    },
    "carousel": {
        "buttonText": 15
    }
}

export default InputFields