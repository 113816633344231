import { TextField } from '@material-ui/core'
import React, { useState } from 'react'
import i18n from '../../../../i18n'
import inputLimit from '../../../../utils/inputLimit'

import { ContentPropsDownManUpdtCreate } from '../../../F3MAddUpdt/types'

import useStyles from './styles'

const F3MDownManDescp = (props: ContentPropsDownManUpdtCreate & { handleError: (message: string) => void }) => {
    const classes = useStyles()

    const {
        addOrUpdt,
        setAddOrUpdt,
        handleError
    } = props

    const [description, setDescription] = useState<string>(addOrUpdt.description ? addOrUpdt.description : '')

    const handleChangeDescrp = (value: string) => {
        const descriptionLimit = inputLimit.common.description
        if (value.length > descriptionLimit) {
            handleError(i18n.t('common.maxCharField', { field: i18n.t('downloadCategoryDownload.description'), maxChar: descriptionLimit }))
        }
        else {
            setDescription(value)
            setAddOrUpdt(prevState => ({ ...prevState, description: value }))
        }
    }

    return (
        <TextField
            id='add-updt-description'
            className={classes.description}
            value={description}
            onChange={(e) => handleChangeDescrp(e.target.value)}
            style={{ margin: 8 }}
            fullWidth
            margin='normal'
            InputLabelProps={{
                shrink: true,
            }}
        />
    )
}

export default F3MDownManDescp