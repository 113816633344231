import React from 'react'

import { useTheme } from '@material-ui/core'

import { useWindowSize } from '../../hooks'

import DesktopSidebar from './Desktop'
import MobileSidebar from './Mobile'

const F3MSidebar = () => {
    const theme = useTheme()
    const size = useWindowSize()
    
    return (
        size.width! > theme.breakpoints.values.lg ? <DesktopSidebar /> : <MobileSidebar/>
    )
}

export default F3MSidebar