import React from 'react'

import { Grid } from '@material-ui/core'
import { F3MFilterSearch, F3MSelect } from '..'

import i18n from '../../i18n'
import { DownCatBusinessAreasResponse } from '../../types/services/downloadCategories'
import { buildBusAreasItemsToSelect } from '../../utils'


interface FiltersBacDownUpgrProps {
    activeBusAreasFilter: string
    handleSelectorFilterChange: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => void
    businessAreasFilter: DownCatBusinessAreasResponse
    handleTextFilterChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    placeholderSearch: string
}

const F3MFiltersBacDownUpgr = (props: FiltersBacDownUpgrProps) => {
    const {
        activeBusAreasFilter,
        handleSelectorFilterChange,
        businessAreasFilter,
        handleTextFilterChange,
        placeholderSearch
    } = props

    return (
        <Grid container justify='space-between' alignItems='center' spacing={2}>
            <Grid item xs={12} sm={6} md={2}>
                <F3MSelect
                    labelId='businessAreasSelector'
                    label={i18n.t('downloadCategoryDownload.businessArea')}
                    name='businessAreas'
                    value={activeBusAreasFilter}
                    onChange={handleSelectorFilterChange}
                    items={businessAreasFilter.businessAreas.length
                        ? buildBusAreasItemsToSelect(businessAreasFilter.businessAreas)
                        : undefined
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6} md={10}>
                <F3MFilterSearch handleTextFilterChange={handleTextFilterChange} placeholder={placeholderSearch} />
            </Grid>
        </Grid>
    )
}

export default F3MFiltersBacDownUpgr