import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: 20,
        verticalAlign: 'middle'
    },
    uploadArea: {
        paddingTop: 20,
    },
    required: {
        color: 'red'
    }
}))

export default useStyles