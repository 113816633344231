import moment from 'moment'
import i18n from '../../i18n'

import { ReportActions, ReportState } from '../../types/store/report'
import { isDefined } from '../../utils'

const initialState: ReportState = {
    statistics: {
        loading: false,
        year   : undefined,
        years  : []
    },
    reports: {
        data        : [],
        loading     : false,
        rowCount    : 0,
        isFilterOpen: false,
        filter      : {
            startDate    : moment().format('YYYY') + '-01-01',
            endDate      : moment().format('YYYY-MM-DD'),
            rowsPerPage  : 5,
            page         : 1,
            sortDirection: 'desc',
            sortField    : 'requestDate'
        }
    }
}

const reportReducer = (state = initialState, action: ReportActions): ReportState => {
    switch (action.type) {
        case ('REPORT_HANDLE_YEAR_CHANGE'): {
            return { 
                ...state,
                statistics: {
                    ...state.statistics,
                    year: action.payload
                }
            }
        }

        case ('REPORT_HANDLE_FILTER_CHANGE'): {
            const {name, value} = action.payload

            return { 
                ...state,
                reports: {
                    ...state.reports,
                    filter: {
                         ...state.reports.filter,
                        [name]: value
                    }
                }
            }
        }

        case ('REPORT_HANDLE_PAGE_CHANGE'): {
            return { 
                ...state,
                reports: {
                    ...state.reports,
                    filter: {
                         ...state.reports.filter,
                         page: action.payload + 1
                    }
                }
            }
        }

        case ('REPORT_HANDLE_ROWS_PER_PAGE_CHANGE'): {
            return {
                ...state,
                reports: {
                    ...state.reports,
                    filter: {
                         ...state.reports.filter,
                         rowsPerPage: action.payload,
                        page: 1
                    }
                }
            }
        }

        case ('REPORT_LOAD_STATISTICS_REQUEST'): {
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    loading: true
                }
            }
        }

        case ('REPORT_LOAD_STATISTICS_SUCCESS'): {
            const {number, slaPercentage, notSLAPercentage, monthWithMoreTickets, monthWithMoreCompliance, monthWithLessCompliance, tickets, ticketsSLA, ticketsNotSLA} = action.payload
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    data: {
                        number                 : number,
                        slaPercentage          : isDefined(slaPercentage) ? slaPercentage : '-',
                        notSLAPercentage       : isDefined(notSLAPercentage) ? notSLAPercentage : '-',
                        monthWithMoreTickets   : isDefined(monthWithMoreTickets) ? i18n.t('months.' + monthWithMoreTickets + '.label') : '-',
                        monthWithMoreCompliance: isDefined(monthWithMoreCompliance) ? i18n.t('months.' + monthWithMoreCompliance + '.label') : '-',
                        monthWithLessCompliance: isDefined(monthWithLessCompliance) ? i18n.t('months.' + monthWithLessCompliance + '.label') : '-',
                        tickets                : tickets,
                        ticketsSLA             : ticketsSLA,
                        ticketsNotSLA          : ticketsNotSLA
                    },
                    loading: false
                }
            }
        }

        case ('REPORT_LOAD_STATISTICS_ERROR'): {
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    loading: false
                }
            }
        }

        case ('REPORT_LOAD_TICKETS_REQUEST'): {
            return {
                ...state,
                reports: {
                    ...state.reports,
                    loading: true
                }
            }
        }

        case ('REPORT_LOAD_TICKETS_SUCCESS'): {
            return {
                ...state,
                reports: {
                    ...state.reports,
                    data: action.payload.tickets,
                    loading: false,
                    rowCount: action.payload.rowCount
                }
            }
        }

        case ('REPORT_LOAD_TICKETS_ERROR'): {
            return {
                ...state,
                reports: {
                    ...state.reports,
                    loading: true
                }
            }
        }

        case ('REPORT_CLEAR_DATA'): {
            return initialState
        }

        case ('REPORT_LOAD_YEARS_REQUEST'): {
            return state
        }

        case ('REPORT_LOAD_YEARS_SUCCESS'): {
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    year:  action.payload[0] ? action.payload[0] : undefined,
                    years: action.payload
                }
            }
        }

        case ('REPORT_LOAD_YEARS_ERROR'): {
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    years: [moment().year()]
                }
            }
        }

        case ('REPORT_TOGGLE_FILTER'): {
            return {
                ...state,
                reports: {
                    ...state.reports,
                    isFilterOpen: !state.reports.isFilterOpen
                }
            }
        }

        case ('REPORT_ORDER'): {
            return {
                ...state,
                reports: {
                    ...state.reports,
                    filter: {
                        ...state.reports.filter,
                        page: 1,
                        sortDirection: action.payload.sortDirection,
                        sortField: action.payload.sortField
                    }
                }
            }
        }

        default:
            return state
    }
}

export default reportReducer