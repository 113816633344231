import {
    ToggleSidebar,
    ToggleSoftwareSidebar,
    ToggleServiceSidebar,
    ToggleLogoutConfirmation,
    ToggleBackofficeSidebar
} from '../../types/store/common'

const commonActions = {
    toggleSidebar: (): ToggleSidebar => {
        return {type: 'COMMON_TOGGLE_SIDEBAR'}
    },

    toggleSoftwareSidebar: (): ToggleSoftwareSidebar => {
        return {type: 'COMMON_TOGGLE_SOFTWARE_SIDEBAR'}
    },

    toggleServiceSidebar: (): ToggleServiceSidebar => {
        return {type: 'COMMON_TOGGLE_SERVICE_SIDEBAR'}
    },

    toggleBackofficeSidebar: (): ToggleBackofficeSidebar => {
        return {type: 'COMMON_TOGGLE_BACKOFFICE_SIDEBAR'}
    },

    toggleLogoutConfirmation: (): ToggleLogoutConfirmation => {
        return {type: 'COMMON_TOGGLE_LOGOUT_CONFIRMATION'}
    }
}

export default commonActions