import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import moment from 'moment'
import clsx from 'clsx'

import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'

import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import { F3MBreadcrumbs, F3MCard, F3MDataGrid, F3MDataGridColumnProps, F3MDataGridRowData, F3MEmptyState } from '../../../../components'

import i18n from '../../../../i18n'
import { isDefined } from '../../../../utils'

import { AssistanceContractsResponse } from '../../../../types/services/contracts'

import { ApplicationState } from '../../../../types/store'
import { contractOperations } from '../../../../store/operations'

import useStyles from './styles'

const mapStateToProps = (state: ApplicationState) => ({
    contractsState: state.contracts
})

const mapDispatchToProps = (dispatch: any) => ({
    loadServiceContractsData: () => dispatch(contractOperations.loadServiceContractsData()),
    loadAssistanceContractsData: () => dispatch(contractOperations.loadAssistanceContractsData()),
    clearData: () => dispatch(contractOperations.clearData())
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const Contracts = (props: Props) => {
    const { updateContracts, assistanceContracts } = props.contractsState!

    useEffect(() => {
        // On Mount
        props.loadServiceContractsData()
        props.loadAssistanceContractsData()

        // On Unmount
        return () => {
            props.clearData()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updatePlansColumns: F3MDataGridColumnProps[] = [
        { field: 'plan', headerName: i18n.t('contracts.plan'), width: 40, sortable: false, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'active', headerName: i18n.t('common.active'), width: 20, sortable: false, type: 'node', align: 'center', headerAlign: 'center' },
        { field: 'startDate', headerName: i18n.t('common.startDate'), width: 20, sortable: false, type: 'date', align: 'right', headerAlign: 'right' },
        { field: 'endDate', headerName: i18n.t('common.endDate'), width: 20, sortable: false, type: 'date', align: 'right', headerAlign: 'right' }
    ]

    const updatePlansRows: F3MDataGridRowData[] = isDefined(updateContracts.data) ? updateContracts.data.map((r, i) => (
        {
            id: i.toString(),
            plan: r.description,
            active: r.active ? <CheckBoxIcon color='disabled' /> : <CheckBoxOutlineBlankIcon color='disabled' />,
            startDate: r.startDate,
            endDate: r.endDate
        }
    )) : []

    const Row = (props: AssistanceContractsResponse) => {
        const [open, setOpen] = React.useState(false)
        const classes = useStyles()

        return (
            <>
                <TableRow className={classes.tableRow}>
                    <TableCell style={{ padding: '9px 15px' }}>
                        <IconButton size='small' onClick={() => setOpen(!open)} disabled={props.contractExtensions.length === 0}>
                            <KeyboardArrowDownIcon className={clsx(classes.tableRowIcon, !open ? classes.tableRowIconOpen : classes.tableRowIconClose)} />
                        </IconButton>
                    </TableCell>
                    <TableCell align='left'>{props.plan}</TableCell>
                    <TableCell align='right'>{moment(props.startDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align='right'>{moment(props.endDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell align='right'>{props.totalCredited}</TableCell>
                    <TableCell align='right'>{props.totalDebited}</TableCell>
                    <TableCell align='right'>{props.total}</TableCell>
                </TableRow>
                {open &&
                    <TableRow className={classes.collapsedTableRow}>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                            <Collapse in={open} timeout='auto'>
                                <Box margin={1}>
                                    <Table size='small' className={classes.collapsedTable}>
                                        <TableHead className={classes.collapsedTableHead}>
                                            <TableRow className={classes.collapsedTableRowHead}>
                                                <TableCell className={classes.collapsedTableCellHead} id='occurrence' align='left'>{i18n.t('contracts.occurrence')}</TableCell>
                                                <TableCell className={classes.collapsedTableCellHead} id='startDate' align='right'>{i18n.t('common.startDate')}</TableCell>
                                                <TableCell className={classes.collapsedTableCellHead} id='endDate' align='right'>{i18n.t('common.endDate')}</TableCell>
                                                <TableCell className={classes.collapsedTableCellHead} id='credit' align='right'>{i18n.t('contracts.credit') + ' (' + i18n.t('time.hours.short').toUpperCase() + ')'}</TableCell>
                                                <TableCell className={classes.collapsedTableCellHead} id='debit' align='right'>{i18n.t('contracts.debit') + ' (' + i18n.t('time.hours.short').toUpperCase() + ')'}</TableCell>
                                                <TableCell className={classes.collapsedTableCellHead} id='total' align='right'>{i18n.t('common.total') + ' (' + i18n.t('time.hours.short').toUpperCase() + ')'}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.contractExtensions.map((ce, i) => (
                                                <TableRow key={i} className={classes.collapsedTableRowBody}>
                                                    <TableCell align='left'>{ce.description}</TableCell>
                                                    <TableCell align='right'>{isDefined(ce.startDate) ? moment(ce.startDate).format('DD/MM/YYYY') : '-'}</TableCell>
                                                    <TableCell align='right'>{isDefined(ce.endDate) ? moment(ce.endDate).format('DD/MM/YYYY') : '-'}</TableCell>
                                                    <TableCell align='right'>{ce.credit || '-'}</TableCell>
                                                    <TableCell align='right'>{ce.debit || '-'}</TableCell>
                                                    <TableCell align='right'>{ce.total || '-'}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow className={classes.collapsedTableRowBody}>
                                                <TableCell align='left' style={{ fontWeight: 500 }}>{i18n.t('common.total')}</TableCell>
                                                <TableCell align='right' style={{ fontWeight: 500 }}>{moment(props.startDate).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell align='right' style={{ fontWeight: 500 }}>{moment(props.endDate).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell align='right' style={{ fontWeight: 500 }}>{props.totalCredited || '-'}</TableCell>
                                                <TableCell align='right' style={{ fontWeight: 500 }}>{props.totalDebited || '-'}</TableCell>
                                                <TableCell align='right' style={{ fontWeight: 500 }}>{props.total || '-'}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                }
            </>
        )
    }

    return (
        <>
            <F3MBreadcrumbs path={i18n.t('paths.contracts.breadcrumb')} />

            <F3MCard loading={updateContracts.loading}>
                <Typography variant='h5' color='primary' gutterBottom>{i18n.t('contracts.updatePlans')}</Typography>
                <F3MDataGrid columns={updatePlansColumns} rows={updatePlansRows} pagination={false} />
            </F3MCard>

            <F3MCard loading={assistanceContracts.loading}>
                <Typography variant='h5' color='primary' gutterBottom>{i18n.t('contracts.assistancePlans')}</Typography>
                <TableContainer>
                    <Table>
                        <colgroup>
                            <col style={{ width: '5%' }} />
                            <col style={{ width: '45%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '10%' }} />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ padding: '13px 15px' }}></TableCell>
                                <TableCell id='plan' align='left' style={{ padding: '13px 15px' }}>{i18n.t('contracts.plan')}</TableCell>
                                <TableCell id='startDate' align='right' style={{ padding: '13px 15px' }}>{i18n.t('common.startDate')}</TableCell>
                                <TableCell id='endDate' align='right' style={{ padding: '13px 15px' }}>{i18n.t('common.endDate')}</TableCell>
                                <TableCell id='credit' align='right' style={{ padding: '13px 15px' }}>{i18n.t('contracts.credit') + ' (' + i18n.t('time.hours.short').toUpperCase() + ')'}</TableCell>
                                <TableCell id='debit' align='right' style={{ padding: '13px 15px' }}>{i18n.t('contracts.debit') + ' (' + i18n.t('time.hours.short').toUpperCase() + ')'}</TableCell>
                                <TableCell id='total' align='right' style={{ padding: '13px 15px' }}>{i18n.t('common.total') + ' (' + i18n.t('time.hours.short').toUpperCase() + ')'}</TableCell>
                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {
                                isDefined(assistanceContracts.data) && assistanceContracts.data.length > 0
                                    ?
                                    assistanceContracts.data.map((ac, i) => (
                                        <Row key={i} {...ac} />
                                    ))
                                    :
                                    assistanceContracts.loading
                                        ?
                                        <TableRow>
                                            <TableCell
                                                colSpan={7}
                                                height={250}
                                            >
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <TableRow>
                                            <TableCell
                                                colSpan={7}
                                                height={250}
                                            >
                                                <F3MEmptyState />
                                            </TableCell>
                                        </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </F3MCard>
        </>
    )
}

export default connector(Contracts)