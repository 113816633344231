import React from 'react'

import { Link } from 'react-router-dom'
import useStyles from './styles'

const LoginAppBar = () => {
    const classes = useStyles()
    
    return (
        <Link to='/'>
            <img className={classes.logo} src='/images/logos/logo-f3m.svg' alt='Logo' />
        </Link>
    )
}

export default LoginAppBar