import React from 'react'
import { SvgIcon } from '@material-ui/core'

export const TrainingIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M29.4,27.6c0.4-0.5,0.6-1,0.6-1.6v-1c0-1.4-1.2-2.6-2.6-2.6c-1.4,0-2.6,1.2-2.6,2.6v1c0,0.6,0.2,1.1,0.6,1.6
                    c-0.6,0.1-1.2,0.5-1.6,1c-0.4-0.5-1-0.9-1.6-1c0.4-0.5,0.6-1,0.6-1.6v-1c0-1.4-1.2-2.6-2.6-2.6c-1.4,0-2.6,1.2-2.6,2.6v1
                    c0,0.6,0.2,1.1,0.6,1.6c-0.6,0.1-1.2,0.5-1.6,1c-0.4-0.5-1-0.9-1.6-1c0.4-0.5,0.6-1,0.6-1.6v-1c0-1.4-1.2-2.6-2.6-2.6
                    c-1.4,0-2.6,1.2-2.6,2.6v1c0,0.6,0.2,1.1,0.6,1.6c-1.2,0.2-2.1,1.3-2.1,2.5v1.6h1v-1.6c0-0.9,0.7-1.6,1.6-1.6h3.1
                    c0.9,0,1.6,0.7,1.6,1.6v1.6h1v-1.6c0-0.9,0.7-1.6,1.6-1.6h3.1c0.9,0,1.6,0.7,1.6,1.6v1.6h1v-1.6c0-0.9,0.7-1.6,1.6-1.6H29
                    c0.9,0,1.6,0.7,1.6,1.6v1.6h1v-1.6C31.6,28.9,30.7,27.8,29.4,27.6z M14.4,26c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6v-1
                    c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6V26z M21.7,26c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6v-1
                    c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6V26z M29,26c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6v-1c0-0.9,0.7-1.6,1.6-1.6
                    c0.9,0,1.6,0.7,1.6,1.6V26z"/>
                <path d="M7.6,25.3v-9.6L7.8,16c0.5,1,1.8,1.5,2.9,0.9c0.5-0.2,0.8-0.6,1-1.1l0.8-2.1l0.8-2.1c0.1-0.3,0-0.6-0.3-0.7
                    c-0.1,0-0.1,0-0.2,0h-2.1c-0.2,0-0.4,0.1-0.5,0.3l-0.6,1.5l0,0c-0.4-1.1-1.4-1.8-2.6-1.8h0c0.3-0.4,0.5-1,0.5-1.6v-1
                    c0-1.4-1.2-2.6-2.6-2.6c-1.4,0-2.6,1.2-2.6,2.6v1c0,0.6,0.2,1.1,0.5,1.6h0c-1.4,0-2.6,1.2-2.6,2.6v6.2c0,0.3,0.2,0.5,0.5,0.5h1.6v5
                    l-1,1.4c-0.2,0.2-0.1,0.6,0.1,0.7c0.1,0.1,0.2,0.1,0.3,0.1h6.2c0.3,0,0.5-0.2,0.5-0.5c0-0.1,0-0.2-0.1-0.3L7.6,25.3z M11.1,12h0.9
                    l-0.4,1h-0.9L11.1,12z M3.5,8.4c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6v1c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6V8.4z
                    M2.4,19.2h-1v-1h1V19.2z M5.5,26.5v-6.2h-1v6.2H2.9l0.5-0.7c0.1-0.1,0.1-0.2,0.1-0.3v-6.2h3.1v6.2c0,0.1,0,0.2,0.1,0.3l0.5,0.7
                    H5.5z M6.9,13.1c-0.2,0.1-0.3,0.3-0.3,0.5v4.7H3.5v-4.7c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v3.6h-1v-3.6
                    c0-0.9,0.7-1.6,1.6-1.6h1.6v5.2h1V12h1.6c0.7,0,1.4,0.4,1.6,1.1l0.5,1.4c0.1,0.3,0.4,0.4,0.7,0.3c0.1-0.1,0.3-0.2,0.3-0.3l0.2-0.4
                    h0.9l-0.6,1.4C10.5,16,9.8,16.3,9.3,16c-0.2-0.1-0.4-0.3-0.6-0.5l-1.1-2.2C7.5,13,7.1,12.9,6.9,13.1z"/>
                <path d="M9.2,3.7h0.5v6.2h1V3.7h18.7v11.4H12.8v1h6.8v2.3l-3.4,1.9l0.5,0.9l2.9-1.6v1.7h1v-1.7l2.9,1.6l0.5-0.9
                    l-3.4-1.9v-2.3h10.9v-1h-1V3.7h0.5c0.3,0,0.5-0.2,0.5-0.5V1.1c0-0.3-0.2-0.5-0.5-0.5H9.2c-0.3,0-0.5,0.2-0.5,0.5v2.1
                    C8.7,3.5,8.9,3.7,9.2,3.7z M9.7,1.6h20.8v1H9.7V1.6z"/>
                <path d="M18.4,7.9c-0.2-0.1-0.4-0.2-0.6,0l-3.6,2.6l0.6,0.8L18,9l2.3,1.8c0.2,0.1,0.4,0.2,0.6,0l4.4-3.1v1.1h1V6.8
                    c0-0.2-0.2-0.4-0.4-0.5l-2.1-0.5l-0.3,1l1,0.2l-4,2.8L18.4,7.9z"/>
            </g>
        </SvgIcon>
    )
}