import { Button, ButtonGroup } from "@material-ui/core";
import React, { useState } from "react";
import { F3MDropzoneSection } from "../..";
import i18n from "../../../../i18n";
import { connect, ConnectedProps } from "react-redux";

import { ContentPropsAppDownUpdtCreate } from "../../../F3MAddUpdt/types";

import useStyles from "./styles";
import { getFileVersion } from "../../../../utils";
import { appDownloadOperations } from "../../../../store/operations";
import { FILESIZE } from "../../../../constants";

const mapDispatchToProps = (dispatch: any) => ({
  setVersion: (version: string) =>
    dispatch(appDownloadOperations.setVersion(version)),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const F3MAppDownFilesInd = (
  props: ContentPropsAppDownUpdtCreate &
    Props & {
      required: boolean;
      fileName: string[];
      gainsName: string[];
      useOriginalVersion: boolean;
    }
) => {
  const classes = useStyles();

  const {
    setAddOrUpdt,
    setVersion,
    required,
    fileName,
    gainsName,
    useOriginalVersion,
  } = props;

  const [selected, setSelected] = useState<number>(0);

  const [fileNameToShow, setFileNameToShow] =
    useState<(File | string)[]>(fileName);
  const [gainsNameToShow, setGainsNameToShow] =
    useState<(File | string)[]>(gainsName);

  const [originalVersion, setOriginalVersion] =
    useState<boolean>(useOriginalVersion);

  const getStyle = (btnSelected: number) => {
    return btnSelected === selected
      ? { background: "#00adef", color: "white" }
      : { background: "none", color: "#00adef" };
  };

  const handleFileChangeFile = (files: File[]) => {
    if (files.length > 0) {
      if (originalVersion) {
        setOriginalVersion(false);
      } else {
        setVersion(getFileVersion(files[0].name));
      }
      setFileNameToShow(files.length > 0 ? files : []);
    } else {
      setVersion("");
    }
    setAddOrUpdt((prevState) => ({
      ...prevState,
      file: files.length > 0 ? files[0] : undefined,
    }));
  };

  const handleGainsChangeFile = (files: File[]) => {
    setGainsNameToShow(files.length > 0 ? files : []);
    setAddOrUpdt((prevState) => ({
      ...prevState,
      gains: files.length > 0 ? files[0] : undefined,
    }));
  };

  const getFileAddedMessage = (file: string) => {
    setAddOrUpdt((prevState) => ({ ...prevState, isNewFile: true }));
    return i18n.t("dropzone.fileAdded").replace("FILE_NAME", file);
  };

  const getGainsAddedMessage = (file: string) => {
    setAddOrUpdt((prevState) => ({ ...prevState, isNewGains: true }));
    return i18n.t("dropzone.fileAdded").replace("FILE_NAME", file);
  };

  return (
    <div className={classes.root}>
      <ButtonGroup color="primary" aria-label="contained primary button group">
        <Button
          onClick={() => {
            setSelected(0);
          }}
          style={getStyle(0)}
        >
          {i18n.t("applicationDownload.file")}
        </Button>
        <Button
          onClick={() => {
            setSelected(1);
          }}
          style={getStyle(1)}
        >
          {i18n.t("applicationDownload.gains")}
        </Button>
      </ButtonGroup>
      {required === true && <span className={classes.required}>&ensp;*</span>}
      <div className={classes.uploadArea}>
        {selected === 0 && (
          <F3MDropzoneSection
            handleFileChange={handleFileChangeFile}
            getFileAddedMessage={getFileAddedMessage}
            initialFiles={fileNameToShow}
            showPreviews={true}
            showPreviewsInDropzone={false}
            useChipsForPreview
            previewGridProps={{ container: { spacing: 1, direction: "row" } }}
            filesLimit={1}
            maxFileSize={FILESIZE["2GB"]}
          />
        )}
        {selected === 1 && (
          <F3MDropzoneSection
            handleFileChange={handleGainsChangeFile}
            getFileAddedMessage={getGainsAddedMessage}
            initialFiles={gainsNameToShow}
            showPreviews={true}
            showPreviewsInDropzone={false}
            useChipsForPreview
            previewGridProps={{ container: { spacing: 1, direction: "row" } }}
            filesLimit={1}
            acceptedFiles={[".pdf", "application/pdf"]}
            maxFileSize={FILESIZE["350MB"]}
            detailsMessage={i18n.t("dropzone.pdf")}
          />
        )}
      </div>
    </div>
  );
};

export default connector(F3MAppDownFilesInd);
