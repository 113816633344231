import React, { useEffect, useState } from 'react'

import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core'

import { isDefined } from '../../utils'

import { F3MDataGridProps, F3MDataGridRowData } from './types'

import { alignCell, formatField, getClassName, getComparator, getWidth, stableSort } from './functions'
import { F3MEmptyState } from '..'

const F3MDataGrid = (props: F3MDataGridProps) => {
    let tableProps: F3MDataGridProps = {
        // COLUMNS
        columns: props.columns.map((c, i) => ({
                // FIELD
                field: c.field,

                // HEADER NAME
                headerName: c.headerName,

                // WIDTH
                width: getWidth(c.width),

                // SORTABLE => FALSE
                sortable: isDefined(c.sortable) ? c.sortable: false,

                // TYPE
                type: isDefined(c.type) ? c.type : 'string',

                // ALIGN
                align: alignCell(c.align, c.type),

                // CELL CLASS NAME
                cellClassName: getClassName(c.cellClassName),

                // HEADER CLASS NAME
                headerClassName: getClassName(c.headerClassName),

                // HEADER ALIGN
                headerAlign: alignCell(c.headerAlign, c.type),

                // HIDE SORT ICONS => FALSE
                hideSortIcons: isDefined(c.hideSortIcons) ? c.hideSortIcons: false
            })),
        
        // ROWS
        rows: props.rows,

        // ROW CLICK
        rowClick: props.rowClick,

        // EXTRA ROWS
        extraRows: props.extraRows,

        // HOVER => FALSE
        hover: isDefined(props.hover) ? props.hover : false,

        // LOADING => FALSE
        loading: isDefined(props.loading) ? props.loading : false,

        // SORT MODE => SERVER
        sortMode: isDefined(props.sortMode) ? props.sortMode : 'server',

        // SORT FIELD
        sortField: props.sortField,

        // SORT DIRECTION
        sortDirection: props.sortDirection,

        // SORT CHANGE
        sortChange: props.sortChange,

        // PAGINATION => FALSE
        pagination: isDefined(props.pagination) ? props.pagination : false,

        // PAGINATION MODE => SERVER
        paginationMode: isDefined(props.paginationMode) ? props.paginationMode : 'server',

        // ROW COUNT => ROWS LENGTH
        rowCount: isDefined(props.rowCount) ? props.rowCount : props.rows.length,

        // PAGE SIZE => FIRST OPTION OF ROWS PER PAGE ARRAY => 10
        pageSize: isDefined(props.pageSize) ? props.pageSize : isDefined(props.rowsPerPageOptions) ? props.rowsPerPageOptions![0] : props.pagination ? 10 : undefined,

        // PAGE => 0
        page: isDefined(props.page) ? (props.page! - 1) : 0,

        // ON CHANGE PAGE
        onChangePage: props.onChangePage,

        // ON CHANGE ROWS PER PAGE
        onChangeRowsPerPage: props.onChangeRowsPerPage,

        // ROWS PER PAGE OPTIONS
        rowsPerPageOptions: isDefined(props.rowsPerPageOptions) ? props.rowsPerPageOptions : [10, 25, 50, 100]
    }


    const {columns, rows, rowClick, extraRows, hover, loading} = tableProps
    const {sortMode, sortField, sortDirection, sortChange} = tableProps
    const {pagination, paginationMode, rowCount, pageSize, page, onChangePage, onChangeRowsPerPage, rowsPerPageOptions} = tableProps


    const [ps, setPageSize]      = useState<number>(pageSize!)
    const [p, setPage]           = useState<number>(page!)
    const [sf, setSortField]     = useState<string | undefined>(sortField)
    const [sd, setSortDirection] = useState<'asc' | 'desc' | undefined>(sortDirection)
    const [rowData, setRowData]  = useState<F3MDataGridRowData[]>([])

    const GetRowsData = () => {
        let result = rows

        if(isDefined(sf) && isDefined(sd)) {
            if(sortMode === 'client') {
                result = stableSort(result, getComparator(sd!, sf!))
            }
        }

        if(pagination) {
            if(paginationMode === 'client') {
                result = result.slice(p * ps, p * ps + ps)
            }
        }

        return result
    }

    useEffect(() => {
        setRowData(GetRowsData())

        return () => {
            setRowData([])
        }
    }, [rows, sd, sf, p, ps]) // eslint-disable-line react-hooks/exhaustive-deps

    const sort = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        const id = e.currentTarget.parentElement!.id

        if(sf === id) {
            if(sd === 'asc') {
                setSortDirection('desc')
            } else {
                setSortDirection('asc')
            }
        } else {
            setSortDirection('asc')
            setSortField(id)
        }

        if(sortMode === 'server') {
            sortChange!(e)
        }

        setPage(0)
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        if(paginationMode === 'server') {
            onChangePage!(event, page)
        }

        setPage(page)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(paginationMode === 'server') {
            onChangeRowsPerPage!(event)
        }
        
        setPageSize(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <colgroup>
                        {columns.map((c, i) => (<col key={i} style={{width: c.width as string}} />))}
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            {columns.map((c, i) => (
                                <TableCell
                                    key           = {c.field}
                                    id            = {c.field}
                                    className     = {c.headerClassName as string}
                                    align         = {c.headerAlign}
                                    sortDirection = {c.sortable && c.field === sf ? sd! : false}
                                    style         = {{padding: '13px 15px'}}
                                >
                                    {
                                        c.sortable
                                        ?
                                        <TableSortLabel
                                            active       = {sf === c.field}
                                            direction    = {sd}
                                            hideSortIcon = {c.hideSortIcons}
                                            onClick      = {sort}
                                        >
                                            {c.headerName}
                                        </TableSortLabel>
                                        : 
                                        c.headerName
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowData.map((r: { [x: string]: any; id: string }, i: any) => (
                            <TableRow
                                key     = {r.id}
                                id      = {r.id.toString()}
                                onClick = {rowClick}
                                hover   = {hover}
                            >
                                {columns.map((c, i) => (
                                    <TableCell
                                        key       = {c.field}
                                        id        = {c.field + '-' + r.id}
                                        align     = {c.align}
                                        className = {c.cellClassName as string}
                                        style     = {{
                                            height: '50px',
                                            padding: c.type === 'node' ? '9px 15px' : '10px',
                                            lineHeight:  c.type === 'node' ? 0 : 1.2}}
                                    >
                                        {formatField(r[c.field], c.type)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        {
                            rowData.length > 0
                            ?
                                extraRows
                            :
                                loading
                                ?
                                    <TableRow>
                                        <TableCell
                                            colSpan = {columns.length}
                                            height  = {isDefined(ps) ? ps * 50 : 250}
                                        >
                                        </TableCell>
                                    </TableRow>
                                :
                                    <TableRow>
                                        <TableCell
                                            colSpan = {columns.length}
                                            height  = {isDefined(ps) ? ps * 50 : 250}
                                        >
                                            <F3MEmptyState />
                                        </TableCell>
                                    </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {pagination === true && rowData.length > 0 && 
            <TablePagination
                component           = 'div'
                count               = {rowCount!}
                rowsPerPage         = {ps!}
                page                = {p!}
                onPageChange        = {handleChangePage}
                onRowsPerPageChange = {handleChangeRowsPerPage}
                rowsPerPageOptions  = {rowsPerPageOptions!}
            />}
        </>
    )
}

export default F3MDataGrid