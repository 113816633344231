import { Button, ButtonGroup, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { F3MDropzoneSection } from "../..";
import { FILESIZE } from "../../../../constants";
import i18n from "../../../../i18n";
import { appDownloadOperations } from "../../../../store/operations";
import { FilesStruct } from "../../../../types/services/applicationDownload";
import { ApplicationState } from "../../../../types/store";
import { getFileVersion } from "../../../../utils";

import { ContentPropsAppDownUpdtCreate } from "../../../F3MAddUpdt/types";

import useStyles from "./styles";

const mapStateToProps = (state: ApplicationState) => ({
  appDownload: state.appDownload,
});

const mapDispatchToProps = (dispatch: any) => ({
  setVersion: (version: string) =>
    dispatch(appDownloadOperations.setVersion(version)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const F3MAppDownFilesMulti = (
  props: ContentPropsAppDownUpdtCreate & Props & { required: boolean }
) => {
  const classes = useStyles();

  const { setAddOrUpdt, setVersion, required } = props;

  const version = props.appDownload?.version;

  const [selected, setSelected] = useState<number>(0);
  const [addedFiles, setAddedFiles] = useState<FilesStruct | undefined>(
    undefined
  );
  const [gainsFile, setGainsFile] = useState<File | undefined>(undefined);

  const initInstFile: File[] = addedFiles ? [addedFiles["installation"]] : [];
  const initUpdtFile: File[] = addedFiles ? [addedFiles["update"]] : [];
  const initUpgrFile: File[] = addedFiles ? [addedFiles["upgrade"]] : [];
  const initGainsFile: File[] = gainsFile ? [gainsFile] : [];

  const getStyle = (btnSelected: number) => {
    return btnSelected === selected
      ? { background: "#00adef", color: "white" }
      : { background: "none", color: "#00adef" };
  };

  const handleFilesChange = (type: number, files: any) => {
    let key = "";
    switch (type) {
      case 0:
        key = "installation";
        break;
      case 1:
        key = "update";
        break;
      case 2:
        key = "upgrade";
        break;
    }
    let newFiles: FilesStruct | undefined = {};

    if (addedFiles !== undefined) {
      newFiles = addedFiles;

      if (files.length === 0) {
        delete newFiles[key];
      }
    }

    if (files.length > 0) {
      if (!version) {
        setVersion(getFileVersion(files[0].name));
      }
      newFiles[key] = files[0];
    }

    if (Object.keys(newFiles).length === 0) {
      newFiles = undefined;
      setVersion("");
    }

    setAddedFiles(newFiles);
    setAddOrUpdt((prevState) => ({ ...prevState, files: newFiles }));
  };

  const handleGainsChangeFile = (files: File[]) => {
    setGainsFile(files.length > 0 ? files[0] : undefined);
    setAddOrUpdt((prevState) => ({
      ...prevState,
      gains: files.length > 0 ? files[0] : undefined,
    }));
  };

  const getFileAddedMessage = (file: string) => {
    setAddOrUpdt((prevState) => ({ ...prevState, isNewFile: true }));
    return i18n.t("dropzone.fileAdded").replace("FILE_NAME", file);
  };

  const getGainsAddedMessage = (file: string) => {
    setAddOrUpdt((prevState) => ({ ...prevState, isNewGains: true }));
    return i18n.t("dropzone.fileAdded").replace("FILE_NAME", file);
  };

  return (
    <div className={classes.root}>
      <ButtonGroup color="primary" aria-label="contained primary button group">
        <Button
          onClick={() => {
            setSelected(0);
          }}
          style={getStyle(0)}
        >
          {i18n.t("applicationDownload.file")}
        </Button>
        <Button
          onClick={() => {
            setSelected(1);
          }}
          style={getStyle(1)}
        >
          {i18n.t("applicationDownload.gains")}
        </Button>
      </ButtonGroup>
      {required === true && <span className={classes.required}>&ensp;*</span>}
      <div className={classes.uploadArea}>
        {selected === 0 && (
          <Grid
            container
            justify="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12} sm={6} md={4}>
              <F3MDropzoneSection
                handleFileChange={(files: any) => handleFilesChange(0, files)}
                initialFiles={initInstFile}
                getFileAddedMessage={getFileAddedMessage}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: { spacing: 1, direction: "row" },
                }}
                filesLimit={1}
                maxFileSize={FILESIZE["2GB"]}
                centerInformation={i18n.t(
                  "applicationDownload.uploadInstallation"
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <F3MDropzoneSection
                handleFileChange={(files: any) => handleFilesChange(1, files)}
                initialFiles={initUpdtFile}
                getFileAddedMessage={getFileAddedMessage}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: { spacing: 1, direction: "row" },
                }}
                filesLimit={1}
                maxFileSize={FILESIZE["2GB"]}
                centerInformation={i18n.t("applicationDownload.uploadUpdate")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <F3MDropzoneSection
                handleFileChange={(files: any) => handleFilesChange(2, files)}
                initialFiles={initUpgrFile}
                getFileAddedMessage={getFileAddedMessage}
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: { spacing: 1, direction: "row" },
                }}
                filesLimit={1}
                maxFileSize={FILESIZE["2GB"]}
                centerInformation={i18n.t("applicationDownload.uploadUpgrade")}
              />
            </Grid>
          </Grid>
        )}
        {selected === 1 && (
          <F3MDropzoneSection
            handleFileChange={handleGainsChangeFile}
            getFileAddedMessage={getGainsAddedMessage}
            initialFiles={initGainsFile}
            showPreviews={true}
            showPreviewsInDropzone={false}
            useChipsForPreview
            previewGridProps={{ container: { spacing: 1, direction: "row" } }}
            filesLimit={1}
            acceptedFiles={[".pdf", "application/pdf"]}
            maxFileSize={FILESIZE["350MB"]}
            detailsMessage={i18n.t("dropzone.pdf")}
          />
        )}
      </div>
    </div>
  );
};

export default connector(F3MAppDownFilesMulti);
