import { AxiosResponse } from "axios"

declare global {
    interface Window {
        ApplePaySetupFeature: any,
        safari: any
    }
}

const agentHas = (keyword: string) => {
    return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
}

const isIOsMobile = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

export const openOrDownload = (response?: AxiosResponse<any>, filename?: string, blob?: Blob) => {

    const isSafari = (!!window.ApplePaySetupFeature || !!window.safari) && agentHas("Safari") && !agentHas("Chrome") && !agentHas("CriOS")
    
    if(response !== undefined){
        blob = new Blob([response.data], { type: response.headers['content-type'] })
    }

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
    }
    else if (((response !== undefined && response.headers['content-type'].toString().includes('pdf')) && !isSafari && !isIOsMobile()) || (response === undefined)) {
        window.open(URL.createObjectURL(blob))
    }
    else {
        let a = document.createElement('a')

        a.href = window.URL.createObjectURL(blob)

        a.download = filename!

        document.body.appendChild(a)

        a.click()

        document.body.removeChild(a)
    }
}