import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import clsx from 'clsx'

import { Box, Button, Grid, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@material-ui/core'

import FilterListIcon from '@material-ui/icons/FilterList'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { F3MBreadcrumbs, F3MDatePicker, F3MSelect, F3MDataGrid, F3MDataGridColumnProps, F3MDataGridRowData, F3MCard, F3MChart } from '../../../../components'

import i18n from '../../../../i18n'

import { ApplicationState } from '../../../../types/store'
import { reportOperations } from '../../../../store/operations'

import theme from '../../../../assets/theme'
import { isDefined } from '../../../../utils'
import { useWindowSize } from '../../../../hooks'

import useStyles from './styles'

import { ticketsService } from '../../../../api/services/ticketsService'

import { tableAsExcel } from '../../../../utils/tableAsExcel'

const mapStateToProps = (state: ApplicationState) => ({
    reportsState: state.reports
})

const mapDispatchToProps = (dispatch: any) => ({
    handleYearChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => dispatch(reportOperations.handleYearChange(e)),
    handleFilterChange: (n: string, v: string) => dispatch(reportOperations.handleFilterChange(n, v)),
    handleChangePage: (e: any, p: number) => dispatch(reportOperations.handleChangePage(e, p)),
    handleChangeRowsPerPage: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => dispatch(reportOperations.handleChangeRowsPerPage(e)),
    loadStatistics: () => dispatch(reportOperations.loadStatistics()),
    loadTickets: () => dispatch(reportOperations.loadTickets()),
    clearData: () => dispatch(reportOperations.clearData()),
    loadYears: () => dispatch(reportOperations.loadYears()),
    toggleFilter: () => dispatch(reportOperations.toggleFilter()),
    order: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => dispatch(reportOperations.order(e))
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const Reports = (props: Props) => {
    const classes = useStyles()
    const { statistics, reports } = props.reportsState!
    const size = useWindowSize()
    const { sm, md } = theme.breakpoints.values

    const [menu, setMenu] = React.useState<null | HTMLElement>(null)
    const [initDateFilter] = useState<string>(reports.filter.startDate)
    const [endDateFilter,] = useState<string>(reports.filter.endDate)

    const menuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenu(event.currentTarget)
    }

    const menuClose = () => {
        setMenu(null)
    }

    useEffect(() => {
        // On Mount
        props.loadYears()
        props.loadTickets()

        // On Unmount
        return () => {
            props.clearData()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // On Mount
        props.loadStatistics()
    }, [statistics.year]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleYearChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        props.handleYearChange(e)
        props.loadStatistics()
    }

    const handleFilterChange = (name: string) => (value: any) => {
        props.handleFilterChange(name, value)
        props.loadTickets()
    }

    const clearFilter = () => {
        props.handleFilterChange('startDate', initDateFilter)
        props.handleFilterChange('endDate', endDateFilter)
        props.loadTickets()
    }

    const order = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        props.order(e)
        props.loadTickets()
    }

    const handleChangePage = (e: any, p: number) => {
        props.handleChangePage(e, p)
        props.loadTickets()
    }

    const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.handleChangeRowsPerPage(e)
        props.loadTickets()
    }

    // TODO: Export table as excel
    const exportTableAsExcel = async () => {
        menuClose()

        const response = await ticketsService.getExcel({ ...reports.filter, rowsPerPage: null })

        tableAsExcel.downloadExcel(response, i18n.t('fileNames.excel.tickets'))
    }

    // TODO: Export table as PDF
    // const exportTableAsPDF = () => {
    //     menuClose()
    // console.log(reports.data)
    // }

    const renderValue = () => {
        return statistics.year
    }

    const dataset = {
        labels: [
            i18n.t('months.january.label'),
            i18n.t('months.february.label'),
            i18n.t('months.march.label'),
            i18n.t('months.april.label'),
            i18n.t('months.may.label'),
            i18n.t('months.june.label'),
            i18n.t('months.july.label'),
            i18n.t('months.august.label'),
            i18n.t('months.september.label'),
            i18n.t('months.october.label'),
            i18n.t('months.november.label'),
            i18n.t('months.december.label')
        ],
        datasets: [
            {
                label: i18n.t('reports.numberOfTickets'),
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                borderWidth: 1,
                hoverBackgroundColor: theme.palette.primary.light,
                hoverBorderColor: theme.palette.primary.light,
                data: statistics.data?.tickets
            },
            {
                label: i18n.t('reports.meetSLA'),
                backgroundColor: theme.palette.success.main,
                borderColor: theme.palette.success.main,
                borderWidth: 1,
                hoverBackgroundColor: theme.palette.success.light,
                hoverBorderColor: theme.palette.success.light,
                data: statistics.data?.ticketsSLA
            },
            {
                label: i18n.t('reports.doNotMeetSLA'),
                backgroundColor: theme.palette.warning.main,
                borderColor: theme.palette.warning.main,
                borderWidth: 1,
                hoverBackgroundColor: theme.palette.warning.light,
                hoverBorderColor: theme.palette.warning.light,
                data: statistics.data?.ticketsNotSLA
            }
        ]
    }

    const columns: F3MDataGridColumnProps[] = [
        { field: 'ticket', headerName: i18n.t('reports.ticket'), width: 15, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'requestDate', headerName: i18n.t('common.date'), width: 15, sortable: true, type: 'datetime', align: 'right', headerAlign: 'right' },
        { field: 'predictedDate', headerName: i18n.t('reports.expectedDate'), width: 15, sortable: true, type: 'datetime', align: 'right', headerAlign: 'right' },
        { field: 'realDate', headerName: i18n.t('reports.realDate'), width: 15, sortable: true, type: 'datetime', align: 'right', headerAlign: 'right' },
        { field: 'slaCompliance', headerName: i18n.t('reports.slaCompliance'), width: 15, sortable: false, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'incident', headerName: i18n.t('reports.incidentRequest'), width: 20, sortable: true, type: 'string', align: 'left', headerAlign: 'left' }
    ]

    const rows: F3MDataGridRowData[] = isDefined(reports.data) ? reports.data.map((r, i) => (
        {
            id: r.id + '-' + i,
            ticket: r.ticket,
            requestDate: r.requestDate,
            predictedDate: r.predictedDate,
            realDate: r.realDate,
            slaCompliance: r.slaComplicance ? i18n.t('common.yes') : i18n.t('common.no'),
            incident: r.incident
        }
    )) : []

    return (
        <>
            <F3MBreadcrumbs path={i18n.t("paths.reports.breadcrumb")} />
            <F3MCard loading={statistics.loading}>
                <Grid container alignItems='center'>
                    <Grid item xs={8} sm={10} lg={11}>
                        <Typography variant='h5' color='primary'>{i18n.t('reports.ticketsStatistics')}</Typography>
                    </Grid>
                    <Grid item xs={4} sm={2} lg={1}>
                        <F3MSelect
                            labelId='yearLabel'
                            label={i18n.t('time.years.labelSingular')}
                            name='year'
                            value={statistics.years}
                            renderValue={() => renderValue()}
                            onChange={handleYearChange}
                            items={isDefined(statistics.years) ? statistics.years.map((year) => ({ id: year, label: year })) : []}
                            displayAll={false}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.tableCellHead}>{i18n.t('reports.numberOfTickets')}</TableCell>
                                        <TableCell align='right'>{statistics.data?.number}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellHead}>{i18n.t('reports.answeredOnTime')}</TableCell>
                                        <TableCell align='right'>{statistics.data?.slaPercentage}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellHead}>{i18n.t('reports.answeredAfterTime')}</TableCell>
                                        <TableCell align='right'>{statistics.data?.notSLAPercentage}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.tableCellHead}>{i18n.t('reports.monthWithMostTickets')}</TableCell>
                                        <TableCell align='right'>{statistics.data?.monthWithMoreTickets}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellHead}>{i18n.t('reports.monthWithMostCompliance')}</TableCell>
                                        <TableCell align='right'>{statistics.data?.monthWithMoreCompliance}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCellHead}>{i18n.t('reports.monthWithLessCompliance')}</TableCell>
                                        <TableCell align='right'>{statistics.data?.monthWithLessCompliance}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <F3MChart dataset={dataset} />
            </F3MCard>
            <F3MCard loading={reports.loading}>
                <Box marginBottom={reports.isFilterOpen ? 0 : 4}>
                    <Grid container alignItems='center'>
                        <Grid item xs={8} sm={10}>
                            <Typography variant='h5' color='primary'>{i18n.t('reports.ticketsList')}</Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={4}
                            sm={2}
                            direction='row'
                            justify='flex-end'
                        >
                            <Tooltip title={i18n.t('filter.filters')!} arrow>
                                <IconButton onClick={props.toggleFilter}>
                                    <FilterListIcon className={classes.icons} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={i18n.t('common.more')!} arrow>
                                <IconButton onClick={menuOpen}>
                                    <MoreVertIcon className={classes.icons} />
                                </IconButton>
                            </Tooltip>

                            <Menu
                                keepMounted
                                anchorEl={menu}
                                open={Boolean(menu)}
                                onClose={menuClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            >
                                <MenuItem onClick={exportTableAsExcel}>{i18n.t('common.exportExcel')}</MenuItem>
                                {/* <MenuItem onClick={exportTableAsPDF}>{i18n.t('common.exportPDF')}</MenuItem> */}
                            </Menu>
                        </Grid>
                    </Grid>
                </Box>

                <Box paddingRight={size.width! < md ? 0 : 5}>
                    <Grid
                        container
                        className={clsx(classes.filter, !reports.isFilterOpen ? classes.filterHidden : '')}
                        spacing={size.width! < sm ? 2 : 5}
                        justify='flex-end'
                    >
                        <Grid item xs={6} sm={3} md={2}>
                            <F3MDatePicker
                                label={i18n.t('filter.startDate')}
                                name='startDate'
                                value={reports.filter.startDate!}
                                handleChange={handleFilterChange('startDate')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} md={2}>
                            <F3MDatePicker
                                label={i18n.t('filter.endDate')}
                                name='endDate'
                                value={reports.filter.endDate!}
                                handleChange={handleFilterChange('endDate')}
                            />
                        </Grid>
                        <Grid className={classes.buttonSeeMore} item xs={12} sm={2} md={1}>
                            <Button className={classes.textButtonSeeMore} onClick={clearFilter}>
                                {i18n.t('common.clearFilters')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <F3MDataGrid
                    columns={columns}
                    rows={rows}
                    loading={reports.loading}
                    sortField={reports.filter.sortField}
                    sortDirection={reports.filter.sortDirection}
                    sortChange={order}
                    pagination={true}
                    paginationMode='server'
                    rowCount={reports.rowCount}
                    pageSize={reports.filter.rowsPerPage ? reports.filter.rowsPerPage : 0}
                    page={reports.filter.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                />
            </F3MCard>
        </>
    )
}

export default connector(Reports)