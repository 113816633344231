import React from 'react'
import { Switch, Route, useLocation } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

import { ROUTES } from '../constants'

import NotFound from '../views/home/other/404'

import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Container } from '@material-ui/core'

const Routes = () => {
    const { HOMEPAGE, SOFTWARE, SERVICES, BACKOFFICE } = ROUTES

    const location = useLocation()

    return (
        <BrowserRouter>
            <div className='app'>
                <Route render={() => {
                    return (
                        <TransitionGroup component={undefined}>
                            <CSSTransition
                                key={location.key}
                                classNames='fade'
                                appear={true}
                                timeout={{ enter: 750, exit: 0 }}
                            >
                                <Container style={{ maxWidth: '1600px' }}>
                                    <Switch location={location}>
                                        {/* Homepage */}
                                        <Route exact path={HOMEPAGE.PATH} component={HOMEPAGE.COMPONENT} />

                                        {/* Software */}
                                        <Route path={SOFTWARE.LICENSING.PATH} component={SOFTWARE.LICENSING.COMPONENT} />
                                        <Route path={SOFTWARE.INSTALLATION.PATH} component={SOFTWARE.INSTALLATION.COMPONENT} />
                                        <Route path={SOFTWARE.UPDATES.PATH} component={SOFTWARE.UPDATES.COMPONENT} />

                                        {/* Services */}
                                        <Route path={SERVICES.INCIDENTS.PATH} component={SERVICES.INCIDENTS.COMPONENT} />
                                        <Route path={SERVICES.CONTRACTS.PATH} component={SERVICES.CONTRACTS.COMPONENT} />
                                        <Route path={SERVICES.REPORTS.PATH} component={SERVICES.REPORTS.COMPONENT} />
                                        <Route path={SERVICES.DOWNLOADS.PATH} component={SERVICES.DOWNLOADS.COMPONENT} />

                                        {/* BACKOFFICE */}
                                        <Route path={BACKOFFICE.CAROUSEL.PATH} component={BACKOFFICE.CAROUSEL.COMPONENT} />
                                        <Route path={BACKOFFICE.APPLICATIONS.PATH} component={BACKOFFICE.APPLICATIONS.COMPONENT} />
                                        <Route path={BACKOFFICE.DOWNLOADS.PATH} component={BACKOFFICE.DOWNLOADS.COMPONENT} />

                                        {/* Not Found 404 */}
                                        <Route component={NotFound} />
                                    </Switch>
                                </Container>
                            </CSSTransition>
                        </TransitionGroup>
                    )
                }
                } />
            </div>
        </BrowserRouter>

    )
}

export default Routes