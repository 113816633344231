import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    titlePage: {
        fontFamily: 'Roboto',
        fontSize: '15px',
        color: '#989898',
        textDecoration: 'none solid rgb(152, 152, 152)',
        borderBottom: '#dadada 1px solid'
    },
    buttons: {
        width: '100%'
    },
    quickAccess: {
        marginTop: '32px',
        height: '350px'
    },
    quickAccessBtns: {
        backgroundColor: 'white',
        width: '100%',
        height: '106px',
        color: theme.palette.warning.main,
        borderRadius: '4px',
        boxShadow: '0px 3px 6px rgb(0 0 0 / 16%)',
        '&:hover': {
            color: 'white',
            backgroundColor: theme.palette.warning.main
        }
    },
    icon: {
        marginRight: '1vmax'
    },
    buttonSeeMore: {
        position: 'absolute',
        bottom: '15px',
        color: theme.palette.warning.main
    },
    cardTitleOrange: {
        color: theme.palette.warning.main
    },
    plansHeaders: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        color: '#666666',
        textDecoration: 'none solid rgb(102, 102, 102)'
    },
    plansContent: {
        borderTop: '1px solid #dadada',
        width: '100%'
    },
    plansContentName: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        color: '#666666',
        textDecoration: 'none solid rgb(102, 102, 102)'
    },
    plansContentEnds: {
        padding: '0 !important',
        borderRadius: '4px',
        backgroundSize: 'cover',
        fontWeight: 500,
        fontFamily: 'Roboto',
        height: '25px',
        fontSize: '12px',
        textDecoration: 'none solid rgb(255, 255, 255)',
        textAlign: 'center'
    }
}))

export default useStyles