import React from 'react'

import { Grid, IconButton, Container } from '@material-ui/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LINKS } from '../../constants'

import useStyles from './styles'

const F3MIcons = (props: any) => {
    const classes = useStyles(props)
    
    return (
        <Container className={classes.paper} disableGutters maxWidth={false}>
            <Grid container spacing={0} alignContent='center' alignItems='center' justify='center'>
                <Grid item>
                    <IconButton className={classes.icon} href={LINKS.FACEBOOK} target='_blank'>
                        <FontAwesomeIcon icon={['fab', 'facebook-f']} size='sm' />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton className={classes.icon} href={LINKS.LINKEDIN} target='_blank'>
                        <FontAwesomeIcon icon={['fab', 'linkedin-in']} size='sm' />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton className={classes.icon} href={LINKS.TWITTER} target='_blank'>
                        <FontAwesomeIcon icon={['fab', 'twitter']} size='sm' />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton className={classes.icon} href={LINKS.YOUTUBE} target='_blank'>
                        <FontAwesomeIcon icon={['fab', 'youtube']} size='sm' />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton className={classes.icon} href={LINKS.INSTAGRAM} target='_blank'>
                        <FontAwesomeIcon icon={['fab', 'instagram']} size='sm' />
                    </IconButton>
                </Grid>
            </Grid>
        </Container>
    )
}

export default F3MIcons