import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    main: {
        position: 'relative'
    },
    background: {
        fontSize: '25vmax',
        color: '#e6e6e6',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    content: {
        textAlign: 'center',
        minHeight: '23vmax',
        position: 'relative'
    },
    title: {
        paddingTop: '10%',
        fontFamily: 'Roboto',
        fontSize: '18px',
        color: '#f7931d',
        textDecoration: 'none solid rgb(247, 147, 29)'
    },
    innerContent: {
        marginTop: '15%'
    },
    firstLine: {
        fontFamily: 'Roboto',
        fontSize: '32px',
        color: '#666666',
        textDecoration: 'none solid rgb(102, 102, 102)'
    },
    seccondLine: {
        paddingTop: '5%',
        paddingBottom: '10%',
        fontFamily: 'Roboto',
        fontSize: '20px',
        color: '#666666',
        textDecoration: 'none solid rgb(102, 102, 102)'
    }
}))

export default useStyles