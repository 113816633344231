import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles(() => createStyles({
    hover: {
        '&:hover': {
            color: '#00adef'
         }
    }
}))

export default useStyles