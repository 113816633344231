import React, { Dispatch, SetStateAction } from 'react'

import { Chip, IconButton, Switch } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import { F3MDataGrid, F3MDataGridColumnProps, F3MDataGridRowData } from '../../../../../../components'
import { DownloadCategoryDownloadsFields, SortDirection } from '../../../../../../types'

import i18n from '../../../../../../i18n'

import { DownloadCategoryDownloadsFilter, DownloadCategoryDownloadsResponse } from '../../../../../../types/services/downloadCategoryDownloads'

import globalSoftwareStyles from '../../../../styles'

interface ContentProps {
    downloadCategoryDownloads: DownloadCategoryDownloadsResponse
    handleChangeStatus: (id: number, isActive: boolean) => Promise<void>
    handleDeleteBtn: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, id: number) => void
    filter: DownloadCategoryDownloadsFilter
    setFilter: Dispatch<SetStateAction<DownloadCategoryDownloadsFilter>>
    loading: boolean
    handleAddUpdtModal: (type: string, event: React.MouseEvent<HTMLTableRowElement, MouseEvent> | null) => void
}

const Table = (props: ContentProps) => {
    const globalClasses = globalSoftwareStyles()

    const {
        downloadCategoryDownloads,
        handleChangeStatus,
        handleDeleteBtn,
        filter,
        setFilter,
        loading,
        handleAddUpdtModal
    } = props

    const handleSortChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        const id = e.currentTarget.parentElement!.id

        const { sortDirection, sortField } = filter
        let newSortDirection = 'desc'
        let newField = 'category'

        if (sortField === id) {
            newField = sortField
            newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
        } else {
            newSortDirection = 'asc'
            newField = id
        }

        setFilter(prevState => ({
            ...prevState,
            sortDirection: newSortDirection as SortDirection,
            sortField: newField as DownloadCategoryDownloadsFields
        }))
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        setFilter(prevState => ({
            ...prevState,
            page: page + 1
        }))
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilter(prevState => ({
            ...prevState,
            page: 1,
            rowsPerPage: parseInt(event.target.value)
        }))
    }

    const columns: F3MDataGridColumnProps[] = [
        { field: 'businessArea', headerName: i18n.t('downloadCategoryDownload.businessArea'), width: 12.5, sortable: false, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'category', headerName: i18n.t('downloadCategoryDownload.categoryName'), width: 12.5, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'fileName', headerName: i18n.t('downloadCategoryDownload.fileName'), width: 15, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'description', headerName: i18n.t('downloadCategoryDownload.description'), width: 40, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'isActive', headerName: i18n.t('downloadCategoryDownload.isActive'), width: 10, sortable: true, type: 'node', align: 'center', headerAlign: 'center' },
        { field: 'deleteButton', headerName: i18n.t('downloadCategoryDownload.deleteButton'), width: 10, sortable: false, type: 'node', align: 'center', headerAlign: 'center' },
    ]

    const data: F3MDataGridRowData[] = downloadCategoryDownloads.downloadCategoryDownloads.map((r, rowIndex) => {
        return {
            id: rowIndex,
            downloadCategoryid: r.id,
            businessArea:
                <Chip size='small' label={r.businessAreas.map(busArea => i18n.t(busArea.name)).join(' + ')} color='primary' variant='outlined' />,
            category: r.category,
            fileName: r.fileName,
            description: r.description,
            isActive: <span onClick={(e) => e.stopPropagation()}>
                <Switch checked={r.isActive} onChange={(e, c) => handleChangeStatus(r.id, c)} color='primary' size='small' />
                {i18n.t('common.active')}
            </span>,
            deleteButton: <IconButton className={globalClasses.hover} onClick={(e) => handleDeleteBtn(e, r.id)} size='small'><DeleteIcon /></IconButton>
        }
    })

    return (
        <F3MDataGrid
            columns={columns}
            rows={data}
            loading={loading}
            hover={true}
            rowClick={(e) => handleAddUpdtModal('edit', e)}
            pagination={true}
            paginationMode='server'
            rowsPerPageOptions={[5, 10, 15, 20]}
            page={filter.page}
            pageSize={filter.rowsPerPage}
            sortMode='server'
            sortDirection={filter.sortDirection}
            sortField={filter.sortField}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            sortChange={handleSortChange}
            rowCount={downloadCategoryDownloads.rowCount}
        />
    )
}

export default Table