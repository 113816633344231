import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { push } from 'connected-react-router'

import { Button, Grid, Typography } from '@material-ui/core'
import useStyles from './styles'

import TableProducts from '../../software/licensing/TableProducts'
import QuickAccessBtns from './QuickAccessBtns'
import i18n from '../../../../i18n'
import { F3MCard, F3MChart, F3MSnackbar, F3MSnackbarState } from '../../../../components'
import { ticketsService } from '../../../../api'
import { TicketsLastXMonthsResponse } from '../../../../types/services/tickets'
import theme from '../../../../assets/theme'
import Plans from './Plans'
import { ROUTES } from '../../../../constants'

const mapDispatchToProps = (dispatch: any) => ({
    push: (p: string) => dispatch(push(p))
})

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const Dashboard = (props: Props) => {
    const classes = useStyles()

    const { SERVICES } = ROUTES

    const [snackbar, setSnackbar] = useState<F3MSnackbarState>({
        open: false,
        severity: 'info',
        message: ''
    })
    const [statistics, setStatistics] = useState<TicketsLastXMonthsResponse>({ tickets: {} })
    const [loading, setLoading] = useState<boolean>(false)

    const loadStatistics = async () => {
        setLoading(true)
        try {
            const response = await ticketsService.loadNumberTicketsByMonthLastXMonths(4)

            if (response.isSuccess) {
                setStatistics(response.result)
            } else {
                handleError(i18n.t('error.unexpectedError'))
            }
            setLoading(false)
        }
        catch (e) {
            handleError(i18n.t('error.unexpectedError'))
        }
        finally {
            setLoading(false)
        }
    }

    const handleError = (message: string) => {
        setSnackbar({ open: true, severity: 'error', message: message })
    }

    useEffect(() => {
        loadStatistics()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const labels = [
        i18n.t('months.january.label'),
        i18n.t('months.february.label'),
        i18n.t('months.march.label'),
        i18n.t('months.april.label'),
        i18n.t('months.may.label'),
        i18n.t('months.june.label'),
        i18n.t('months.july.label'),
        i18n.t('months.august.label'),
        i18n.t('months.september.label'),
        i18n.t('months.october.label'),
        i18n.t('months.november.label'),
        i18n.t('months.december.label')
    ]

    let labelsToPresent: string[] = []
    let finalDataToPresente: number[] = []

    Object.keys(statistics.tickets).forEach((elem) => {
        labelsToPresent.push(labels[+(elem.split("m_")[1]) - 1])
        finalDataToPresente.push(statistics.tickets[elem])
    })

    const dataset = {
        labels: labelsToPresent,
        datasets: [
            {
                label: i18n.t('reports.numberOfTickets'),
                backgroundColor: theme.palette.warning.main,
                borderColor: theme.palette.warning.main,
                borderWidth: 1,
                hoverBackgroundColor: theme.palette.warning.light,
                hoverBorderColor: theme.palette.warning.light,
                data: finalDataToPresente
            }
        ]
    }

    return (
        <>
            <Typography className={classes.titlePage} gutterBottom>{i18n.t('homepage.greentingGeneral')}</Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                    <F3MCard style={{height: '350px', position: 'relative'}} loading={loading}>
                        <Typography variant='h5' className={classes.cardTitleOrange} gutterBottom>{i18n.t('homepage.last4MonthsInc')}</Typography>
                        <F3MChart dataset={dataset} height={200} noMinWidth={true} />
                        <Button className={classes.buttonSeeMore} onClick={() => props.push(SERVICES.REPORTS.PATH)}>
                            {i18n.t('homepage.seeMore')}
                        </Button>
                    </F3MCard>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Plans handleError={handleError} />
                </Grid>
                <Grid className={classes.buttons} item xs={12} sm={6} lg={3}>
                    <QuickAccessBtns />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TableProducts handleError={handleError} isHomePage={true} />
                </Grid>
            </Grid>
            <F3MSnackbar
                setSnackbar={setSnackbar}
                snackbar={snackbar}
            />
        </>
    )
}

export default connector(Dashboard)