import React, { useEffect, useState } from 'react'

import {
    F3MAddUpdt,
    F3MButton,
    F3MDialog,
    F3MFiltersBacDownUpgr,
    F3MSnackbar,
    F3MSnackbarState,
    F3MDownCatDownlsStateSection,
    F3MBusAreaAndCategory,
    F3MUploadFiles
} from '../../../../../../components'

import Table from './Table'

import i18n from '../../../../../../i18n'

import { Grid } from '@material-ui/core'

import { DownloadCategoryDownloadResponse, DownloadCategoryDownloadsFilter, DownloadCategoryDownloadsResponse, DownloadCategoryDownloadsUpdtCreate, FileNameAndDesc } from '../../../../../../types/services/downloadCategoryDownloads'
import { downloadCategoryDownloadsService } from '../../../../../../api/services/downloadCategoryDownloadsService'

import { downloadCategoriesService } from '../../../../../../api/services/downloadCategoriesService'
import { DownCatBusinessAreasResponse } from '../../../../../../types/services/downloadCategories'
import { F3MAddUpdtSection } from '../../../../../../components/F3MAddUpdt/types'
import { isDownloadCategoryDownloadValid } from '../../../../../../utils'
import { BusinessArea } from '../../../../../../types/services/backoffice'

interface DeleteState {
    open: boolean
    id: number
}

interface DownloadCategoryDownloadsProps {
    setLoading: (b: boolean) => void
    loading: boolean
}

const DownloadCategoryDownloads = (props: DownloadCategoryDownloadsProps) => {
    const [snackbar, setSnackbar] = useState<F3MSnackbarState>({
        open: false,
        severity: 'info',
        message: ''
    })

    const [fetchedDownloadCategoryDownloads, setDownloadCategoryDownloads] = useState<DownloadCategoryDownloadsResponse>({ rowCount: 0, downloadCategoryDownloads: [] })
    const [filter, setFilter] = useState<DownloadCategoryDownloadsFilter>({
        page: 1,
        rowsPerPage: 10,
        sortDirection: 'asc',
        sortField: 'category',
        businessAreas: null,
        category: null
    })

    const [businessAreasFilter, setBusinessAreasFilter] = useState<DownCatBusinessAreasResponse>({ businessAreas: [] })
    const [activeBusAreasFilter, setActiveBusAreasFilter] = useState<string>('')

    const [del, setDel] = useState<DeleteState>({ open: false, id: 0 })

    const [compSections, setCompSections] = useState<Array<F3MAddUpdtSection>>([])
    const [addUpdtModal, setAddUpdtModal] = useState<boolean>(false)
    const [addUpdtModalTitle, setAddUpdtModalTitle] = useState<string>('')
    const [addUpdtTitleVariant, setAddUpdtTitleVariant] = useState<string>('')

    const emptyAddOrUpdt: DownloadCategoryDownloadsUpdtCreate = {
        id: null,
        categoryId: null,
        files: null,
        isActive: true,
        isNewFile: false
    }
    const [addOrUpdt, setAddOrUpdt] = useState<DownloadCategoryDownloadsUpdtCreate>(emptyAddOrUpdt)

    const [callApiToSave, setCallApiToSave] = useState<(object: any) => any>(() => downloadCategoryDownloadsService.createDownloadCategoryDownload)

    const [update, setUpdate] = useState<boolean>(false)

    const loadDownloadCategoryDownloads = async () => {
        props.setLoading(true)
        try {
            await loadDownCatBusAreas();
            const response = await downloadCategoryDownloadsService.loadDownloadCategoryDownloads(filter)

            if (response.isSuccess) {
                setDownloadCategoryDownloads(response.result)
                props.setLoading(false)
            } else {
                props.setLoading(false)
                handleError(i18n.t('error.unexpectedError'))
            }
        }
        catch (e) {
            handleError(i18n.t('error.unexpectedError'))
        }
        finally {
            props.setLoading(false)
            setDel({ open: false, id: 0 })
        }
    }

    const loadDownCatBusAreas = async () => {
        try {
            const response = await downloadCategoriesService.loadDownCatBusAreas()

            if (response.isSuccess) {
                setBusinessAreasFilter(response.result)
                return
            } else {
                throw new Error()
            }
        }
        catch {
            throw new Error()
        }
    }

    const delDownloadCategoryDownload = async () => {
        props.setLoading(true)
        try {
            const response = await downloadCategoryDownloadsService.deleteDownloadCategoryDownload(del.id)

            if (response.isSuccess) {
                await loadDownloadCategoryDownloads()
                handleSuccess(i18n.t('snackbar.deleteSuccess'))
            } else {
                handleError(i18n.t('snackbar.deleteError'))
            }
        }
        catch (e) {
            handleError(i18n.t('snackbar.deleteError'))
        }
        finally {
            props.setLoading(false)
            setDel({ open: false, id: 0 })
        }
    }

    const handleChangeStatus = async (id: number, newStatus: boolean) => {
        props.setLoading(true)
        try {
            const downloadCategoryDownloadToUpdate: DownloadCategoryDownloadsUpdtCreate = {
                ...emptyAddOrUpdt,
                id: id,
                isActive: newStatus
            }
            const response = await downloadCategoryDownloadsService.updateDownloadCategoryDownload(downloadCategoryDownloadToUpdate)

            if (response.isSuccess) {
                await loadDownloadCategoryDownloads()
                handleSuccess(i18n.t('snackbar.updateSuccess'))
            } else {
                handleError(i18n.t('snackbar.updateError'))
            }
        }
        catch (e) {
            handleError(i18n.t('snackbar.updateError'))
        }
        finally {
            props.setLoading(false)
        }
    }

    const handleDeleteBtn = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, id: number) => {
        event.preventDefault()
        event.stopPropagation()
        setDel({ open: true, id: id })
    }

    const handleAddUpdtModal = (type: string, event: React.MouseEvent<HTMLTableRowElement, MouseEvent> | null) => {
        let addOrUpdtToComp: DownloadCategoryDownloadsUpdtCreate = addOrUpdt

        let fileNameAndDesc: FileNameAndDesc = { fileName: '', description: ''}
        let businessAreas: BusinessArea[] = []

        if (type === 'create') {
            setAddUpdtTitleVariant('add')
            setAddUpdtModalTitle(i18n.t('downloadCategoryDownload.addCategory'))
            setCallApiToSave(() => downloadCategoryDownloadsService.createDownloadCategoryDownload)
        }
        else {
            const rowIndex: number | undefined = event ? +event.currentTarget.id : undefined

            if (rowIndex !== undefined) {
                const downloadCategoryDownload: DownloadCategoryDownloadResponse = fetchedDownloadCategoryDownloads.downloadCategoryDownloads[rowIndex]
                addOrUpdtToComp = {
                    ...emptyAddOrUpdt,
                    id: downloadCategoryDownload.id,
                    categoryId: downloadCategoryDownload.categoryId,
                    isActive: downloadCategoryDownload.isActive,
                    isNewFile: false
                }
                fileNameAndDesc = { fileName: downloadCategoryDownload.fileName, description: downloadCategoryDownload.description }
                businessAreas = downloadCategoryDownload.businessAreas

                setAddOrUpdt(addOrUpdtToComp)
                setAddUpdtTitleVariant('edit')
                setAddUpdtModalTitle(i18n.t('downloadCategoryDownload.editCategory'))
                setCallApiToSave(() => downloadCategoryDownloadsService.updateDownloadCategoryDownload)
            }
        }
        setCompSections([
            {
                title: 'downloadCategoryDownload.fileUpload',
                required: true,
                component: <F3MUploadFiles addOrUpdt={addOrUpdtToComp} setAddOrUpdt={setAddOrUpdt} fileNameAndDesc={fileNameAndDesc} handleError={handleError} />
            },
            { title: 'downloadCategoryDownload.information', required: true },
            { component: <F3MBusAreaAndCategory addOrUpdt={addOrUpdtToComp} setAddOrUpdt={setAddOrUpdt} businessAreas={businessAreas} handleError={handleError} setOpen={setAddUpdtModal} /> },
            { title: 'downloadCategoryDownload.isActive', required: true, component: <F3MDownCatDownlsStateSection addOrUpdt={addOrUpdtToComp} setAddOrUpdt={setAddOrUpdt} /> }
        ])
        setAddUpdtModal(true)
    }

    const handleSuccess = (message: string) => {
        setSnackbar({ open: true, severity: 'success', message: message })
    }

    const handleError = (message: string) => {
        setSnackbar({ open: true, severity: 'error', message: message })
    }

    const handleSelectorFilterChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        if (typeof e.target.value === 'string' && e.target.value !== '') {
            const filterBusAreaToApply: number[] = e.target.value.split(/(?:\s*),(?:\s*)/).map(x => +x)
            setFilter(prevState => ({ ...prevState, businessAreas: filterBusAreaToApply }))
            setActiveBusAreasFilter(e.target.value)
        }
        else {
            setFilter(prevState => ({ ...prevState, businessAreas: null }))
            setActiveBusAreasFilter('')
        }
    }

    const handleTextFilterChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFilter(prevState => ({ ...prevState, category: e.currentTarget.value }))
    }

    const resetAddOrUpdt = () => {
        setAddOrUpdt(emptyAddOrUpdt)
        setCompSections([])
    }

    useEffect(() => {
        loadDownloadCategoryDownloads()
    }, [filter, update]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid container justify='space-between' alignItems='center' spacing={1}>
                <Grid item xs={12} sm={8} md={10}>
                    <F3MFiltersBacDownUpgr
                        activeBusAreasFilter={activeBusAreasFilter}
                        handleSelectorFilterChange={handleSelectorFilterChange}
                        businessAreasFilter={businessAreasFilter}
                        handleTextFilterChange={handleTextFilterChange}
                        placeholderSearch={`${i18n.t('downloadCategoryDownload.categoryName')}...`}
                    />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <F3MButton size='medium' onClick={() => handleAddUpdtModal('create', null)}>
                        {i18n.t('button.add')}
                    </F3MButton>
                </Grid>
            </Grid>
            <Table
                downloadCategoryDownloads={fetchedDownloadCategoryDownloads}
                handleChangeStatus={handleChangeStatus}
                handleDeleteBtn={handleDeleteBtn}
                filter={filter}
                setFilter={setFilter}
                loading={props.loading}
                handleAddUpdtModal={handleAddUpdtModal}
            />
            <F3MAddUpdt
                title={addUpdtModalTitle}
                titleVariant={addUpdtTitleVariant}
                open={addUpdtModal}
                setOpen={setAddUpdtModal}
                sections={compSections}
                addOrUpdt={addOrUpdt}
                setSnackBar={setSnackbar}
                update={update}
                setUpdate={setUpdate}
                resetAddOrUpdt={resetAddOrUpdt}
                isValid={isDownloadCategoryDownloadValid}
                callApiToSave={callApiToSave}
            />
            <F3MDialog
                open={del.open}
                variant='confirmation'
                newOnClose={() => setDel((prevState: any) => ({ ...prevState, open: false }))}
                DialogTitleProps={{ children: i18n.t('confirmation.remove.title') }}
                DialogContentProps={{ children: i18n.t('confirmation.remove.message') }}
                DialogActionsProps={{ label: i18n.t('button.remove'), newOnClick: () => delDownloadCategoryDownload() }}
            />
            <F3MSnackbar
                setSnackbar={setSnackbar}
                snackbar={snackbar}
            />
        </>
    )
}

export default DownloadCategoryDownloads