import React, { useEffect, useState } from 'react'

import { Grid, IconButton, Typography } from '@material-ui/core'

import {
    F3MAddUpdt,
    F3MBreadcrumbs,
    F3MCard,
    F3MDataGridColumnProps,
    F3MDataGridRowData,
    F3MDataGrid,
    F3MDialog,
    SortDirection,
    F3MSnackbar,
    F3MSnackbarState,
    F3MButton,
    F3MDropzoneSection,
    F3MCarouselInfoFields,
    F3MButtonFields,
    F3MCarouselStateSection
} from '../../../../components'

import i18n from '../../../../i18n'

import { isDefined, isSlideValid } from '../../../../utils'

import DeleteIcon from '@material-ui/icons/Delete'
import LinkIcon from '@material-ui/icons/Link'
import Avatar from '@material-ui/core/Avatar'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import useStyles from './styles'

import { SlideResponse } from '../../../../types/services/carousel'

import { carouselService } from '../../../../api/services/carouselService'
import { CarouselFilter } from '../../../../types/services/carousel/CarouselFilter'
import { CarouselFields } from '../../../../types'
import { F3MAddUpdtSection } from '../../../../components/F3MAddUpdt/types'
import { AddUpdtSlide } from '../../../../types/services/carousel/AddUpdtSlide'
import { FILESIZE } from '../../../../constants'

interface IRowBlobImg {
    [key: string]: string
}

interface DeleteState {
    open: boolean
    id: string
    isActive: boolean
    order: number | null
}

const CarouselManager = () => {
    const classes = useStyles()

    const [fetchedRows, setFetchedRows] = useState<SlideResponse[]>([])
    const [rows, setRows] = useState<F3MDataGridRowData[]>([])
    const [rowCount, setRowCount] = useState<number>(0)
    const [activeRowCount, setActiveRowCount] = useState<number>(0)
    const [rowsBlobImg, setRowsBlobImg] = useState<IRowBlobImg>({})

    const [compSections, setCompSections] = useState<Array<F3MAddUpdtSection>>([])
    const [addUpdtModal, setAddUpdtModal] = useState<boolean>(false)
    const [addUpdtModalTitle, setAddUpdtModalTitle] = useState<string>('')
    const [addUpdtTitleVariant, setAddUpdtTitleVariant] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [filter, setFilter] = useState<CarouselFilter>({
        page: 1,
        rowsPerPage: 10,
        sortDirection: 'asc',
        sortField: 'order'
    })

    const [deleteRow, setDeleteRow] = useState<DeleteState>({ open: false, id: '', isActive: false, order: null })
    const [snackbar, setSnackbar] = useState<F3MSnackbarState>({
        open: false,
        severity: 'info',
        message: ''
    })

    const emptyAddOrUpdt: AddUpdtSlide = {
        id: null,
        newImage: true,
        image: null,
        status: true,
        activeRows: activeRowCount,
        order: activeRowCount + 1,
        title: '',
        description: '',
        buttonText: '',
        buttonLink: '',
        buttonColor: ''
    }
    const [addOrUpdt, setAddOrUpdt] = useState<AddUpdtSlide>(emptyAddOrUpdt)
    const [update, setUpdate] = useState<boolean>(false)

    const columns: F3MDataGridColumnProps[] = [
        { field: 'imagePath', headerName: i18n.t('carousel.image'), width: 10, sortable: false, type: 'node', align: 'center', headerAlign: 'center' },
        { field: 'isActive', headerName: i18n.t('carousel.state'), width: 15, sortable: true, type: 'node', align: 'center', headerAlign: 'center' },
        { field: 'order', headerName: i18n.t('carousel.order'), width: 10, sortable: true, type: 'number', align: 'right', headerAlign: 'right' },
        { field: 'title', headerName: i18n.t('carousel.title'), width: 45, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'buttonLink', headerName: i18n.t('carousel.link'), width: 10, sortable: false, type: 'node', align: 'center', headerAlign: 'center' },
        { field: 'deleteButton', headerName: i18n.t('carousel.delete'), width: 10, sortable: false, type: 'node', align: 'center', headerAlign: 'center' },
    ]

    const getImage = async (rowId: string, imagePath: string) => {
        const response: any = await carouselService.loadSlideImage(imagePath)

        const { data, headers } = response
        const blob: Blob = new Blob([data], { type: headers['content-type'] })

        const blobImg: string = window.URL.createObjectURL(blob)

        const rowBlobImg: IRowBlobImg = {}
        rowBlobImg[rowId] = blobImg
        setRowsBlobImg(prevState => ({ ...prevState, ...rowBlobImg }))

        setLoading(false)
        return blobImg
    }

    const delSlide = async () => {
        if (activeRowCount > 1 || deleteRow.isActive === false) {
            setLoading(true)
            await carouselService.deleteSlide(deleteRow.id, deleteRow.isActive, deleteRow.order)
                .then(async () => {
                    await loadCarouselManagement()
                    handleSuccess(i18n.t('snackbar.deleteSuccess'))
                })
                .catch((errorMessage) => {
                    handleError(i18n.t('snackbar.deleteError'))
                })
            setLoading(false)
        }
        else {
            handleError(i18n.t('snackbar.deleteError'))
        }
        setDeleteRow({ open: false, id: '', isActive: false, order: null })
    }

    const handleSortChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        const id = e.currentTarget.parentElement!.id

        const { sortDirection, sortField } = filter
        let newSortDirection = 'asc'
        let newField = 'order'

        if (sortField === id) {
            newField = sortField
            newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
        } else {
            newSortDirection = 'asc'
            newField = id
        }

        setFilter(prevState => ({
            ...prevState,
            sortDirection: newSortDirection as SortDirection,
            sortField: newField as CarouselFields
        }))
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        setFilter(prevState => ({
            ...prevState,
            page: page + 1
        }))
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilter(prevState => ({
            ...prevState,
            page: 1,
            rowsPerPage: parseInt(event.target.value)
        }))
    }

    const handleChangeStatus = async (actRowId: string, status: boolean, rowOrder: number | null) => {
        if (activeRowCount > 1 || !status) {
            setLoading(true)
            carouselService.setSlideStatus(actRowId, !status, rowOrder)
                .then(async () => {
                    await loadCarouselManagement()
                    handleSuccess(i18n.t('snackbar.updateSuccess'))
                })
                .catch((errorMessage) => {
                    handleError(i18n.t('snackbar.updateError'))
                })
            setLoading(false)
        }
        else {
            handleError(i18n.t('snackbar.updateError'))
        }
    }

    const handleDeleteBtn = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, actRowId: string, isActive: boolean, order: number | null) => {
        event.preventDefault()
        event.stopPropagation()
        setDeleteRow({ open: true, id: actRowId, isActive: isActive, order: order })
    }

    const handleButtonLink = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, buttonLink: string | undefined) => {
        event.preventDefault()
        event.stopPropagation()
        window.open(buttonLink)
    }

    const handleImageChange = (files: File[]) => {
        setAddOrUpdt(prevState => ({ ...prevState, image: files }))
    }

    const getImageAddedMessage = (fileName: string) => {
        setAddOrUpdt(prevState => ({ ...prevState, newImage: true }))
        return i18n.t('dropzone.fileAdded').replace('FILE_NAME', fileName)
    }

    const handleAddUpdtModal = (type: string, event: React.MouseEvent<HTMLTableRowElement, MouseEvent> | null) => {
        let addOrUpdtToComp: AddUpdtSlide = addOrUpdt

        if (type === 'create') {
            setAddUpdtModalTitle(i18n.t('carousel.addSlide'))
            setAddUpdtTitleVariant('add')
        }
        else {
            const id: number | undefined = event ? +event.currentTarget.id : undefined

            if (id !== undefined) {
                addOrUpdtToComp = {
                    id: fetchedRows[id].id,
                    newImage: false,
                    image: [rowsBlobImg[fetchedRows[id].id]],
                    status: fetchedRows[id].isActive,
                    activeRows: activeRowCount - 1,
                    order: fetchedRows[id].order,
                    title: fetchedRows[id].title,
                    description: fetchedRows[id].description,
                    buttonText: fetchedRows[id].buttonText,
                    buttonLink: fetchedRows[id].buttonLink,
                    buttonColor: fetchedRows[id].buttonColor
                }
                setAddOrUpdt(addOrUpdtToComp)
                setAddUpdtTitleVariant('edit')
                setAddUpdtModalTitle(i18n.t('carousel.editSlide'))
            }
        }
        setCompSections([
            {
                title: 'carousel.uplImg',
                required: true,
                component: <F3MDropzoneSection
                    handleFileChange={handleImageChange}
                    getFileAddedMessage={getImageAddedMessage}
                    initialFiles={addOrUpdtToComp.image !== null ? addOrUpdtToComp.image : []}
                    filesLimit={1}
                    acceptedFiles={['.png', '.jpg', '.jpeg','image/jpeg', 'image/png']}
                    maxFileSize={FILESIZE['1MB']}
                    detailsMessage={i18n.t('dropzone.images')} />
            },
            { title: 'carousel.status', required: true, component: <F3MCarouselStateSection addOrUpdt={addOrUpdtToComp} setAddOrUpdt={setAddOrUpdt} /> },
            { title: 'carousel.info', required: true, component: <F3MCarouselInfoFields addOrUpdt={addOrUpdtToComp} setAddOrUpdt={setAddOrUpdt} handleError={handleError} /> },
            { title: 'carousel.button', component: <F3MButtonFields addOrUpdt={addOrUpdtToComp} setAddOrUpdt={setAddOrUpdt} handleError={handleError} /> }
        ])
        setAddUpdtModal(true)
    }

    const resetAddOrUpdt = () => {
        setAddOrUpdt(emptyAddOrUpdt)
        setCompSections([])
    }

    const handleSuccess = (message: string) => {
        setSnackbar({ open: true, severity: 'success', message: message })
    }

    const handleError = (message: string) => {
        setSnackbar({ open: true, severity: 'error', message: message })
    }

    const loadCarouselManagement = async () => {
        setLoading(true)
        try {
            const response = await carouselService.loadCarouselManagement(filter)

            if (response.isSuccess && response.result) {
                setRowCount(response.result.rowCount)
                setActiveRowCount(response.result.activeRowCount)
                setFetchedRows(response.result.slides)
            }
        }
        catch (e) {
            handleError(i18n.t('error.unexpectedError'))
        }
        finally {
            setLoading(false)
        }
    }

    const buildRows = async () => {
        let resultRows: F3MDataGridRowData[] = []
        if (isDefined(fetchedRows)) {
            resultRows = await Promise.all(fetchedRows.map(async (r, index): Promise<F3MDataGridRowData> => {
                const rowId: string = `${r.id}`
                const image: string = await getImage(rowId, r.imagePath)
                return {
                    id: index,
                    order: r.order,
                    isActive: <span id='isActive' onClick={(event) => event.stopPropagation()}>
                        <FormControlLabel
                            control={
                                <Switch
                                    size='small'
                                    checked={r.isActive}
                                    onChange={() => handleChangeStatus(rowId, r.isActive, r.order)}
                                    color='primary'
                                />
                            }
                            label={i18n.t('common.active')}
                        />
                    </span>,
                    imagePath: <Avatar variant='rounded' src={image} />,
                    title: r.title,
                    buttonLink: r.buttonLink !== null
                        ? <IconButton size='small' id='buttonLink' onClick={(event) => handleButtonLink(event, r.buttonLink)}><LinkIcon className={classes.icons} /></IconButton>
                        : '',
                    deleteButton: <IconButton size='small' id='deleteButton' onClick={(event) => handleDeleteBtn(event, rowId, r.isActive, r.order)}><DeleteIcon className={classes.icons} /></IconButton>
                }
            }))
        }
        setRows(resultRows)
    }

    useEffect(() => {
        loadCarouselManagement()
    }, [filter, update])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setAddOrUpdt(emptyAddOrUpdt)
        buildRows()
    }, [fetchedRows])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <F3MBreadcrumbs path={i18n.t('paths.carousel.breadcrumb')} />
            <F3MCard loading={loading}>
                <Typography variant='h5' color='primary' gutterBottom>{i18n.t('carousel.cardTitle')}</Typography>
                <Grid container justify='flex-end' alignItems='center'>
                    <Grid item xs={12} sm={6} md={1}>
                        <F3MButton size='medium' onClick={() => handleAddUpdtModal('create', null)}>
                            {i18n.t('button.add')}
                        </F3MButton>
                    </Grid>
                </Grid>
                <F3MDataGrid
                    columns={columns}
                    rows={rows}
                    loading={loading}
                    hover={true}
                    rowClick={(e) => handleAddUpdtModal('edit', e)}
                    pagination={true}
                    paginationMode='server'
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    page={filter.page}
                    pageSize={filter.rowsPerPage}
                    sortMode='server'
                    sortDirection={filter.sortDirection}
                    sortField={filter.sortField}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    sortChange={handleSortChange}
                    rowCount={rowCount}
                />
                <F3MAddUpdt
                    title={addUpdtModalTitle}
                    titleVariant={addUpdtTitleVariant}
                    open={addUpdtModal}
                    setOpen={setAddUpdtModal}
                    sections={compSections}
                    addOrUpdt={addOrUpdt}
                    setSnackBar={setSnackbar}
                    update={update}
                    setUpdate={setUpdate}
                    resetAddOrUpdt={resetAddOrUpdt}
                    isValid={isSlideValid}
                    callApiToSave={carouselService.addorUpdtSlide}
                ></F3MAddUpdt>
                <F3MDialog
                    open={deleteRow.open}
                    variant='confirmation'
                    newOnClose={() => setDeleteRow({ open: false, id: '', isActive: false, order: null })}
                    DialogTitleProps={{ children: i18n.t('confirmation.remove.title') }}
                    DialogContentProps={{ children: i18n.t('confirmation.remove.message') }}
                    DialogActionsProps={{ label: i18n.t('button.remove'), newOnClick: async () => await delSlide() }}
                />
            </F3MCard>
            <F3MSnackbar
                setSnackbar={setSnackbar}
                snackbar={snackbar}
            />
        </>
    )
}

export default CarouselManager