import { IncidentFields, SortDirection } from '../../types'
import { ApplicationResponse } from '../../types/services/application'
import { ContractResponse, IncidentsResponse } from '../../types/services/incidents'
import {
    ClearData,
    HandleFilterChange,
    HandlePageChange,
    HandleRowsPerPageChange,
    LoadApplications,
    LoadContracts,
    LoadIncidentsError,
    LoadIncidentsRequest,
    LoadIncidentsSuccess,
    Order,
    SetLoading,
    ToggleFilter
} from '../../types/store/incident'

const incidentActions = {
    handleFilterChange: (data: { name: string, value: string | number }): HandleFilterChange => {
        return { type: 'INCIDENT_HANDLE_FILTER_CHANGE', payload: data }
    },

    handlePageChange: (page: number): HandlePageChange => {
        return { type: 'INCIDENT_HANDLE_PAGE_CHANGE', payload: page }
    },

    handleRowsPerPageChange: (rowsPerPage: number): HandleRowsPerPageChange => {
        return { type: 'INCIDENT_HANDLE_ROWS_PER_PAGE_CHANGE', payload: rowsPerPage }
    },

    loadIncidentsRequest: (): LoadIncidentsRequest => {
        return { type: 'INCIDENT_LOAD_INCIDENTS_REQUEST' }
    },

    loadIncidentsSuccess: (data: IncidentsResponse): LoadIncidentsSuccess => {
        return { type: 'INCIDENT_LOAD_INCIDENTS_SUCCESS', payload: data }
    },

    loadIncidentsError: (error: string): LoadIncidentsError => {
        return { type: 'INCIDENT_LOAD_INCIDENTS_ERROR', payload: error }
    },

    clearData: (): ClearData => {
        return { type: 'INCIDENT_CLEAR_DATA' }
    },

    loadContracts: (contracts: ContractResponse[]): LoadContracts => {
        return { type: 'INCIDENT_LOAD_CONTRACTS', payload: contracts }
    },

    loadApplications: (applications: ApplicationResponse[]): LoadApplications => {
        return { type: 'INCIDENT_LOAD_APPLICATIONS', payload: applications }
    },

    toggleFilter: (): ToggleFilter => {
        return { type: 'INCIDENT_TOGGLE_FILTER' }
    },

    setLoading: (loading: boolean): SetLoading => {
        return { type: 'SET_LOADING', payload: loading }
    },

    order: (sortDirection: SortDirection, sortField: IncidentFields): Order => {
        return { type: 'INCIDENT_ORDER', payload: { sortDirection, sortField } }
    }
}

export default incidentActions