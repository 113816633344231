import { makeStyles, createStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => createStyles({
    alertRoot: {
        fontSize: '0.875rem',
        lineHeight: 1.43
    }
}))

export default useStyles