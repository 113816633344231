import React from 'react'

import { IconButton } from '@material-ui/core'

import GetAppIcon from '@material-ui/icons/GetApp'

import { isDefined, openOrDownload } from '../../utils'

import { F3MDownloadProps } from '..'
import i18n from '../../i18n'
import useStyles from './styles'

const F3MDownload = (props: F3MDownloadProps) => {
    const classes = useStyles()

    let downloadProps: F3MDownloadProps = {
        // ICON => GetAppIcon
        icon: isDefined(props.icon) ? props.icon : <GetAppIcon />,

        // FILENAME
        filename: props.filename,

        // ISLOADING
        isLoading: props.isLoading,

        // LOADING
        setLoading: props.setLoading,

        // ON CLICK
        request: props.request,

        // HANDLE ERROR
        handleError: props.handleError
    }

    const { icon, filename, isLoading, setLoading, request, handleError } = downloadProps

    const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (setLoading !== undefined) {
            setLoading(true)
        }

        const response = await request()
        try {
            if (response && response.status === 200) {
                openOrDownload(response, filename)
            } else {
                handleError(i18n.t('error.unexpectedError'))
            }
        }
        catch (e) {
            handleError(i18n.t('error.unexpectedError'))
        }
        finally {
            if (setLoading !== undefined) {
                setLoading(false)
            }
        }
    }

    return (
        <IconButton disabled={isLoading} className={classes.hover} size='small' onClick={handleClick}>
            {icon}
        </IconButton>
    )
}

export default F3MDownload