import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { Container, useTheme } from '@material-ui/core'

import { F3MAppBar, F3MSidebar, F3MFooter, F3MDialog } from '../../components'

import Routes from '../../router/Routes'

import { ApplicationState } from '../../types/store'
import commonOperations from '../../store/operations/commonOperations'
import loginOperations from '../../store/operations/loginOperations'
import servCatalogOperations from '../../store/operations/servCatalogOperations'

import i18n from '../../i18n'

import useStyles from './styles'
import { ROUTES } from '../../constants'

const mapStateToProps = (state: ApplicationState) => ({
    commonState: state.common,
    loginState: state.login,
    routerState: state.router
})

const mapDispatchToProps = (dispatch: any) => ({
    setUser                 : () => dispatch(loginOperations.setUser()),
    loadServCatalogUrl      : () => dispatch(servCatalogOperations.loadServCatalogUrl()),
    toggleLogoutConfirmation: () => dispatch(commonOperations.toggleLogoutConfirmation()),
    logout                  : () => dispatch(loginOperations.logout())
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const Home = (props: Props) => {
    const classes = useStyles()
    const theme = useTheme()

    useEffect(() => {
        props.setUser()
        props.loadServCatalogUrl()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onClose = () => {
        props.toggleLogoutConfirmation()
        props.logout()
    }

    const IsBackoffice = (): boolean => [
            ROUTES.BACKOFFICE.CAROUSEL.PATH,
            ROUTES.BACKOFFICE.APPLICATIONS.PATH,
            ROUTES.BACKOFFICE.DOWNLOADS.PATH
        ].includes(props.routerState?.location.pathname!)

    return (
        <>
            <F3MAppBar />
            <F3MSidebar />
            <Container
                className = {classes.root}
                component = 'div'
                maxWidth  = {false}
                disableGutters
                style     = {{backgroundColor: IsBackoffice() ? '#e3f7ff' : theme.palette.grey[50]}}
            >
                <Container className={classes.main} component='div' maxWidth={false}>
                    <Routes />
                </Container>
                <F3MFooter />
            </Container>

            <F3MDialog
                open               = {props.commonState?.logoutConfirmationOpen!}
                variant            = 'confirmation'
                newOnClose         = {props.toggleLogoutConfirmation}
                DialogTitleProps   = {{children: i18n.t('confirmation.logout.title')}}
                DialogContentProps = {{children: i18n.t('confirmation.logout.message')}}
                DialogActionsProps = {{label: i18n.t('confirmation.logout.button'), newOnClick: onClose}}
            />
        </>
    )
}

export default connector(Home)