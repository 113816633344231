import { incidentsService } from '../../api'
import { IncidentFields } from '../../types'
import { AppThunkAction } from '../../types/store'
import { IncidentActions } from '../../types/store/incident'
import incidentActions from '../actions/incidentActions'
import { SortDirection } from '../../types'

const {
    clearData,
    handleFilterChange,
    handlePageChange,
    handleRowsPerPageChange,
    loadApplications,
    loadContracts,
    loadIncidentsError,
    loadIncidentsRequest,
    loadIncidentsSuccess,
    order,
    setLoading,
    toggleFilter
} = incidentActions

const incidentOperations = {
    handleFilterChange: (name: string, value: any): AppThunkAction<IncidentActions> => async (dispatch, getState) => {            
        dispatch(handleFilterChange({name, value}))
    },

    handleChangePage: (event: any, page: number): AppThunkAction<IncidentActions> => async (dispatch, getState) => {
        dispatch(handlePageChange(page))
    },

    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): AppThunkAction<IncidentActions> => async (dispatch, getState) => {
        dispatch(handleRowsPerPageChange(parseInt(event.target.value)))
    },

    loadIncidents: (): AppThunkAction<IncidentActions> => async (dispatch, getState) => {
        dispatch(loadIncidentsRequest())

        try {
            const response = await incidentsService.loadIncidents(getState().incidents?.incidents.filter!)
            
            if(response.isSuccess) {
                dispatch(loadIncidentsSuccess(response.result))
            } else {
                dispatch(loadIncidentsError(response.errorMessage))
            }
        } catch(e) {
            dispatch(loadIncidentsError(e.response))
        }
    },

    clearData: (): AppThunkAction<IncidentActions> => async (dispatch, getState) => {
        dispatch(clearData())
    },

    loadContracts: (): AppThunkAction<IncidentActions> => async (dispatch, getState) => {
        try {
            const response = await incidentsService.loadContracts()

            dispatch(loadContracts(response.result))
        } catch(e) {
            dispatch(loadContracts([]))
        }
    },

    loadApplications: (): AppThunkAction<IncidentActions> => async (dispatch, getState) => {
        try {
            const response = await incidentsService.loadApplications()
            dispatch(loadApplications(response.result))
        } catch(e) {
            dispatch(loadApplications([]))
        }
    }, 

    toggleFilter: (): AppThunkAction<IncidentActions> => async (dispatch, getState) => {
        dispatch(toggleFilter())
    },

    setLoading: (loading: boolean): AppThunkAction<IncidentActions> => (dispatch) => {
        dispatch(setLoading(loading))
    },

    order: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): AppThunkAction<IncidentActions> => async (dispatch, getState) => {
        e.preventDefault()
        const id = e.currentTarget.parentElement!.id

        const {sortDirection, sortField} = getState().incidents!.incidents.filter
        let newSortDirection= 'desc'
        let newField = 'date'

        if(sortField === id) {
            newField = sortField
            if(sortDirection === 'asc') {
                newSortDirection = 'desc'
            } else {
                newSortDirection = 'asc'
            }
        } else {
            newSortDirection = 'asc'
            newField = id
        }

        dispatch(order(newSortDirection as SortDirection, newField as IncidentFields))
    }
}

export default incidentOperations