import React from 'react'
import clsx from 'clsx'

import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'

import { IconButton, Container, Avatar, Menu, MenuItem, Typography, Divider } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import i18n from '../../../i18n'
import { ApplicationState } from '../../../types/store'
import commonOperations from '../../../store/operations/commonOperations'
import loginOperations from '../../../store/operations/loginOperations'

import useStyles from './styles'

const mapStateToProps = (state: ApplicationState) => ({
    commonState: state.common,
    loginState: state.login
})

const mapDispatchToProps = (dispatch: any) => ({
    toggleSidebar: (e: any) => dispatch(commonOperations.toggleSidebar(e)),
    toggleLogoutConfirmation: () => dispatch(commonOperations.toggleLogoutConfirmation()),
    logout: () => dispatch(loginOperations.logout())
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const HomeAppBar = (props: Props) => {
    const classes = useStyles()

    const { sidebarOpen } = props.commonState!

    const { user } = props.loginState!

    const [menu, setMenu] = React.useState<null | HTMLElement>(null)

    const menuOpen = (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        setMenu(event.currentTarget)
    }

    const menuClose = () => {
        setMenu(null)
    }

    const logoutToggle = () => {
        props.toggleLogoutConfirmation()
        menuClose()
    }

    return (
        <>
            <IconButton className={classes.sidebarToggleIcon} onClick={props.toggleSidebar} onTouchEnd={props.toggleSidebar}>
                {sidebarOpen ? <CloseIcon/> : <MenuIcon />}
            </IconButton>
            
            <Link to='/'>
                <img className={classes.logo} src='/images/logos/logo-f3m.svg' alt='Logo' />
            </Link>

            <Container className={classes.contentContainer}>
                <Avatar className={classes.avatar} src='/images/default-avatar.svg' sizes='small' onClick={menuOpen} />
                <IconButton onClick={menuOpen} className={clsx(classes.menuExpand, Boolean(menu) ? classes.menuExpandClose : null)}>
                    <ExpandMoreIcon color='primary' />
                </IconButton>
                <Menu
                    keepMounted
                    anchorEl           = {menu}
                    open               = {Boolean(menu)}
                    onClose            = {menuClose}
                    getContentAnchorEl = {null}
                    anchorOrigin       = {{vertical: 'bottom', horizontal: 'center'}}
                    transformOrigin    = {{vertical: 'top', horizontal: 'center'}}
                    classes            = {{paper: classes.menuPaper}}
                >
                    <Typography className={classes.menuTitle} variant='h5' color='textPrimary'>{user?.name}</Typography>
                    <Divider className={classes.divider} />
                    {/* <MenuItem className={classes.item} onClick={menuClose}>{i18n.t('common.settings')}</MenuItem> */}
                    <MenuItem className={classes.item} onClick={logoutToggle}>{i18n.t('common.logout')}</MenuItem>
                </Menu>
            </Container>
        </>
    )
}

export default connector(HomeAppBar)