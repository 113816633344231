import React from 'react'

import { Typography, Box, Container } from '@material-ui/core'
import { Carousel } from 'react-responsive-carousel'

import { isDefined } from '../../utils'

import { F3MCarouselProps } from './types'

import useStyles from './styles'
import F3MCarouselButton from './F3MCarouselButton'

const F3MCarousel = (props: F3MCarouselProps) => {
    const classes = useStyles(props)

    // Default AUTOPLAY
    let autoplay = isDefined(props.autoPlay) ? props.autoPlay : true

    // Default CLASSNAME EMPTY
    let className = isDefined(props.className) ? props.className : ''

    // Default INFINITE LOOP
    let infiniteLoop = isDefined(props.infiniteLoop) ? props.infiniteLoop : true

    // Default INTERVAL 4S
    let interval = isDefined(props.interval) ? props.interval : 4000

    // Default NOT SHOW THUMBS
    let showThumbs = isDefined(props.showThumbs) ? props.showThumbs : false

    // Default NOT SHOW ARROWS
    let showArrows = isDefined(props.showArrows) ? props.showArrows : false

    // Default NOT SHOW INDICATORS
    let showIndicators = isDefined(props.showIndicators) ? props.showIndicators : false

    // Default NOT SHOW STATUS
    let showStatus = isDefined(props.showStatus) ? props.showStatus : false

    // Default STOP ON HOVER
    let stopOnHover = isDefined(props.stopOnHover) ? props.stopOnHover : true

    // Default WIDTH 100%
    let width = isDefined(props.width) ? props.width : '100%'

    return (
        <Carousel
            autoPlay={autoplay}
            className={className}
            infiniteLoop={infiniteLoop}
            interval={interval}
            showThumbs={showThumbs}
            showArrows={showArrows}
            showIndicators={showIndicators}
            showStatus={showStatus}
            stopOnHover={stopOnHover}
            width={width}
        >
            {props.carouselSlides.map((slide) => (
                <Box
                    key={slide.title}
                    className={classes.root}
                    minWidth='100vw'
                    style={{
                        backgroundImage: 'url(' + slide.imagePath + ')',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: '50% 50%'
                    }}
                >
                    <Container className={classes.container}>
                        <Box maxWidth={445} marginBottom={2}>
                            <Typography className={classes.text} variant='h1'>{slide.title}</Typography>
                        </Box>
                        <Box maxWidth={330} marginBottom={3}>
                            <Typography className={classes.text} variant='subtitle1'>{slide.description}</Typography>
                        </Box>

                        {isDefined(slide.buttonLink) &&
                            <F3MCarouselButton buttonLink={slide.buttonLink} buttonColor={slide.buttonColor} buttonText={slide.buttonText} />
                        }
                    </Container>
                </Box>
            ))}
        </Carousel>
    )
}

export default F3MCarousel