import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import { createBrowserHistory, History } from 'history'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'

import { ThemeProvider, CssBaseline, LinearProgress } from '@material-ui/core'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

import App from './App'
import * as serviceWorker from './serviceWorker'
import theme from './assets/theme'

import configureStore from './store'

import './assets/css/index.scss'

// Router
const history: History = createBrowserHistory()
export const store = configureStore(history)

// Font Awesome Library
library.add(fab)
library.add(fas)

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Suspense fallback={LinearProgress}>
                    <App />
                </Suspense>
            </ThemeProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
)

serviceWorker.register()
