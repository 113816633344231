export const isDefined = (v: any): boolean => 
	v !== undefined && v !== null

export const isNumber = (v: any): boolean =>
	typeof v === 'number'

export const isArray = (v: any) : boolean =>
	Array.isArray(v)

export const isString = (v: any): boolean =>
	typeof v === 'string'

export const isStringEmpty = (v: string): boolean =>
	v.trim() === ''

export const stringToBool = (v: string): boolean => 
	v.toUpperCase() === 'TRUE'

export const clone = (v: any): any => 
	JSON.parse(JSON.stringify(v))

export const lastElemOf = (array: any[]): any => 
	array[array.length - 1]

export const isEven = (i: number): boolean =>
	i % 2 === 0

export const isDev = (): boolean =>
	process.env.NODE_ENV === 'development'

export const isProd = (): boolean =>
	process.env.NODE_ENV === 'production'

export const convertBytesToMbsOrKbsOrGb = (filesize: number) => {
	let size = ''
	if(filesize >= 1073741824){
		size = filesize / 1073741824 + ' gigabytes'
	} else if (filesize >= 1048576) {
		size = filesize / 1048576 + ' megabytes'
	} else if (filesize >= 1024) {
		size = filesize / 1024 + ' kilobytes'
	} else {
		size = filesize + ' bytes'
	}
	
	return size
	}