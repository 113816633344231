import { Theme, makeStyles, createStyles } from '@material-ui/core'

import { isDefined } from '../../utils'

import { F3MTextFieldProps } from './types'

const useStyles = makeStyles<Theme, F3MTextFieldProps>((theme: Theme) => createStyles({
    formLabelRoot: props => ({
        opacity: 1,
        color: isDefined(props.value) ? theme.palette[props.color!].main : theme.palette.grey[300],
        fontWeight: props.bold ? 700 : 'normal'
    }),
    formLabelDisabled: {},
    formLabelFocused: {},
    inputLabelRoot: {},
    inputLabelOutlined: {
        color: theme.palette.text.primary + ' !important'
    },
    inputLabelStandard: {
        color: theme.palette.text.primary + ' !important'
    },
    inputLabelFilled: {
        color: theme.palette.text.primary + ' !important'
    },
    inputLabelDisabled: {},
    inputLabelFocused: props => ({
        opacity: 1,
        color: theme.palette[props.color!].main + ' !important',
        fontWeight: 700
    }),
    inputRoot: {
        color: theme.palette.text.primary
    },
    inputRootDisabled: {
        color: theme.palette.grey[300]
    },
    focused: props => ({
        '& > fieldset': {
            borderColor: theme.palette[props.color!].main + ' !important'
        }
    })
}))

export default useStyles