import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'

import useStyles from './styles'
import { Checkbox, FormControlLabel, FormLabel } from '@material-ui/core'

import F3MSelect from '../../../F3MSelect'
import i18n from '../../../../i18n'

import { BusinessAreasCategoriesResponse } from '../../../../types/services/downloadCategoryDownloads'
import { downloadCategoryDownloadsService } from '../../../../api/services/downloadCategoryDownloadsService'
import { BusinessArea } from '../../../../types/services/backoffice'
import { ContentPropsDownCatDonwlsUpdtCreate } from '../../../F3MAddUpdt/types'
import { backofficeService } from '../../../../api'

const F3MBusAreaAndCategory = (props: ContentPropsDownCatDonwlsUpdtCreate &
{
    businessAreas: BusinessArea[],
    handleError: (message: string) => void,
    setOpen: Dispatch<SetStateAction<boolean>>
}) => {

    const classes = useStyles()

    const {
        addOrUpdt,
        setAddOrUpdt,
        businessAreas,
        handleError,
        setOpen
    } = props

    const [busAreasToPresent, setBusAreasToPresent] = useState<BusinessArea[]>([])
    const [busAreasSelected, setBusAreasSelected] = useState<BusinessArea[]>(businessAreas)
    const [fetchedCategories, setCategories] = useState<BusinessAreasCategoriesResponse>({ categories: [] })
    const [categorySelected, setCategorySelected] = useState<string>(addOrUpdt.categoryId ? `${addOrUpdt.categoryId}` : '')
    const [selectDisabled, setSelectDisabled] = useState<boolean>(busAreasSelected.length > 0 ? false : true)

    const loadBusinessAreas = async () => {
        try {
            const response = await backofficeService.loadBusinessAreas()
            if (response.isSuccess) {
                setBusAreasToPresent(response.result)
            } else {
                handleError(i18n.t('error.unexpectedError'))
                setOpen(false)
            }
        }
        catch (e) {
            handleError(i18n.t('error.unexpectedError'))
            setOpen(false)
        }
    }
    
    const loadCategories = async () => {
        try {
            const response = await downloadCategoryDownloadsService.getBusinessAreasCategories(busAreasSelected)

            if (response.isSuccess) {
                setCategories(response.result)
            } else {
                handleError(i18n.t('error.unexpectedError'))
                setOpen(false)
            }
        }
        catch (e) {
            handleError(i18n.t('error.unexpectedError'))
            setOpen(false)
        }
    }

    const handleChangeBusAreas = (e: ChangeEvent<HTMLInputElement>) => {
        if (isSelected(e.target.id)) {
            const newBusAreas: BusinessArea[] = busAreasSelected.filter(x => x.id !== e.target.id)
            setBusAreasSelected(newBusAreas)
            if (newBusAreas.length === 0) {
                setSelectDisabled(true)
            }
        }
        else {
            const newBusArea = {
                id: e.target.id,
                code: e.target.name,
                name: e.target.labels ? e.target.labels[0].innerText : ''
            }
            setBusAreasSelected(prevState => [...prevState, newBusArea])
            setSelectDisabled(false)
        }
        setCategorySelected('')
        setAddOrUpdt(prevState => ({ ...prevState, categoryId: null }))
    }

    const handleSelectorFilterChange = (e: ChangeEvent<{ value: unknown }>) => {
        if (typeof e.target.value === 'number') {
            const newCategoryId: number = e.target.value
            setCategorySelected(newCategoryId.toString())
            setAddOrUpdt(prevState => ({ ...prevState, categoryId: newCategoryId }))
        }
        else {
            setCategorySelected('')
            setAddOrUpdt(prevState => ({ ...prevState, categoryId: null }))
        }
    }

    const isSelected = (id: string) => {
        return busAreasSelected.some(busArea => busArea.id === id)
    }

    useEffect(() => {
        loadBusinessAreas()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadCategories()
    }, [busAreasSelected]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={classes.root}>
            <div>
                <FormLabel component="legend">{i18n.t('downloadCategoryDownload.businessArea')}</FormLabel>
                {busAreasToPresent.map((busArea, index) => (
                    <FormControlLabel
                        key={index.toString()}
                        control={
                            <Checkbox
                                checked={isSelected(busArea.id)}
                                onChange={(e) => handleChangeBusAreas(e)}
                                id={busArea.id}
                                name={busArea.code}
                                color='primary'
                            />
                        }
                        label={busArea.name}
                    />
                ))}
            </div>
            <F3MSelect
                labelId='categorySelectLabel'
                label={i18n.t('downloadCategoryDownload.category')}
                name='categorySelect'
                value={categorySelected}
                onChange={handleSelectorFilterChange}
                disabled={selectDisabled}
                items={fetchedCategories.categories}
                displayAll={false}
            />
        </div>
    )
}

export default F3MBusAreaAndCategory