import { userDownloadService } from '../../api'
import { AppThunkAction } from '../../types/store'
import { DownloadActions } from '../../types/store/download'
import downloadActions from '../actions/downloadActions'

const { clearData, loadDownloadsError, loadDownloadsRequest, loadDownloadsSuccess } = downloadActions

const downloadOperations = {
    loadDownloads: (): AppThunkAction<DownloadActions> => async (dispatch, getState) => {
        dispatch(loadDownloadsRequest())
        
        try {
            const response = await userDownloadService.loadDownloads()
            
            if(response.isSuccess) {
                dispatch(loadDownloadsSuccess(response.result))
            } else {
                dispatch(loadDownloadsError(response.errorMessage))
            }
        } catch(e) {
            dispatch(loadDownloadsError(e.response))
        }
    },

    clearData: (): AppThunkAction<DownloadActions> => async (dispatch, getState) => {
        dispatch(clearData())
    }
}

export default downloadOperations