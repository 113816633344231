import { LicensingActions, LicensingState } from "../../types/store/licensing"

const initialState: LicensingState = {
    products   : {
        data: [],
        filter: {
            sortDirection: 'desc',
            sortField    : 'salesDate',
            page         : 1,
            rowsPerPage  : 5
        },
        rowCount: 0,
        loading: false
    },
    webProducts: {
        data: [],
        loading: false
    }
}

const licensingReducer = (state = initialState, action: LicensingActions): LicensingState => {
    switch (action.type) {
        case ('LICENSING_HANDLE_PRODUCTS_PAGE_CHANGE'): {
            return { 
                ...state,
                products: {
                    ...state.products,
                    filter: {
                        ...state.products.filter,
                        page: action.payload + 1
                    }
                }
            }
        }

        case ('LICENSING_HANDLE_PRODUCTS_ROWS_PER_PAGE_CHANGE'): {
            return { 
                ...state,
                products: {
                    ...state.products,
                    filter: {
                        ...state.products.filter,
                        rowsPerPage: action.payload,
                        page: 1
                    }
                }
            }
        }

        case ('LICENSING_LOAD_PRODUCTS_REQUEST'): {
            return {
                ...state,
                products: {
                    ...state.products,
                    loading: true
                }
            }
        }

        case ('LICENSING_LOAD_PRODUCTS_SUCCESS'): {
            const { applications, rowCount } = action.payload
            return {
                ...state,
                products: {
                    ...state.products,
                    data: applications,
                    rowCount: rowCount,
                    loading: false
                },
            }
        }

        case ('LICENSING_LOAD_PRODUCTS_ERROR'): {
            return {
                ...state,
                products: {
                    ...state.products,
                    loading: false
                }
            }
        }

        case ('LICENSING_LOAD_WEB_PRODUCTS_REQUEST'): {
            return {
                ...state,
                webProducts: {
                    ...state.webProducts,
                    loading: true
                }
            }
        }

        case ('LICENSING_LOAD_WEB_PRODUCTS_SUCCESS'): {
            return {
                ...state,
                webProducts: {
                    ...state.webProducts,
                    data: action.payload,
                    loading: false
                }
            }
        }

        case ('LICENSING_LOAD_WEB_PRODUCTS_ERROR'): {
            return {
                ...state,
                webProducts: {
                    ...state.webProducts,
                    loading: false
                }
            }
        }

        case ('LICENSING_CLEAR_DATA'): {
            return initialState
        }

        case ('LICENSING_PRODUCTS_ORDER'): {
            const { sortDirection, sortField } = action.payload
            return {
                ...state,
                products: {
                    ...state.products,
                    filter: {
                        ...state.products.filter,
                        page: 1,
                        sortDirection: sortDirection,
                        sortField: sortField
                    }
                }
            }
        }

        default:
            return state
    }
}

export default licensingReducer