import React, { useEffect, useState } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from '../../types/store'
import { loginOperations } from '../../store/operations'

import { Box, CircularProgress, Container, Link, Typography } from '@material-ui/core'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import i18n from '../../i18n'

import { carouselService } from '../../api/services/carouselService'
import { CarouselSlide, F3MAppBar, F3MCarousel, F3MIcons, F3MSnackbar, F3MSnackbarState } from '../../components'

import LoginForm from './LoginForm'

import useStyles from './styles'
import { remoteAssistanceService } from '../../api'
import { openOrDownload } from '../../utils'

const mapStateToProps = (state: ApplicationState) => ({
    loginState: state.login
})

const mapDispatchToProps = (dispatch: any) => ({
    handleChange: (v: React.ChangeEvent<HTMLInputElement>) => dispatch(loginOperations.handleChange(v)),
    handleSubmit: (v: React.FormEvent) => dispatch(loginOperations.login(v))
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const Login = (props: Props) => {
    const classes = useStyles()
    const [fetchedCarousel, setFetchedCarousel] = useState<CarouselSlide[]>([])
    const [carousel, setCarousel] = useState<CarouselSlide[]>([])

    const { handleChange, handleSubmit, loginState } = props

    const { username, password, expired } = loginState!

    const [snackbar, setSnackbar] = useState<F3MSnackbarState>({
        open: expired,
        severity: 'info',
        message: i18n.t('login.expiredSessionMessage')
    })

    const getRemAssistExe = async () => {
        const response: any = await remoteAssistanceService.downloadRemoAssistExe()
        openOrDownload(response, 'SuporteF3M.exe')
    }

    const getImage = async (imagePath: string) => {
        const response: any = await carouselService.loadSlideImage(imagePath)

        const { data, headers } = response
        const blob: Blob = new Blob([data], { type: headers['content-type'] })

        return window.URL.createObjectURL(blob)
    }

    const handleError = (message: string) => {
        setSnackbar({ open: true, severity: 'error', message: message })
    }

    useEffect(() => {
        const fetchData = async () => {
            return await carouselService.loadCarouselLogin()
        }
        fetchData()
            .then(res => {
                if (res.isSuccess && res.result) {
                    setFetchedCarousel(res.result)
                }
            }).catch(error => {
                switch (error.message) {
                    case '500':
                        handleError(i18n.t('snackbar.serviceUnavailable'))
                        break
                    default:
                        handleError(i18n.t('snackbar.verifyConnection'))
                }
            })
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            let ordFinalCar: CarouselSlide[] = []
            await Promise.all(fetchedCarousel.map(async el => {
                const imagePath: string = await getImage(el.imagePath)
                const slide: CarouselSlide = { ...el, imagePath: imagePath }
                ordFinalCar[el.order ? el.order - 1 : 0] = slide
            }))
            setCarousel([...ordFinalCar])
        }
        fetchData()
    }, [fetchedCarousel])

    return (
        <Container className={classes.root} disableGutters maxWidth={false}>
            <F3MAppBar />
            <Container className={classes.container} component='div' disableGutters maxWidth={false}>
                {carousel.length > 0
                    ? <F3MCarousel carouselSlides={carousel} />
                    : <Box className={classes.loading}>
                        <CircularProgress />
                    </Box>
                }
                <Container className={classes.paper} disableGutters>
                    <Typography variant='h1' color='primary' gutterBottom>
                        {i18n.t('login.clientPortal')}
                    </Typography>
                    <Typography variant='h3' color='primary' gutterBottom>
                        {i18n.t('login.greeting')}
                    </Typography>
                    <LoginForm
                        username={username}
                        password={password}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                    />
                </Container>
            </Container>
            <Container className={classes.paperRemoteAssist} disableGutters maxWidth={false}>
                <Link
                    href='#'
                    onClick={getRemAssistExe}
                    align='center'
                    color='textSecondary'
                    underline='none'
                    variant='body2'
                >
                    {i18n.t('login.remoteAssistance')}
                </Link>
            </Container>
            <F3MIcons />
            <F3MSnackbar
                setSnackbar={setSnackbar}
                snackbar={snackbar}
            />
        </Container>
    )

}

export default connector(Login)