import { UserDownloadResponse } from '../../types/services/userDownload'
import { ClearData, LoadDownloadsError, LoadDownloadsRequest, LoadDownloadsSuccess } from '../../types/store/download'

const downloadActions = {
    loadDownloadsRequest: (): LoadDownloadsRequest => {
        return {type: 'DOWNLOAD_LOAD_DOWNLOADS_REQUEST'}
    },

    loadDownloadsSuccess: (installations: UserDownloadResponse[]): LoadDownloadsSuccess => {
        return {type: 'DOWNLOAD_LOAD_DOWNLOADS_SUCCESS', payload: installations}
    },

    loadDownloadsError: (error: string): LoadDownloadsError => {
        return {type: 'DOWNLOAD_LOAD_DOWNLOADS_ERROR', payload: error}
    },

    clearData: (): ClearData => {
        return {type: 'DOWNLOAD_CLEAR_DATA'}
    }
}

export default downloadActions