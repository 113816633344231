import React from 'react'
import { SvgIcon } from '@material-ui/core'

export const ServicesIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g>
                <rect x="7.6" y="12.4" width="1" height="1"/>
                <rect x="12.9" y="12.4" width="1" height="1"/>
                <path d="M31.2,18.2l-2-0.3c-0.1-0.3-0.2-0.6-0.3-0.8l1.2-1.6c0.2-0.2,0.1-0.5,0-0.7l-2.2-2.2c-0.1-0.1-0.2-0.1-0.3-0.1
                    v-5c1-0.8,1.6-2,1.6-3.2c0-1.6-0.9-3.1-2.4-3.8c-0.3-0.1-0.6,0-0.7,0.2c0,0.1-0.1,0.1-0.1,0.2v2.9l-1,0.3l-1-0.3V0.9
                    c0-0.3-0.2-0.5-0.5-0.5c-0.1,0-0.2,0-0.2,0c-1.5,0.7-2.4,2.1-2.4,3.7c0,1.3,0.6,2.5,1.6,3.2v3.5h-1.6c-0.3,0-0.5,0.2-0.5,0.4
                    l-0.3,2.4l1,0.1l0.3-1.9h2.2l0.3,1.9c0,0.2,0.2,0.4,0.4,0.4c0.4,0.1,0.9,0.3,1.3,0.5c0.2,0.1,0.4,0.1,0.6,0l1.5-1.2l1.6,1.6
                    l-1.2,1.5c-0.1,0.2-0.1,0.4,0,0.6c0.2,0.4,0.4,0.8,0.5,1.2c0.1,0.2,0.2,0.3,0.4,0.4l1.9,0.3v2.2l-1.9,0.3c-0.2,0-0.4,0.2-0.4,0.4
                    c-0.1,0.4-0.3,0.9-0.5,1.2c-0.1,0.2-0.1,0.4,0,0.6l1.2,1.5l-1.6,1.6l-1.5-1.2c-0.2-0.1-0.4-0.1-0.6,0c-0.4,0.2-0.8,0.4-1.3,0.5
                    c-0.2,0.1-0.3,0.2-0.4,0.4l-0.3,1.9h-2.2v-2.2c0-0.5-0.1-1.1-0.2-1.6c2.5,0.7,5.2-0.7,5.9-3.2c0.7-2.5-0.7-5.1-3.2-5.9
                    c-2.5-0.7-5.2,0.7-5.9,3.2c-0.2,0.7-0.2,1.5-0.1,2.2c0,0,0,0,0,0l-4.2-1.7v-0.8c0.7-0.4,1.3-0.8,1.8-1.4h1.3c0.9,0,1.6-0.7,1.6-1.6
                    v-1.1c0.6-0.2,1-0.8,1-1.5v-1.6c0-0.7-0.4-1.2-1-1.5V9.3c0-4-3.3-7.3-7.3-7.3c-4,0-7.3,3.3-7.3,7.3v0.6c-0.6,0.2-1,0.8-1,1.5V13
                    c0,0.9,0.7,1.6,1.6,1.6h1c0,0,0.1,0,0.1,0c0.3,1.7,1.4,3.2,3,4v0.8L4,21c-2.2,0.9-3.6,3-3.6,5.3v4.8c0,0.3,0.2,0.5,0.5,0.5h19.8
                    c0.3,0,0.5-0.2,0.5-0.5v-1.6h2.6c0.3,0,0.5-0.2,0.5-0.4l0.3-2c0.3-0.1,0.6-0.2,0.8-0.3l1.6,1.2c0.2,0.2,0.5,0.1,0.7,0l2.2-2.2
                    c0.2-0.2,0.2-0.5,0-0.7l-1.2-1.6c0.1-0.3,0.2-0.5,0.3-0.8l2-0.3c0.3,0,0.4-0.3,0.4-0.5v-3.1C31.7,18.4,31.5,18.2,31.2,18.2z
                    M27.2,6.2c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.1-0.2,0.3-0.2,0.4V13l-1,0.7c0,0-0.1,0-0.1,0V7.3h-1v4.1l0,0c0-0.3-0.3-0.4-0.5-0.4
                    h-0.5V7.1c0-0.2-0.1-0.3-0.2-0.4c-1.4-1-1.8-2.9-0.8-4.3c0.1-0.2,0.3-0.4,0.5-0.5v2.3c0,0.2,0.1,0.4,0.4,0.5l1.6,0.5
                    c0.1,0,0.2,0,0.3,0l1.6-0.5C26.8,4.6,27,4.4,27,4.1V1.8C28.2,3,28.4,4.9,27.2,6.2z M15.5,10.4c-0.5,0-1-0.2-1.3-0.6l-0.9-1.2
                    c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.3,0-0.4,0.1l-1.8,1.4c-0.5,0.4-1,0.6-1.6,0.6H6.1V9.9c0-2.6,2.1-4.7,4.7-4.7
                    c2.6,0,4.7,2.1,4.7,4.7V10.4z M17,15.6c0,0.3-0.2,0.5-0.5,0.5h-0.6c0.3-0.5,0.4-1,0.5-1.6c0,0,0.1,0,0.1,0H17V15.6z M18.1,11.4V13
                    c0,0.3-0.2,0.5-0.5,0.5h-1v-2.6h1C17.9,10.9,18.1,11.1,18.1,11.4z M5,13.5H4c-0.3,0-0.5-0.2-0.5-0.5v-1.6c0-0.3,0.2-0.5,0.5-0.5h1
                    V13.5z M5,9.9H4.5V9.3c0-3.4,2.8-6.2,6.3-6.2c3.5,0,6.3,2.8,6.3,6.2v0.5h-0.5c0-3.2-2.6-5.7-5.7-5.7C7.6,4.1,5,6.7,5,9.9z
                    M7.1,16.4c-0.7-0.8-1-1.9-1-2.9v-2.1h3.1c0.8,0,1.6-0.3,2.3-0.8l1.3-1.1l0.6,0.8c0.5,0.7,1.3,1,2.1,1v2.1c0,0.9-0.3,1.8-0.8,2.6
                    h-3.9v1h2.9C11.7,18.7,8.8,18.4,7.1,16.4z M12.3,19v0.7c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6V19c0.5,0.1,1,0.2,1.6,0.2
                    C11.3,19.2,11.8,19.1,12.3,19z M17,30.6v-5.7h-1v5.7H5.6v-5.7h-1v5.7H1.4v-4.3c0-1.9,1.2-3.6,3-4.3l3.9-1.6
                    c0.4,1.4,1.8,2.2,3.2,1.8c0.9-0.2,1.5-0.9,1.8-1.8l3.9,1.6c1.8,0.7,3,2.4,3,4.3v4.3H17z M20.3,23.3c-0.4-0.6-0.8-1.1-1.4-1.5
                    c-0.2-0.5-0.4-1-0.4-1.6c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.6c0,2-1.6,3.7-3.7,3.7C21.6,23.9,20.9,23.7,20.3,23.3z"/>
                <rect x="21.7" y="13" width="1" height="1"/>
                <rect x="21.7" y="26.5" width="1" height="1"/>
                <polygon points="27.1,25.7 27.8,25 27.1,24.3 26.3,25 	"/>
                <rect x="28.5" y="19.7" width="1" height="1"/>
                <polygon points="27.1,14.7 26.3,15.5 27.1,16.2 27.8,15.5 	"/>
            </g>
        </SvgIcon>
    )
}