import { makeStyles, createStyles } from '@material-ui/core'


const useStyles = makeStyles(() => createStyles({
    goBack: {
        color: '#989898'
    },
    action: {
        boxShadow: 'none !important'
    }
}))

export default useStyles