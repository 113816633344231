import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ptPT from './resources/pt-PT.json'

i18n.use(initReactI18next).init({
    interpolation: { 
        escapeValue: false 
    },
    lng: 'pt-PT',
    fallbackLng: 'pt-PT',
    resources: {
        'pt-PT': {
            translation: ptPT
        }
    }
})

export default i18n