import React from 'react'

import { Box } from '@material-ui/core'

import { Bar } from 'react-chartjs-2'
import { LinearTickOptions } from 'chart.js'

const F3MChart = (props: { dataset: any, height?: number, noMinWidth?: boolean }) => {

    const {
        dataset,
        height,
        noMinWidth
    } = props

    return (
        <Box overflow='auto'>
            <Box height={height ? height : 400} marginTop={3} position='relative' minWidth={noMinWidth ? '' : 600}>
                <Bar
                    data={dataset}
                    options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    precision: 0
                                } as LinearTickOptions
                            }]
                        }
                    }}
                />
            </Box>
        </Box>
    )
}

export default F3MChart