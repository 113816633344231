import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { Typography } from '@material-ui/core'

import TableProducts from './TableProducts'

import { F3MBreadcrumbs, F3MDownload, F3MDataGrid, F3MDataGridColumnProps, F3MDataGridRowData, F3MCard, F3MDialog, F3MSnackbar, F3MSnackbarState } from '../../../../components'

import { ApplicationState } from '../../../../types/store'
import { licensingOperations } from '../../../../store/operations'

import i18n from '../../../../i18n'
import { isDefined } from '../../../../utils'
import { licenseService } from '../../../../api'

const mapStateToProps = (state: ApplicationState) => ({
    licensingState: state.licensing
})

const mapDispatchToProps = (dispatch: any) => ({
    loadWebProducts: () => dispatch(licensingOperations.loadWebProducts()),
    clearData: () => dispatch(licensingOperations.clearData()),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const Licensing = (props: Props) => {
    const [snackbar, setSnackbar] = useState<F3MSnackbarState>({
        open: false,
        severity: 'info',
        message: ''
    })

    const { webProducts } = props.licensingState!

    const [modal, setModal] = useState<boolean>(false)

    useEffect(() => {
        // On Mount
        props.loadWebProducts()

        // On Unmount
        return () => {
            props.clearData()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleModal = () => {
        setModal(!modal)
    }

    const webProductColumns: F3MDataGridColumnProps[] = [
        { field: 'name', headerName: i18n.t('common.application'), width: 85, sortable: false, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'download', headerName: i18n.t('product.download'), width: 15, sortable: false, type: 'node', align: 'center', headerAlign: 'center' }
    ]

     const handleError = (message: string) => {
        setSnackbar({ open: true, severity: 'error', message: message })
    }

    const webProductData: F3MDataGridRowData[] = webProducts.data &&  webProducts.data.length > 0 ? webProducts.data.map((r, i) => (
        {
            id: r.code,
            name: r.name,
            download: <F3MDownload handleError={handleError} filename={`${i18n.t('product.webProductFile')}.lic`} request={() => licenseService.downloadWebProductLicense(r.code)} />
        }
    )) : []
   
    return (
        <>
            <F3MBreadcrumbs path={i18n.t("paths.licensing.breadcrumb")} />
            <TableProducts handleError={handleError} />
            <F3MCard loading={webProducts.loading}>
                <Typography variant='h5' color='primary' gutterBottom>{i18n.t('product.webProducts')}</Typography>
                <F3MDataGrid columns={webProductColumns} rows={webProductData} loading={webProducts.loading} pagination={false} />
            </F3MCard>
            <F3MDialog
                open={modal}
                variant='dialog'
                newOnClose={handleModal}
                DialogTitleProps={{ children: i18n.t('dialogs.license') }}
                DialogContentProps={{}}
            />
            <F3MSnackbar
                setSnackbar={setSnackbar}
                snackbar={snackbar}
            />
        </>
    )
}

export default connector(Licensing)