import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Checkbox, FormControlLabel } from '@material-ui/core'

import useStyles from './styles'

import { ContentPropsDownCatUpdtCreate } from '../../../F3MAddUpdt/types'
import i18n from '../../../../i18n'
import { backofficeService } from '../../../../api'
import { BusinessArea } from '../../../../types/services/backoffice'

const F3MBusinessArea = (props: ContentPropsDownCatUpdtCreate & { handleError: (message: string) => void, setOpen: Dispatch<SetStateAction<boolean>> }) => {

    const classes = useStyles()

    const {
        addOrUpdt,
        setAddOrUpdt,
        handleError,
        setOpen
    } = props

    const [busAreasToPresent, setBusAreasToPresent] = useState<BusinessArea[]>([])
    const [busAreasSelected, setBusAreasSelected] = useState<BusinessArea[]>(addOrUpdt.businessAreas ? addOrUpdt.businessAreas : [])

    const loadBusinessAreas = async () => {
        try {
            const response = await backofficeService.loadBusinessAreas()
            if (response.isSuccess) {
                setBusAreasToPresent(response.result)
            } else {
                handleError(i18n.t('error.unexpectedError'))
                setOpen(false)
            }
        }
        catch (e) {
            handleError(i18n.t('error.unexpectedError'))
            setOpen(false)
        }
    }

    const handleChangeBusAreas = (e: ChangeEvent<HTMLInputElement>) => {
        if (isSelected(e.target.id)) {
            const newBusAreas: BusinessArea[] = busAreasSelected.filter(x => x.id !== e.target.id)
            setBusAreasSelected(newBusAreas)
            setAddOrUpdt(prevState => ({ ...prevState, businessAreas: newBusAreas }))
        }
        else {
            const newBusArea = {
                id: e.target.id,
                code: e.target.name,
                name: e.target.labels ? e.target.labels[0].innerText : ''
            }

            setAddOrUpdt(prevState => ({ ...prevState, businessAreas: [...busAreasSelected, newBusArea] }))
            setBusAreasSelected(prevState => [...prevState, newBusArea])
        }
    }

    const isSelected = (id: string) => {
        return busAreasSelected.some(busArea => busArea.id === id)
    }

    useEffect(() => {
        loadBusinessAreas()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={classes.root}>
            {busAreasToPresent.map((busArea, index) => (
                <FormControlLabel
                    key={index.toString()}
                    control={
                        <Checkbox
                            checked={isSelected(busArea.id)}
                            onChange={(e) => handleChangeBusAreas(e)}
                            id={busArea.id}
                            name={busArea.code}
                            color='primary'
                        />
                    }
                    label={busArea.name}
                />
            ))}
        </div>
    )
}

export default F3MBusinessArea