import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    tableCellHead: {
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: theme.typography.body1.fontSize
    },
    hidden: {
        display: 'none'
    },
    filter: {
        transition: theme.transitions.create(['max-height'], {
            duration: theme.transitions.duration.short,
            easing: 'ease-in-out'
        }),
        maxHeight: 1000,
        overflow: 'hidden'
    },
    filterHidden: {
        maxHeight: 0
    },
    icons: {
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    buttonSeeMore: {
        alignSelf: 'center',
        textAlign: 'right'
    },
    textButtonSeeMore: {
        color: theme.palette.primary.main,
        fontSize: '12px',
        maxWidth: '69px',
        '@media (max-width:768px)': {
            maxWidth: '112px'
        }
    }
}))

export default useStyles