import { request } from '../axios'
import { CONFIG, ENDPOINTS } from '../../constants'
import { TokenCreateRequest, TokenCreateResponse, TokenRefreshRequest, TokenRefreshResponse } from '../../types/services/auth'

export const authService = {
    tokenCreate: async (data: TokenCreateRequest) => {
        const response = await request<TokenCreateResponse>({
            method : 'POST',
            url    : CONFIG.API_URL + ENDPOINTS.TOKEN_CREATE,
            data   : data
        })

        return response
    },

    tokenRefresh: async (data: TokenRefreshRequest) => {
        const response = await request<TokenRefreshResponse>({
            method : 'POST',
            url    : CONFIG.API_URL + ENDPOINTS.TOKEN_REFRESH,
            data   : data
        })

        return response
    },

    tokenRevoke: async () => {
        const response = await request<string>({
            method : 'POST',
            url    : CONFIG.API_URL + ENDPOINTS.TOKEN_REVOKE
        })

        return response
    }
}