import { STORAGE } from '../../constants'
import { LoginActions, LoginState } from '../../types/store/login'
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../../utils'

const initialState: LoginState = {
    username     : getLocalStorage(STORAGE.USERNAME),
    password     : '',
    token        : getLocalStorage(STORAGE.ACCESS_TOKEN),
    tokenRefresh : getLocalStorage(STORAGE.REFRESH_TOKEN),
    authenticated: getLocalStorage(STORAGE.ACCESS_TOKEN) !== '',
    expiresOn    : getLocalStorage(STORAGE.EXPIRES_ON),
    expired      : false,
    errorMessage : '',
    hasError     : false,
    carousel     : []
}

const loginReducer = (state = initialState, action: LoginActions): LoginState => {
    switch (action.type) {
        case ('LOGIN_HANDLE_CHANGE'): {
            const {name, value} = action.payload

            return { 
                ...state, 
                [name]: value
            }
        }

        case ('LOGIN_REQUEST'): {
            return state
        }

        case ('LOGIN_SUCCESS'): {
            const {accessToken, tokenRefresh, expiration} = action.payload
            
            setLocalStorage(STORAGE.USERNAME, state.username)
            setLocalStorage(STORAGE.ACCESS_TOKEN, accessToken)
            setLocalStorage(STORAGE.REFRESH_TOKEN, tokenRefresh)
            setLocalStorage(STORAGE.EXPIRES_ON, expiration)
            
            return {
                ...state,
                token        : accessToken,
                tokenRefresh : tokenRefresh,
                authenticated: true,
                expiresOn    : expiration.toString(),
                expired      : false
            }
        }

        case ('LOGIN_ERROR'): {
            return {
                ...state,
                hasError     : true,
                errorMessage : action.payload[0],
                authenticated: false,
                password     : ''
            }
        }

        case ('LOGIN_LOGOUT_REQUEST'): {
            return state
        }

        case ('LOGIN_LOGOUT_SUCCESS'): {
            removeLocalStorage(STORAGE.ACCESS_TOKEN)
            removeLocalStorage(STORAGE.REFRESH_TOKEN)
            removeLocalStorage(STORAGE.EXPIRES_ON)

            return {
                ...state,
                password     : '',
                token        : '',
                tokenRefresh : '',
                authenticated: false,
                expiresOn    : '',
                expired      : false
            }
        }

        case ('LOGIN_LOGOUT_ERROR'): {
            return state
        }

        case ('LOGIN_REFRESH_TOKEN_REQUEST'): {
            return state
        }

        case ('LOGIN_REFRESH_TOKEN_SUCCESS'): {
            const {accessToken, tokenRefresh, expiration} = action.payload

            setLocalStorage(STORAGE.ACCESS_TOKEN, accessToken)
            setLocalStorage(STORAGE.REFRESH_TOKEN, tokenRefresh)
            setLocalStorage(STORAGE.EXPIRES_ON, expiration)
            
            return {
                ...state,
                token        : accessToken,
                tokenRefresh : tokenRefresh,
                authenticated: true,
                expiresOn    : expiration,
                expired      : false
            }
        }

        case ('LOGIN_REFRESH_TOKEN_ERROR'): {
            removeLocalStorage(STORAGE.ACCESS_TOKEN)
            removeLocalStorage(STORAGE.REFRESH_TOKEN)
            removeLocalStorage(STORAGE.EXPIRES_ON)

            return {
                ...state,
                authenticated: false,
                password     : '',
                token        : '',
                tokenRefresh : '',
                expiresOn    : '',
                expired      : true
            }
        }

        case ('LOGIN_LOAD_CAROUSEL_REQUEST'): {
            return state
        }

        case ('LOGIN_LOAD_CAROUSEL_SUCCESS'): {
            return {
                ...state,
                carousel: action.payload
            }
        }

        case ('LOGIN_LOAD_CAROUSEL_ERROR'): {
            return state
        }

        case ('LOGIN_CLOSE_EXPIRED_SNACKBAR'): {
            return {
                ...state,
                expired: false
            }
        }

        case ('LOGIN_SET_USER'): {
            return {
                ...state,
                user: action.payload
            }
        }

        default:
            return state
    }
}

export default loginReducer