import React from 'react'

import { Container } from '@material-ui/core'

import useStyles from './styles'
import { F3MButton } from '../../../../components'
import { useHistory } from 'react-router-dom'
import i18n from '../../../../i18n'

const NotFound = () => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <div className={classes.main}>
            <div className={classes.background}>
                {i18n.t('errorPage.errorCode')}
            </div>
            <Container maxWidth='sm' className={classes.content}>
                <div className={classes.title}>
                    {i18n.t('errorPage.errorCodeMessage')}
                </div>
                <div className={classes.innerContent}>
                    <div className={classes.firstLine}>
                        {i18n.t('errorPage.errorWarning')}
                    </div>
                    <div className={classes.seccondLine}>
                        {i18n.t('errorPage.errorReason')}
                    </div>
                    <F3MButton color='warning' fullWidth={false} onClick={() => history.goBack()}>
                        {i18n.t('errorPage.goBack')}
                    </F3MButton>
                </div>
            </Container>
        </div>
    )
}

export default NotFound