import React, { Suspense, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import clsx from 'clsx'

import { Typography, Box, Grid, MenuItem, IconButton, Tooltip, TableRow, TableCell, Menu, LinearProgress, Button } from '@material-ui/core'

import FilterListIcon from '@material-ui/icons/FilterList'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { F3MBreadcrumbs, F3MDatePicker, F3MSelect, F3MDataGrid, F3MDataGridColumnProps, F3MDataGridRowData, F3MCard, F3MSnackbar, F3MSnackbarState } from '../../../../components'

import i18n from '../../../../i18n'
import { ApplicationState } from '../../../../types/store'
import { incidentOperations } from '../../../../store/operations'

import { useWindowSize } from '../../../../hooks'
import theme from '../../../../assets/theme'

import { isDefined, openOrDownload } from '../../../../utils'

import { incidentsService } from '../../../../api/services/incidentsService'

import useStyles from './styles'

import { tableAsExcel } from '../../../../utils/tableAsExcel'

const mapStateToProps = (state: ApplicationState) => ({
    incidentsState: state.incidents
})

const mapDispatchToProps = (dispatch: any) => ({
    handleFilterChange: (n: string, v: any) => dispatch(incidentOperations.handleFilterChange(n, v)),
    handleChangePage: (e: any, p: number) => dispatch(incidentOperations.handleChangePage(e, p)),
    handleChangeRowsPerPage: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => dispatch(incidentOperations.handleChangeRowsPerPage(e)),
    loadIncidents: () => dispatch(incidentOperations.loadIncidents()),
    clearData: () => dispatch(incidentOperations.clearData()),
    loadContracts: () => dispatch(incidentOperations.loadContracts()),
    loadApplications: () => dispatch(incidentOperations.loadApplications()),
    toggleFilter: () => dispatch(incidentOperations.toggleFilter()),
    setLoading: (loading: boolean) => dispatch(incidentOperations.setLoading(loading)),
    order: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => dispatch(incidentOperations.order(e))
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const Incidents = (props: Props) => {
    const [snackbar, setSnackbar] = useState<F3MSnackbarState>({
        open: false,
        severity: 'info',
        message: ''
    })

    const classes = useStyles()
    const { incidents } = props.incidentsState!
    const size = useWindowSize()
    const { sm, md } = theme.breakpoints.values

    const [menu, setMenu] = React.useState<null | HTMLElement>(null)
    const [initDateFilter] = useState<string>(incidents.filter.startDate)
    const [endDateFilter,] = useState<string>(incidents.filter.endDate)
    const [initContractFilter] = useState<string>(incidents.filter.contract)
    const [initApplicationFilter] = useState<string>(incidents.filter.application)

    const menuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenu(event.currentTarget)
    }

    const menuClose = () => {
        setMenu(null)
    }

    const columns: F3MDataGridColumnProps[] = [
        { field: 'date', headerName: i18n.t('common.date'), width: 10, sortable: true, type: 'date', align: 'right', headerAlign: 'right' },
        { field: 'contract', headerName: i18n.t('incidents.contractNumber'), width: 15, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'consultant', headerName: i18n.t('incidents.consultant'), width: 15, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'assistanceNumber', headerName: i18n.t('incidents.assistanceNumber'), width: 15, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'application', headerName: i18n.t('common.application'), width: 10, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'numberOfHours', headerName: i18n.t('common.numberHours'), width: 10, sortable: true, type: 'string', align: 'right', headerAlign: 'right' },
        { field: 'debited', headerName: i18n.t('incidents.debited'), width: 10, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'requestedBy', headerName: i18n.t('incidents.requestedBy'), width: 15, sortable: true, type: 'string', align: 'left', headerAlign: 'left' }
    ]

    const rows: F3MDataGridRowData[] = isDefined(incidents.data) ? incidents.data.map((r, i) => (
        {
            id: r.id,
            date: r.date,
            contract: isDefined(r.contractDescription) ? r.contractDescription + ' (' + r.contractNumber + ')' : '',
            consultant: r.consultant,
            assistanceNumber: r.assistanceNumber,
            application: r.application,
            numberOfHours: r.numberOfHours,
            debited: r.debited ? i18n.t('common.yes') : i18n.t('common.no'),
            requestedBy: r.requestedBy
        }
    )) : []

    const totals =
        <>
            <TableRow>
                <TableCell colSpan={6}></TableCell>
                <TableCell>
                    <Typography color='primary' style={{ fontWeight: 500, fontSize: 'inherit' }}>{i18n.t('common.totaldebited')}</Typography>
                </TableCell>
                <TableCell align='center'>
                    <Typography color='textPrimary' style={{ fontWeight: 700, fontSize: 'inherit' }}>{incidents.totalDebited}</Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={6}></TableCell>
                <TableCell>
                    <Typography color='primary' style={{ fontWeight: 500, fontSize: 'inherit' }}>{i18n.t('common.total')}</Typography>
                </TableCell>
                <TableCell align='center'>
                    <Typography color='textPrimary' style={{ fontWeight: 700, fontSize: 'inherit' }}>{incidents.total}</Typography>
                </TableCell>
            </TableRow>
        </>

    const handleFilterChange = (name: string) => (value: any) => {
        if (name === 'contract' || name === 'application') {
            props.handleFilterChange(name, value.target.value)
        } else {
            props.handleFilterChange(name, value)
        }
        props.loadIncidents()
    }

    const clearFilter = () => {
        props.handleFilterChange('contract', initContractFilter)
        props.handleFilterChange('application', initApplicationFilter)
        props.handleFilterChange('startDate', initDateFilter)
        props.handleFilterChange('endDate', endDateFilter)
        props.loadIncidents()
    }

    const order = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        props.order(e)
        props.loadIncidents()
    }

    const handleChangePage = (e: any, p: number) => {
        props.handleChangePage(e, p)
        props.loadIncidents()
    }

    const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.handleChangeRowsPerPage(e)
        props.loadIncidents()
    }

    const exportTableAsExcel = async () => {
        menuClose()

        const response = await incidentsService.getExcel({ ...incidents.filter, rowsPerPage: null })

        tableAsExcel.downloadExcel(response, i18n.t('fileNames.excel.incidents'))
    }

    const handleError = (message: string) => {
        setSnackbar({ open: true, severity: 'error', message: message })
    }

    const handleClick = async (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        props.setLoading(true)

        const response = await incidentsService.getIncidentPDF(e.currentTarget.id)
        try {
            if (response && response.status === 200) {
                openOrDownload(response)
            } else {
                handleError(i18n.t('error.unexpectedError'))
            }
        }
        catch (e) {
            handleError(i18n.t('error.unexpectedError'))
        }
        finally {
            props.setLoading(false)
        }
    }

    // TODO: Export table as PDF
    // const exportTableAsPDF = () => {
    //     menuClose()
    //     console.log(incidents.data)
    // }

    useEffect(() => {
        // On Mount
        props.loadIncidents()
        props.loadContracts()
        props.loadApplications()

        // On Unmount
        return () => {
            props.clearData()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Suspense fallback={LinearProgress}>
            <F3MBreadcrumbs path={i18n.t('paths.incidents.breadcrumb')} />
            <F3MCard loading={incidents.loading}>
                <Box marginBottom={incidents.isFilterOpen ? 0 : 4}>
                    <Grid container alignItems='center'>
                        <Grid item xs={8} sm={10}>
                            <Typography variant='h5' color='primary'>{i18n.t('menus.services.incidents')}</Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={4}
                            sm={2}
                            direction='row'
                            justify='flex-end'
                        >
                            <Tooltip title={i18n.t('filter.filters')!} arrow>
                                <IconButton onClick={props.toggleFilter}>
                                    <FilterListIcon className={classes.icons} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={i18n.t('common.more')!} arrow>
                                <IconButton onClick={menuOpen}>
                                    <MoreVertIcon className={classes.icons} />
                                </IconButton>
                            </Tooltip>

                            <Menu
                                keepMounted
                                anchorEl={menu}
                                open={Boolean(menu)}
                                onClose={menuClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                            >
                                <MenuItem onClick={exportTableAsExcel}>{i18n.t('common.exportExcel')}</MenuItem>
                                {/* <MenuItem onClick={exportTableAsPDF}>{i18n.t('common.exportPDF')}</MenuItem> */}
                            </Menu>
                        </Grid>
                    </Grid>
                </Box>

                <Box paddingRight={size.width! < md ? 0 : 5}>
                    <Grid
                        container
                        className={clsx(classes.filter, !incidents.isFilterOpen ? classes.filterHidden : '')}
                        spacing={size.width! < sm ? 2 : 5}
                        justify='flex-end'
                    >
                        <Grid item xs={6} sm={3} md={2}>
                            <F3MDatePicker
                                label={i18n.t('filter.startDate')}
                                name='startDate'
                                value={incidents.filter.startDate!}
                                handleChange={handleFilterChange('startDate')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} md={2}>
                            <F3MDatePicker
                                label={i18n.t('filter.endDate')}
                                name='endDate'
                                value={incidents.filter.endDate!}
                                handleChange={handleFilterChange('endDate')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} md={2}>
                            <F3MSelect
                                labelId='contractLabel'
                                label={i18n.t('common.contract')}
                                name='contract'
                                value={incidents.filter.contract}
                                onChange={handleFilterChange('contract')}
                                items={incidents.contracts}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} md={2}>
                            <F3MSelect
                                labelId='applicationLabel'
                                label={i18n.t('common.application')}
                                name='application'
                                value={incidents.filter.application}
                                onChange={handleFilterChange('application')}
                                items={incidents.applications.map((elem) => ({ 'id': elem.applicationId, 'label': elem.applicationName }))}
                            />
                        </Grid>
                        <Grid className={classes.buttonSeeMore} item xs={12} sm={3} md={1}>
                            <Button className={classes.textButtonSeeMore} onClick={clearFilter}>
                                {i18n.t('common.clearFilters')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <F3MDataGrid
                    columns={columns}
                    rows={rows}
                    extraRows={totals}
                    hover={true}
                    loading={incidents.loading}
                    rowClick={handleClick}
                    sortField={incidents.filter.sortField}
                    sortDirection={incidents.filter.sortDirection}
                    sortChange={order}
                    pagination={true}
                    paginationMode='server'
                    rowCount={incidents.rowCount}
                    pageSize={incidents.filter.rowsPerPage ? incidents.filter.rowsPerPage : 0}
                    page={incidents.filter.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <F3MSnackbar
                    setSnackbar={setSnackbar}
                    snackbar={snackbar}
                />
            </F3MCard>
        </Suspense>

    )
}

export default connector(Incidents)