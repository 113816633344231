import React from 'react'

import { TextField } from '@material-ui/core'

import { isDefined } from '../../utils'

import { F3MTextFieldProps } from './types'

import useStyles from './styles'

const F3MTextField = (props: F3MTextFieldProps) => {

    let textFieldProps: F3MTextFieldProps = {
        // Default NOT AUTOCOMPLETE
        autoComplete: isDefined(props.autoComplete) ? props.autoComplete : undefined,

        // Default NOT AUTOFOCUS
        autoFocus: isDefined(props.autoFocus) ? props.autoFocus : false,

        // Default NOT BOLD
        bold: isDefined(props.bold) ? props.bold : false,

        // CHILDREN
        children: props.children,

        // CLASSNAME
        className: props.className,

        // Default COLOR PRIMARY
        color: isDefined(props.color) ? props.color : 'primary',

        // Default DEFAULT VALUE UNDEFINED
        defaultValue: isDefined(props.defaultValue) ? props.defaultValue : undefined,

        // Default NOT DISABLED
        disabled: isDefined(props.disabled) ? props.disabled : false,

        // Default NOT ERROR
        error: isDefined(props.error) ? props.error : false,

        // Default NOT FOCUSED
        // focused: isDefined(props.focused) ? props.focused : false,

        // FORM HELPER TEXT PROPS
        FormHelperTextProps: props.FormHelperTextProps,

        // Default FULLWIDTH
        fullWidth: isDefined(props.fullWidth) ? props.fullWidth : true,

        // HELPER TEXT
        helperText: props.helperText,

        // ID
        id: props.id,

        // INPUT LABEL PROPS
        InputLabelProps: props.InputLabelProps,

        // INPUT REF
        inputRef: props.inputRef,

        // INPUT PROPS
        InputProps: props.InputProps,

        // LABEL
        label: props.label,

        // Default MARGIN NORMAL
        margin: isDefined(props.margin) ? props.margin : 'normal',

        // Default NOT MULTILINE
        multiline: isDefined(props.multiline) ? props.multiline : false,

        // NAME
        name: props.name,

        // ONCHANGE
        onChange: props.onChange,

        // PLACEHOLDER
        placeholder: props.placeholder,

        // Default NOT REQUIRED
        required: isDefined(props.required) ? props.required : false,

        // ROWS
        rows: props.rows,

        // ROWSMAX
        rowsMax: props.rowsMax,

        // Default SIZE SMALL
        size: isDefined(props.size) ? props.size : 'small',

        // Default TYPE TEXT
        type: isDefined(props.type) ? props.type : 'text',

        // VALUE
        value: props.value,

        // Default VARIANT OUTLINED
        variant: isDefined(props.variant) ? props.variant : 'outlined'
    }

    const classes = useStyles(textFieldProps)

    return (
        <TextField
            autoComplete        = {textFieldProps.autoComplete}
            autoFocus           = {textFieldProps.autoFocus}
            className           = {textFieldProps.className}
            defaultValue        = {textFieldProps.defaultValue}
            disabled            = {textFieldProps.disabled}
            error               = {textFieldProps.error}
            // focused             = {textFieldProps.focused}
            FormHelperTextProps = {textFieldProps.FormHelperTextProps}
            fullWidth           = {textFieldProps.fullWidth}
            helperText          = {textFieldProps.helperText}
            id                  = {textFieldProps.id}
            InputLabelProps     = {{
                ...textFieldProps.InputLabelProps, 
                classes: {
                    root    : classes.inputLabelRoot, // standard
                    outlined: classes.inputLabelOutlined,
                    filled  : classes.inputLabelFilled,
                    disabled: classes.inputLabelDisabled,
                    focused : classes.inputLabelFocused,
                }
            }}
            inputRef            = {textFieldProps.inputRef}
            InputProps          = {{
                ...textFieldProps.InputProps,
                classes: {
                    root    : classes.inputRoot,
                    disabled: classes.inputRootDisabled,
                    focused : classes.focused
                }
            }}
            label               = {textFieldProps.label}
            margin              = {textFieldProps.margin}
            multiline           = {textFieldProps.multiline}
            name                = {textFieldProps.name}
            placeholder         = {textFieldProps.placeholder}
            required            = {textFieldProps.required}
            rows                = {textFieldProps.rows}
            rowsMax             = {textFieldProps.rowsMax}
            select              = {false}
            size                = {textFieldProps.size}
            type                = {textFieldProps.type}
            variant             = {textFieldProps.variant as any}
            onBlur              = {textFieldProps.onBlur}
            onChange            = {textFieldProps.onChange}
            onFocus             = {textFieldProps.onFocus}
            onKeyDown           = {textFieldProps.onKeyDown}
            onKeyUp             = {textFieldProps.onKeyUp}
        >
            {textFieldProps.children}
        </TextField>
    )
}

export default F3MTextField