import React from 'react'
import { Link } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'

import { Container, Grid, Typography, Link as MuiLink } from '@material-ui/core'

import { F3MIcons } from '..'

import i18n from '../../i18n'
import { ROUTES } from '../../constants'

import { useWindowSize } from '../../hooks'
import theme from '../../assets/theme'

import useStyles from './styles'

import { ApplicationState } from '../../types/store'
import { openOrDownload } from '../../utils'

const mapStateToProps = (state: ApplicationState) => ({
    loginState: state.login,
    servCatalogState: state.servCatalog
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const F3MFooter = (props: Props) => {
    const classes = useStyles()

    const servCatalogBlob = props.servCatalogState?.servCatalogBlob
    const { user } = props.loginState!

    const { SOFTWARE, SERVICES, TRAINING, BACKOFFICE } = ROUTES

    const size = useWindowSize()
    const { sm } = theme.breakpoints.values

    return (
        <Container className={classes.container} component='footer' maxWidth={false} disableGutters>
            <Grid container direction='column' justify='space-between'>
                <Grid item container className={classes.columnsGrid} justify={size.width! < sm ? 'flex-start' : 'center'}>
                    <Grid item xs={6} sm={3} md={3}>
                        <Typography className={classes.footerTitle} variant='body2' color='textSecondary'>{i18n.t('menus.software.parent')}</Typography>
                        <Typography variant='body2'>
                            <Link className={classes.footerLink} to={SOFTWARE.LICENSING.PATH}>{i18n.t('menus.software.licensing')}</Link>
                        </Typography>
                        <Typography variant='body2'>
                            <Link className={classes.footerLink} to={SOFTWARE.INSTALLATION.PATH}>{i18n.t('menus.software.installations')}</Link>
                        </Typography>
                        <Typography variant='body2'>
                            <Link className={classes.footerLink} to={SOFTWARE.UPDATES.PATH}>{i18n.t('menus.software.updates')}</Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <Typography className={classes.footerTitle} variant='body2' color='textSecondary'>{i18n.t('menus.services.parent')}</Typography>
                        <Typography variant='body2'>
                            <Link className={classes.footerLink} to={SERVICES.INCIDENTS.PATH}>{i18n.t('menus.services.incidents')}</Link>
                        </Typography>
                        <Typography variant='body2'>
                            <Link className={classes.footerLink} to={SERVICES.CONTRACTS.PATH}>{i18n.t('menus.services.contracts')}</Link>
                        </Typography>
                        <Typography variant='body2'>
                            <MuiLink className={classes.footerLink} onClick={() => openOrDownload(undefined, undefined, servCatalogBlob)}>{i18n.t('menus.services.catalog')}</MuiLink>
                        </Typography>
                        <Typography variant='body2'>
                            <Link className={classes.footerLink} to={SERVICES.REPORTS.PATH}>{i18n.t('menus.services.report')}</Link>
                        </Typography>
                        <Typography variant='body2'>
                            <Link className={classes.footerLink} to={SERVICES.DOWNLOADS.PATH}>{i18n.t('menus.services.downloads')}</Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                        <Typography className={classes.footerTitle} variant='body2' color='textSecondary'>{i18n.t('menus.training.parent')}</Typography>
                        <Typography variant='body2'>
                            <MuiLink className={classes.footerLink} href={TRAINING.REDIRECT} target={TRAINING.TARGET}>{i18n.t('menus.training.footer')}</MuiLink>
                        </Typography>
                    </Grid>
                    {user?.role === 'F3M' &&
                        <Grid item xs={6} sm={3} md={3}>
                            <Typography className={classes.footerTitle} variant='body2' color='textSecondary'>{i18n.t('menus.backoffice.parent')}</Typography>
                            <Typography variant='body2'>
                                <Link className={classes.footerLink} to={BACKOFFICE.CAROUSEL.PATH}>{i18n.t('menus.backoffice.carousel')}</Link>
                            </Typography>
                            <Typography variant='body2'>
                                <Link className={classes.footerLink} to={BACKOFFICE.APPLICATIONS.PATH}>{i18n.t('menus.backoffice.applications')}</Link>
                            </Typography>
                            <Typography variant='body2'>
                                <Link className={classes.footerLink} to={BACKOFFICE.DOWNLOADS.PATH}>{i18n.t('menus.backoffice.downloads')}</Link>
                            </Typography>
                        </Grid>
                    }
                </Grid>
                <Grid container className={classes.iconAndImageGrid} direction='column' spacing={2} alignItems='center'>
                    <Grid item>
                        <F3MIcons variant='footer' />
                    </Grid>
                    <Grid item container justify='center'>
                        <img className={classes.image} src='/images/footer/2020.png' alt='' />
                    </Grid>
                </Grid>
            </Grid>

        </Container>
    )
}

export default connector(F3MFooter)