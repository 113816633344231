import { Checkbox, CircularProgress, FormControlLabel, Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { ChangeEvent, Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { applicationService } from '../../../../api'
import i18n from '../../../../i18n'
import { ApiResponse } from '../../../../types/api'
import { ApplicationResponse } from '../../../../types/services/application'
import { ApplicationState } from '../../../../types/store'

import { ContentPropsAppDownUpdtCreate } from '../../../F3MAddUpdt/types'

import useStyles from './styles'


const mapStateToProps = (state: ApplicationState) => {
    return {
        appDownload: state.appDownload
    }
}

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const F3MApplicationChoice = (props: ContentPropsAppDownUpdtCreate & Props &
{ setAddUpdtModal: Dispatch<SetStateAction<boolean>>, handleError: (message: string) => void }) => {

    const classes = useStyles()

    const {
        addOrUpdt,
        setAddOrUpdt,
        setAddUpdtModal,
        handleError
    } = props

    const versionFromFile = props.appDownload?.version

    const defaultApplication = addOrUpdt && (addOrUpdt.applicationId && addOrUpdt.applicationName)
        ? { 'applicationId': addOrUpdt.applicationId, 'applicationName': addOrUpdt.applicationName }
        : null

    const [application, setApplication] = useState<ApplicationResponse | null>(defaultApplication)
    const [applications, setApplications] = useState<ApplicationResponse[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [isManualVersion, setManualVersion] = useState<boolean>(addOrUpdt.isManualVersion ? addOrUpdt.isManualVersion : false)
    const [versionToPresent, setVersionToPresent] = useState<string | undefined>(props.appDownload?.version)

    const loadApplications = async () => {
        setLoading(true)
        try {
            const response: ApiResponse<ApplicationResponse[]> = await applicationService.loadApplications()

            if (response.isSuccess) {
                setApplications(response.result)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }
        catch (e) {
            setAddUpdtModal(false)
            handleError(i18n.t('error.unexpectedError'))
        }
        finally {
            setLoading(false)
        }
    }

    const handleChangeApplication = (e: React.ChangeEvent<{}>, option: { applicationId: string, applicationName: string } | null) => {
        setAddOrUpdt(prevState => ({
            ...prevState,
            applicationId: option ? option.applicationId : undefined,
            applicationName: option ? option.applicationName : undefined
        }))
        setApplication(option)
    }

    const handleChangeVersion = (e: any) => {
        if (!e.target.value.match(/[^0-9.]/g)) {
            setAddOrUpdt(prevState => ({ ...prevState, version: e.target.value }))
            setVersionToPresent(e.target.value)
        }
    }

    const handleChangeManualVersion = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked === true) {
            setAddOrUpdt(prevState => ({ ...prevState, version: versionFromFile, isManualVersion: true }))
        }
        else {
            setAddOrUpdt(prevState => ({ ...prevState, version: versionFromFile, isManualVersion: false }))
        }
        setVersionToPresent(versionFromFile)
        setManualVersion(checked)
    }

    useEffect(() => {
        if (!isManualVersion && versionFromFile !== versionToPresent) {
            setVersionToPresent(versionFromFile)
            setAddOrUpdt(prevState => ({ ...prevState, version: versionFromFile, isManualVersion: false }))
        }
    }, [props.appDownload?.version]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadApplications()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={classes.root}>
            <Grid container alignItems='flex-end' spacing={5} >
                <Grid item xs={12} sm={6} md={6}>
                    <Autocomplete
                        id='auto-complete-application'
                        size={'small'}
                        value={application}
                        options={applications}
                        getOptionSelected={(option, value) => option.applicationId === value.applicationId}
                        getOptionLabel={(option) => option.applicationName}
                        style={{ width: '100%' }}
                        onChange={handleChangeApplication}
                        loading={loading}
                        renderInput={(params) => <TextField
                            {...params}
                            label={i18n.t('applicationDownload.product')}
                            variant='outlined'
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <Fragment>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </Fragment>
                                )
                            }} />}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}  >
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4}  >
                            <TextField id='version' label='Versão' disabled={!isManualVersion} onChange={handleChangeVersion} value={versionToPresent} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}  >
                            <FormControlLabel control={
                                <Checkbox color={'primary'} checked={isManualVersion} onChange={(e, c) => handleChangeManualVersion(e, c)} name='manualVersion' />
                            } label='Introduzir Manualmente' />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

export default connector(F3MApplicationChoice)