import { DownloadActions, DownloadState } from '../../types/store/download'

const initialState: DownloadState = {
    downloads: {
        data: [],
        loading: false
    }
}

const downloadReducer = (state = initialState, action: DownloadActions): DownloadState => {
    switch (action.type) {
        case ('DOWNLOAD_LOAD_DOWNLOADS_REQUEST'): {
            return {
                ...state,
                downloads: {
                    ...state.downloads,
                    loading: true
                }
            }
        }

        case ('DOWNLOAD_LOAD_DOWNLOADS_SUCCESS'): {
            return {
                ...state,
                downloads: {
                    ...state.downloads,
                    data: action.payload,
                    loading: false
                }
            }
        }

        case ('DOWNLOAD_LOAD_DOWNLOADS_ERROR'): {
            return {
                ...state,
                downloads: {
                    ...state.downloads,
                    loading: false
                }
            }
        }

        case ('DOWNLOAD_CLEAR_DATA'): {
            return initialState
        }

        default:
            return state
    }
}

export default downloadReducer