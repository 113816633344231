import { Theme, makeStyles, createStyles } from '@material-ui/core'

import { F3MButtonProps } from './types'

const useStyles = makeStyles<Theme, F3MButtonProps>((theme: Theme) => createStyles({
    root: props => ({
        boxSizing: 'border-box',
        fontWeight: props.bold ? 700 : 500,
        transition: theme.transitions.create(['background-color', 'box-shadow', 'border'], {
            duration: theme.transitions.duration.short
        }),
        '&:hover': {
            color          : props.variant === 'outlined' || props.variant === 'contained' ? theme.palette[props.color!].contrastText 
                                                                                           : theme.palette[props.color!].main,
            backgroundColor: props.variant === 'outlined' || props.variant === 'contained' ? theme.palette[props.color!].dark 
                                                                                           : theme.palette[props.color!].light,
            borderColor    : props.variant === 'outlined' || props.variant === 'contained' ? theme.palette[props.color!].dark 
                                                                                           : theme.palette[props.color!].light
        }
    }),
    contained: props => ({
        color          : theme.palette[props.color!].contrastText,
        backgroundColor: theme.palette[props.color!].main,
        borderColor    : theme.palette[props.color!].main,
        boxShadow: '0px 1px 4px rgba(0,0,0,0.2)'
    }),
    outlined: props => ({
        color          : theme.palette[props.color!].main,
        borderColor    : theme.palette[props.color!].main,
        boxShadow: '0px 1px 4px rgba(0,0,0,0.2)'
    }),
    text: props => ({
        color: theme.palette[props.color!].main,
        border: 'none'
    }),
    disabled: {
        cursor: '',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
}))

export default useStyles