import { CONFIG, ENDPOINTS } from '../../constants'
import { AssistanceContractsResponse, ServiceContractsResponse } from '../../types/services/contracts'
import { request } from '../axios'

export const contractsService = {
    loadAssistanceContracts: async (maxPlans?: number) => {
        const response = await request<AssistanceContractsResponse[]>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.CONTRACT_ASSISTANCE_CONTRACTS,
            params: { maxPlans: maxPlans }
        })

        return response
    },

    loadServiceContracts: async (maxPlans?: number) => {
        const response = await request<ServiceContractsResponse[]>({
            method: 'GET',
            url: CONFIG.API_URL + ENDPOINTS.CONTRACT_SERVICE_CONTRACTS,
            params: { maxPlans: maxPlans }
        })

        return response
    }
}