import React from 'react'

import { Box, Typography } from '@material-ui/core'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import i18n from '../../i18n'

import theme from '../../assets/theme'

const F3MEmptyState = () => {
    return (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
            <ErrorOutlineIcon
                htmlColor = {theme.palette.warning.main}
                fontSize  = 'large'
                style     = {{marginBottom: 10}}
            />
            <Typography
                gutterBottom
                variant = 'body1'
                style   = {{fontWeight: 600}}
            >
                {i18n.t('error.noAvailableInfo')}
            </Typography>
        </Box>
    )
}

export default F3MEmptyState