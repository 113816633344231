import { Theme, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxHeight: '730px',
        margin: 'auto'
    },
    modalTitle: {
        fontWeight: 700,
        borderBottom: 'solid 1px #dadada'
    },
    modalContent: {
        height: 'calc(100vh-50px)',
        overflowY: 'auto'
    },
    modalFooter: {
        height: '50px',
        borderTop: 'solid 1px #dadada'
    },
    required: {
        color: 'red'
    }
}))

export default useStyles