import { AppThunkAction } from '../../types/store'
import { CommonActions } from '../../types/store/common'
import commonActions from '../actions/commonActions'

const { toggleSidebar, toggleSoftwareSidebar, toggleServiceSidebar, toggleBackofficeSidebar, toggleLogoutConfirmation } = commonActions

const commonOperations = {
    toggleSidebar:  (event: React.MouseEvent<Document | HTMLButtonElement, MouseEvent>): AppThunkAction<CommonActions> => (dispatch, getState) => {
        event?.preventDefault()
        
        if(getState().common?.sidebarSoftwareOpen) {
            dispatch(toggleSoftwareSidebar())
        }

        if(getState().common?.sidebarServicesOpen) {
            dispatch(toggleServiceSidebar())
        }

        if(getState().common?.sidebarBackofficeOpen) {
            dispatch(toggleBackofficeSidebar())
        }

        dispatch(toggleSidebar())
    },

    toggleSoftwareSidebar: (): AppThunkAction<CommonActions> => (dispatch, getState) => {
        if (!getState().common?.sidebarOpen) {
            dispatch(toggleSidebar())
        }

        dispatch(toggleSoftwareSidebar())
    },

    toggleServiceSidebar: (): AppThunkAction<CommonActions> => (dispatch, getState) => {
        if (!getState().common?.sidebarOpen) {
            dispatch(toggleSidebar())
        }

        dispatch(toggleServiceSidebar())
    },

    toggleBackofficeSidebar: (): AppThunkAction<CommonActions> => (dispatch, getState) => {
        if (!getState().common?.sidebarOpen) {
            dispatch(toggleSidebar())
        }

        dispatch(toggleBackofficeSidebar())
    },

    toggleLogoutConfirmation: (): AppThunkAction<CommonActions> => (dispatch, getState) => {
        dispatch(toggleLogoutConfirmation())
    }
}

export default commonOperations