import { ReportFields, SortDirection } from '../../types'
import { TicketsResponse, TicketsStatisticsResponse } from '../../types/services/tickets'
import {
    ClearData,
    HandleFilterChange,
    HandlePageChange,
    HandleRowsPerPageChange,
    HandleYearChange,
    LoadStatisticsError,
    LoadStatisticsRequest,
    LoadStatisticsSuccess,
    LoadTicketsError,
    LoadTicketsRequest,
    LoadTicketsSuccess,
    LoadYearsError,
    LoadYearsRequest,
    LoadYearsSuccess,
    Order,
    ToggleFilter
} from '../../types/store/report'

const reportActions = {
    loadStatisticsRequest: (): LoadStatisticsRequest => {
        return {type: 'REPORT_LOAD_STATISTICS_REQUEST'}
    },
    
    loadStatisticsSuccess: (response: TicketsStatisticsResponse): LoadStatisticsSuccess => {
        return {type: 'REPORT_LOAD_STATISTICS_SUCCESS', payload: response}
    },
    
    loadStatisticsError: (error: string): LoadStatisticsError => {
        return {type: 'REPORT_LOAD_STATISTICS_ERROR', payload: error}
    },
    
    loadTicketsRequest: (): LoadTicketsRequest => {
        return {type: 'REPORT_LOAD_TICKETS_REQUEST'}
    },
    
    loadTicketsSuccess: (response: TicketsResponse): LoadTicketsSuccess => {
        return {type: 'REPORT_LOAD_TICKETS_SUCCESS', payload: response}
    },
    
    loadTicketsError: (error: string): LoadTicketsError => {
        return {type: 'REPORT_LOAD_TICKETS_ERROR', payload: error}
    },

    clearData: (): ClearData => {
        return {type: 'REPORT_CLEAR_DATA'}
    },
    
    loadYearsRequest: (): LoadYearsRequest => {
        return {type: 'REPORT_LOAD_YEARS_REQUEST'}
    },
    
    loadYearsSuccess: (response: number[]): LoadYearsSuccess => {
        return {type: 'REPORT_LOAD_YEARS_SUCCESS', payload: response}
    },
    
    loadYearsError: (error: string): LoadYearsError => {
        return {type: 'REPORT_LOAD_YEARS_ERROR', payload: error}
    },

    handleYearChange: (year: number): HandleYearChange => {
        return {type: 'REPORT_HANDLE_YEAR_CHANGE', payload: year}
    },

    handleFilterChange: (name: string, value: string): HandleFilterChange => {
        return {type: 'REPORT_HANDLE_FILTER_CHANGE', payload: {name, value}}
    },

    toggleFilter: (): ToggleFilter => {
        return {type: 'REPORT_TOGGLE_FILTER'}
    },

    handlePageChange: (page: number): HandlePageChange => {
        return {type: 'REPORT_HANDLE_PAGE_CHANGE', payload: page}
    },

    handleRowsPerPageChange: (rowsPerPage: number): HandleRowsPerPageChange => {
        return {type: 'REPORT_HANDLE_ROWS_PER_PAGE_CHANGE', payload: rowsPerPage}
    },

    order: (sortDirection: SortDirection, sortField: ReportFields): Order => {
        return {type: 'REPORT_ORDER', payload: {sortDirection, sortField}}
    }
}

export default reportActions