import { CONFIG, ENDPOINTS } from '../../constants'
import { ProductsRequest, ProductsResponse, WebProductResponse } from '../../types/services/license'
import { download, request } from '../axios'

export const licenseService = {
    loadProducts: async (data: ProductsRequest) => {
        const response = await request<ProductsResponse>({
            method : 'POST',
            url    : CONFIG.API_URL + ENDPOINTS.LICENSING_PRODUCTS,
            data   : data
        })

        return response
    },

    loadWebProducts: async () => {
        const response = await request<WebProductResponse[]>({
            method : 'GET',
            url    : CONFIG.API_URL + ENDPOINTS.LICENSING_WEB_PRODUCTS,
        })

        return response
    },

    getProductLicensePDF: async (id: string) => {
        const response = await download({
            method : 'GET',
            url    : CONFIG.API_URL + ENDPOINTS.LICENSING_PRODUCT_LICENSE_PDF + '/' + id
        })

        return response
    },

    downloadProductLicense: async (id: string) => {
        const response = await download({
            method : 'GET',
            url    : CONFIG.API_URL + ENDPOINTS.LICENSING_PRODUCT_LICENSE + '/' + id
        })

        return response
    },

    downloadWebProductLicense: async (code: string) => {
        const response = await download({
            method : 'GET',
            url    : CONFIG.API_URL + ENDPOINTS.LICENSING_WEB_PRODUCT_LICENSE + '/' + code
        })

        return response
    }
}