import React, { ChangeEvent, Dispatch, Fragment, SetStateAction, useState } from 'react'

import { CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import i18n from '../../../../i18n'

import { downloadManagerService } from '../../../../api'
import { ApiResponse } from '../../../../types/api'
import { ClientModel } from '../../../../types/services/downloadsManager'

import { ContentPropsDownManUpdtCreate } from '../../../F3MAddUpdt/types'

import useStyles from './styles'

const F3MClientChoice = (props: ContentPropsDownManUpdtCreate &
{
    setAddUpdtModal: Dispatch<SetStateAction<boolean>>,
    handleError: (message: string) => void,
    defaultClient: ClientModel | null
}) => {

    const classes = useStyles()

    const {
        setAddOrUpdt,
        setAddUpdtModal,
        handleError,
        defaultClient
    } = props

    const [client, setClient] = useState<ClientModel | null>(defaultClient)
    const [clients, setClients] = useState<ClientModel[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)

    const loadClients = async (clientCodeName: string) => {
        if (clientCodeName.length === 0) {
            setClients([])
            return
        }

        setLoading(true)
        try {
            const response: ApiResponse<ClientModel[]> = await downloadManagerService.loadClients(clientCodeName)

            if (response.isSuccess) {
                if (response.result.length > 0) {
                    setOpen(true)
                }
                setClients(response.result)
                setLoading(false)
            } else {
                setClients([])
                setLoading(false)
            }
        }
        catch (e) {
            setAddUpdtModal(false)
            handleError(i18n.t('error.unexpectedError'))
        }
        finally {
            setLoading(false)
        }
    }

    const handleChangeClient = (e: ChangeEvent<{}>, option: ClientModel | null) => {
        setOpen(false)
        setAddOrUpdt(prevState => ({
            ...prevState,
            clientId: option ? option.clientId : undefined,
        }))
        setClient(option)
    }

    return (
        <div className={classes.root}>
            <Autocomplete
                id='auto-complete-client'
                size={'small'}
                open={open}
                value={client}
                options={clients}
                getOptionSelected={(option, value) => option.clientId === value.clientId}
                getOptionLabel={(option) => option.clientName}
                style={{ width: '100%' }}
                onChange={handleChangeClient}
                filterOptions={(options, state) => options}
                loading={loading}
                noOptionsText={i18n.t('downloads.noOptionsAvail')}
                renderInput={(params) =>
                    <TextField {...params} label={i18n.t('downloads.client')}
                        onChange={async (event) => {
                            await loadClients(event.target.value)
                        }}
                        variant='outlined'
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Fragment>
                                    {loading ? (
                                        <CircularProgress color='inherit' size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            )
                        }}
                    />}
            />
        </div >
    )
}

export default F3MClientChoice