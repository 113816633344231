import React, { useState } from 'react'

import useStyles from './styles'

import { ContentPropsDownCatUpdtCreate } from '../../F3MAddUpdt/types'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import i18n from '../../../i18n'

const F3MDownloadType = (props: ContentPropsDownCatUpdtCreate) => {

    const classes = useStyles()

    const {
        addOrUpdt,
        setAddOrUpdt
    } = props

    const [downloadType, setDownloadType] = useState<string | null>(addOrUpdt && addOrUpdt.downloadType !== undefined ? addOrUpdt.downloadType.toString() : null)

    const handleChangeDownloadType = (downloadType: string) => {
        setDownloadType(downloadType)
        setAddOrUpdt((prevState: any) => ({ ...prevState, downloadType: +downloadType }))
    };

    return (
        <div className={classes.root}>
            <FormControl component='fieldset'>
                <RadioGroup row aria-label='downloadType' name='downloadType' value={downloadType} onChange={(e) => handleChangeDownloadType(e.target.value)}>
                    <FormControlLabel value='0' control={<Radio color='primary' />} label={i18n.t('enum.applicationDownload.type.0')} />
                    <FormControlLabel value='1' control={<Radio color='primary' />} label={i18n.t('enum.applicationDownload.type.1')} />
                    <FormControlLabel value='2' control={<Radio color='primary' />} label={i18n.t('enum.applicationDownload.type.2')} />
                </RadioGroup>
            </FormControl>
        </div>
    )
}

export default F3MDownloadType