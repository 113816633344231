import React from 'react'
import { SvgIcon } from '@material-ui/core'

export const SoftwareIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M28.4,0.5H3.6C1.8,0.5,0.3,2,0.3,3.9v17.9c0,1.8,1.5,3.3,3.3,3.3h8.9l-0.9,3.3H9.5v1.3h13.1v-1.3h-2.1l-1-3.4
                    h8.9c1.8,0,3.3-1.5,3.3-3.3V3.9C31.7,2,30.2,0.5,28.4,0.5z M1.6,3.9c0-1.1,0.9-2,2-2h24.8c1.1,0,2,0.9,2,2v15.2H1.6V3.9z
                    M12.9,28.4l0.9-3.3H18l0.9,3.3H12.9z M30.4,21.7c0,1.1-0.9,2-2,2H3.6c-1.1,0-2-0.9-2-2v-1.3h28.8V21.7z"/>
                <polygon points="14.1,15.6 15.3,16 17.9,6.7 16.7,6.3 	"/>
                <path d="M11.7,7.3l-3.9,3.3c-0.3,0.2-0.3,0.7-0.1,0.9l0.1,0.1l3.9,3.3l0.8-1l-3.3-2.8l3.3-2.8L11.7,7.3z"/>
                <path d="M24.3,10.6l-3.9-3.3l-0.8,1l3.3,2.8l-3.3,2.8l0.8,1l3.9-3.3C24.5,11.4,24.6,11,24.3,10.6
                    C24.3,10.7,24.3,10.7,24.3,10.6z"/>
            </g>
        </SvgIcon>
    )
}