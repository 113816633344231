import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    logo: {
        verticalAlign: 'middle',
        [theme.breakpoints.up('sm')]: {
            width: '120px',
            height: '39px'
        },
        [theme.breakpoints.down('xs')]: {
            width: '110px',
            height: '36px'
        }
    }
}))

export default useStyles