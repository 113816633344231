import { CONFIG, ENDPOINTS } from '../../constants'
import { download } from '../axios'

export const servCatalogService = {
    getServCatalogUrl: async () => {
        try {
            const response = await download({
                method: 'GET',
                url: CONFIG.API_URL + ENDPOINTS.SERVCATALOG
            })

            const { data, headers } = response

            const blob = new Blob([data], { type: headers['content-type'] })

            return blob
        }
        catch (error) {
            throw new Error()
        }
    }
}