import { contractsService } from '../../api'
import { AppThunkAction } from '../../types/store'
import { ContractActions } from '../../types/store/contract'
import contractActions from '../actions/contractActions'

const {
    loadServiceContractsRequest,
    loadServiceContractsSuccess,
    loadServiceContractsError,
    loadAssistanceContractsRequest,
    loadAssistanceContractsSuccess,
    loadAssistanceContractsError,
    clearData
} = contractActions

const contractOperations = {
    loadServiceContractsData: (): AppThunkAction<ContractActions> => async (dispatch, getState) => {
        dispatch(loadServiceContractsRequest())

        try {
            const response = await contractsService.loadServiceContracts()
            
            if(response.isSuccess) {
                dispatch(loadServiceContractsSuccess(response.result))
            } else {
                dispatch(loadServiceContractsError(response.errorMessage))
            }
        } catch(e) {
            dispatch(loadServiceContractsError(e.response))
        }
    },

    loadAssistanceContractsData: (): AppThunkAction<ContractActions> => async (dispatch, getState) => {
        dispatch(loadAssistanceContractsRequest())

        try {
            const response = await contractsService.loadAssistanceContracts()
            
            if(response.isSuccess) {
                dispatch(loadAssistanceContractsSuccess(response.result))
            } else {
                dispatch(loadAssistanceContractsError(response.errorMessage))
            }
        } catch(e) {
            dispatch(loadAssistanceContractsError(e.response))
        }
    },

    clearData: (): AppThunkAction<ContractActions> => async (dispatch, getState) => {
        dispatch(clearData())
    }
}

export default contractOperations