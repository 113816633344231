import { AppDownloadActions, AppDownloadState } from "../../types/store/appDownload"

const initialState: AppDownloadState = {
    version: ''
}

const appDownloadReducer = (state = initialState, action: AppDownloadActions): AppDownloadState => {
    switch (action.type) {

        case ('SET_VERSION'): {
            
            return {
                ...state,
                version: action.payload
            }
        }

        case ('CLEAR_VERSION'): {
            return initialState
        }

        default:
            return state
    }
}

export default appDownloadReducer