import React from 'react'
import { Breadcrumbs, Typography } from '@material-ui/core'

import { F3MBreadcrumbsProps } from './types'

import useStyles from './styles'

// Only works with two levels
// Ex. Software / Licensing
const F3MBreadcrumbs = (props: F3MBreadcrumbsProps) => {
    const classes = useStyles()
    const crumbs = props.path.split('/')

    return (
        <Breadcrumbs>
            <Typography 
                color     = 'textSecondary'
                variant   = 'body1'
                className = {classes.parent}
            >
                {crumbs[0]}
            </Typography>

            <Typography 
                color='textPrimary'
                className = {classes.page}
            >
                {crumbs[1]}
            </Typography>
        </Breadcrumbs>
    )
}

export default F3MBreadcrumbs