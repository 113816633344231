import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: 20,
        verticalAlign: 'middle'
    },
    buttons: {
        padding: 20
    },
    radioButton: {
        float: 'left',
        width: '35px',
        height: '35px',
        borderRadius: '2px'
    },
    orange: {
        backgroundColor: '#f7931d'
    },
    blue: {
        backgroundColor: '#00adef'
    },
    darkBlue: {
        backgroundColor: '#003f6e'
    },
    radioButtonText: {
        float: 'right',
        paddingLeft: 20,
        paddingRight: 20
    }
}))

export default useStyles