import React from 'react'

import { connect, ConnectedProps } from 'react-redux'

import { AppBar, Toolbar } from '@material-ui/core'

import { ApplicationState } from '../../types/store'

import LoginAppBar from './Login'
import HomeAppBar from './Home'

const mapStateToProps = (state: ApplicationState) => ({
    loginState: state.login
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

const F3MAppBar = (props: Props) => {

    return (
        <AppBar position='fixed'>
            <Toolbar>
                { props.loginState?.authenticated ? <HomeAppBar/> : <LoginAppBar/> }
            </Toolbar>
        </AppBar>
    )
}

export default connector(F3MAppBar)