import React from 'react'

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'

import { isDefined } from '../../utils'
import i18n from '../../i18n'

import useStyles from './styles'

import { F3MSelectProps } from './types'

const F3MSelect = (props: F3MSelectProps) => {

    let selectProps: F3MSelectProps = {
        // Default NOT AUTOWIDTH
        autoWidth: isDefined(props.autoWidth) ? props.autoWidth : false,

        // Default NOT BOLD
        bold: isDefined(props.bold) ? props.bold : false,

        // CHILDREN
        children: props.children,

        // CLASSNAME
        className: props.className,

        // Default COLOR PRIMARY
        color: isDefined(props.color) ? props.color : 'primary',

        // DEFAULT VALUE
        defaultValue: props.defaultValue,

        // Default NOT DISABLED
        disabled: isDefined(props.disabled) ? props.disabled : false,
        
        // Default NOT ERROR
        error: isDefined(props.error) ? props.error : false,

        // Default DISPLAY ALL
        displayAll: isDefined(props.displayAll) ? props.displayAll : true,

        // Default NOT DISPLAY EMPTY
        displayEmpty: isDefined(props.displayEmpty) ? props.displayEmpty : false,

        // Default NOT FOCUSED
        // focused: isDefined(props.focused) ? props.focused : false,

        // Default FULL WIDTH
        fullWidth: isDefined(props.fullWidth) ? props.fullWidth : true,

        // Default NOT HIDDEN LABEL
        hiddenLabel: isDefined(props.hiddenLabel) ? props.hiddenLabel : false,

        // ICON COMPONENT
        IconComponent: props.IconComponent,

        // ID
        id: props.id,

        // INPUT
        input: props.input,

        // INPUT PROPS
        inputProps: props.inputProps,

        // ITEMS
        items: isDefined(props.items) ? props.items : [],

        // LABEL
        label: props.label,

        // LABEL ID
        labelId: props.labelId,

        // LABEL WIDTH
        labelWidth: props.labelWidth,

        // Default MARGIN DENSE
        margin: isDefined(props.margin) ? props.margin : 'dense',

        // MENU PROPS
        MenuProps: props.MenuProps,

        // Default NOT MULTIPLE
        multiple: isDefined(props.multiple) ? props.multiple : false,

        // NAME
        name: props.name,

        // Default NOT NATIVE
        native: isDefined(props.native) ? props.native : false,

        // ON CHNAGE
        onChange: props.onChange,

        // ON CLOSE
        onClose: props.onClose,

        // ON OPEN
        onOpen: props.onOpen,

        // OPEN
        open: props.open,

        // RENDER VALUE
        renderValue: props.renderValue,

        // Default NOT REQUIRED
        required: isDefined(props.required) ? props.required : false,

        // SELECT DISPLAY PROPS
        SelectDisplayProps: props.SelectDisplayProps,

        // Default SIZE SMALL
        size: isDefined(props.size) ? props.size : 'small',

        // VALUE
        value: props.value,

        // Default VARIANT OUTLINED
        variant: isDefined(props.variant) ? props.variant : 'outlined'
    }

    const classes = useStyles(selectProps)

    return (
        <FormControl
            className   = {classes.FormControl}
            disabled    = {selectProps.disabled}
            error       = {selectProps.error}
            fullWidth   = {selectProps.fullWidth}
            // focused     = {selectProps.focused}
            hiddenLabel = {selectProps.hiddenLabel}
            id          = {selectProps.id}
            margin      = {selectProps.margin}
            required    = {selectProps.required}
            size        = {selectProps.size}
            variant     = {selectProps.variant}
        >
            <InputLabel className = {classes.inputRoot} id = {selectProps.labelId}>{selectProps.label}</InputLabel>
            <Select
                autoWidth          = {selectProps.autoWidth}
                className          = {classes.select}
                defaultValue       = {selectProps.defaultValue}
                displayEmpty       = {selectProps.displayEmpty}
                IconComponent      = {selectProps.IconComponent}
                input              = {selectProps.input}
                inputProps         = {selectProps.inputProps}
                label              = {selectProps.label}
                labelId            = {selectProps.labelId}
                labelWidth         = {selectProps.labelWidth}
                MenuProps          = {{
                    ...selectProps.MenuProps,
                    anchorOrigin      : {vertical: 'bottom', horizontal: 'center'},
                    transformOrigin   : {vertical: 'top', horizontal: 'center'},
                    getContentAnchorEl: null,
                    classes           : {paper: classes.menuPaper}
                }}
                multiple           = {selectProps.multiple}
                name               = {selectProps.name}
                native             = {selectProps.native}
                onChange           = {selectProps.onChange}
                onClose            = {selectProps.onClose}
                onOpen             = {selectProps.onOpen}
                open               = {selectProps.open}
                renderValue        = {selectProps.renderValue}
                SelectDisplayProps = {selectProps.SelectDisplayProps}
                value              = {selectProps.value}
            >
                {selectProps.children}
                {selectProps.displayAll ? <MenuItem className={classes.menuItem} value=''>{i18n.t('common.all')}</MenuItem> : null}
                {selectProps.items?.map((c) => {
                    return (
                        <MenuItem className={classes.menuItem} key={c.id.toString()} value={c.id}>{c.label.toString()}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
        
    )
}

export default F3MSelect