import { makeStyles, createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    tableRow: {
        margin: theme.spacing(0, 1),
        '& > *': {
          borderBottom: 'unset !important'
        }
    },
    tableRowIcon: {
        transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.short
        })
    },
    tableRowIconOpen: {
        transform: 'rotate(0deg)'
    },
    tableRowIconClose: {
        transform: 'rotate(-180deg)'
    },
    collapsedTableRow: {
      backgroundColor: theme.palette.common.white + ' !important'
    },
    collapsedTable: {
        borderRight: '1px solid ' + theme.palette.grey[300],
        borderLeft: '1px solid ' + theme.palette.grey[300],
        borderTop: '1px solid ' + theme.palette.grey[300],
        borderBottom: '1px solid ' + theme.palette.grey[300]
    },
    collapsedTableHead: {
        backgroundColor: theme.palette.common.white + ' !important'
    },
    collapsedTableRowHead: {
        backgroundColor: theme.palette.common.white + ' !important'
    },
    collapsedTableCellHead: {
        color: theme.palette.text.primary
    },
    collapsedTableRowBody: {
        '&:nth-child(even)': {
            backgroundColor: theme.palette.common.white
        },
        '&:nth-child(odd)': {
            backgroundColor: theme.palette.grey[50]
        }
    }
}))

export default useStyles