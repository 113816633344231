import { AppThunkAction } from '../../types/store'
import { AppDownloadActions } from '../../types/store/appDownload'
import appDownloadActions from '../actions/appDownloadActions'

const {
    setVersion,
    clearVersion
} = appDownloadActions

const appDownloadOperations = {
    setVersion: (version: string): AppThunkAction<AppDownloadActions> => async (dispatch) => {
        dispatch(setVersion(version))
    },

    clearVersion: (): AppThunkAction<AppDownloadActions> => async (dispatch) => {
        dispatch(clearVersion())
    }
}

export default appDownloadOperations