import moment from 'moment'

export const tableAsExcel = {
    downloadExcel: (response: any, title: string) => {
        const { data, headers } = response

        const blob: Blob = new Blob([data], { type: headers['content-type'] })

        let link = document.createElement('a')

        link.href = window.URL.createObjectURL(blob)
        link.download = title + ' - ' + moment().format('DD-MM-YYYY')

        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
    }
}