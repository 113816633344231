import React, { useEffect, useState } from 'react'

import { Grid, IconButton, Link, Typography } from '@material-ui/core'

import VisibilityIcon from '@material-ui/icons/Visibility'

import { F3MBreadcrumbs, F3MDownload, F3MPopover, F3MDataGrid, F3MDataGridColumnProps, F3MDataGridRowData, F3MCard, F3MCategoryTable, F3MSnackbarState, F3MSnackbar } from '../../../../components'

import i18n from '../../../../i18n'
import { isDefined } from '../../../../utils'

import { UpdateResponse, UpgradeResponse } from '../../../../types/services/applicationDownload'
import { applicationDownloadService } from '../../../../api'
import { downloadCategoryDownloadsService } from '../../../../api/services/downloadCategoryDownloadsService'
import { CategoryDownload } from '../../../../types/services/downloadCategoryDownloads'
import globalSoftwareStyles from '../../styles'

const Updates = () => {
    const globalClasses = globalSoftwareStyles()
    
    const [snackbar, setSnackbar] = useState<F3MSnackbarState>({
        open: false,
        severity: 'info',
        message: ''
    })

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [up, setUp] = useState<UpgradeResponse | UpdateResponse | null>(null)
    const [updates, setUpdates] = useState<UpdateResponse[]>([])
    const [upgrades, setUpgrades] = useState<UpgradeResponse[]>([])
    const [downCatDownBusArea, setDownCatDownBusArea] = useState<CategoryDownload>()
    const [loadingUpdt, setLoadingUpdt] = useState<boolean>(false)
    const [loadingUpgr, setLoadingUpgr] = useState<boolean>(false)

    const loadData = async () => {
        setLoadingUpdt(true)
        setLoadingUpgr(true)
        try {
            const responseUpdates = await applicationDownloadService.loadUpdates()

            if (responseUpdates.isSuccess) {
                setUpdates(responseUpdates.result)

            } else {
                handleError(i18n.t('error.unexpectedError'))
            }
            setLoadingUpdt(false)

            const responseUpgrades = await applicationDownloadService.loadUpgrades()

            if (responseUpgrades.isSuccess) {
                setUpgrades(responseUpgrades.result)

            } else {
                handleError(i18n.t('error.unexpectedError'))
            }
            setLoadingUpgr(false)

            const responseCatDownlaods = await downloadCategoryDownloadsService.loadDownCatDownBusArea([1, 2])

            if (responseCatDownlaods.isSuccess) {
                setDownCatDownBusArea(responseCatDownlaods.result.businessAreaDownloads)

            } else {
                handleError(i18n.t('error.unexpectedError'))
            }
        }
        catch (e) {
            handleError(i18n.t('error.unexpectedError'))
        }
        finally {
            setLoadingUpdt(false)
            setLoadingUpgr(false)
        }
    }

    const handleError = (message: string) => {
        setSnackbar({ open: true, severity: 'error', message: message })
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const id = e.currentTarget.parentElement?.parentElement?.id
        setUp(updates.concat(upgrades).filter((u) => u.id.toString() === id)[0])
        setAnchorEl(e.currentTarget)
    }

    const openGains = async () => {

        const { data, headers } = await applicationDownloadService.downloadGains(up?.id)

        const blob = new Blob([data], { type: headers['content-type'] })

        window.open(URL.createObjectURL(blob), "_blank")
    }

    useEffect(() => {
        loadData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    let upgradeColumns: F3MDataGridColumnProps[] = [
        { field: 'application', headerName: i18n.t('common.application'), width: 30, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'fileName', headerName: i18n.t('common.fileName'), width: 20, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'version', headerName: i18n.t('common.version'), width: 10, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'date', headerName: i18n.t('common.date'), width: 10, sortable: true, type: 'date', align: 'right', headerAlign: 'right' },
        { field: 'download', headerName: i18n.t('common.download'), width: 15, sortable: false, type: 'node', align: 'center', headerAlign: 'center' },
        { field: 'view', headerName: i18n.t('common.viewDetails'), width: 15, sortable: false, type: 'node', align: 'center', headerAlign: 'center' }
    ]

    let upgradeData: F3MDataGridRowData[] = isDefined(updates) ? updates.map((r, i) => ({
        id: r.id,
        application: r.application,
        fileName: r.fileName,
        version: r.version,
        date: r.date,
        download: <F3MDownload handleError={handleError} filename={r.fileName} request={() => applicationDownloadService.downloadApplication(r.id!)} />,
        view: r.hasGains || (isDefined(r.description) && r.description !== '') ? <IconButton className={globalClasses.hover} size='small' onClick={handleClick}><VisibilityIcon /></IconButton> : ''
    })) : []

    let updateColumns: F3MDataGridColumnProps[] = [
        { field: 'application', headerName: i18n.t('common.application'), width: 30, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'fileName', headerName: i18n.t('common.fileName'), width: 20, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'version', headerName: i18n.t('common.version'), width: 10, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'date', headerName: i18n.t('common.date'), width: 10, sortable: true, type: 'date', align: 'right', headerAlign: 'right' },
        { field: 'download', headerName: i18n.t('common.download'), width: 15, sortable: false, type: 'node', align: 'center', headerAlign: 'center' },
        { field: 'view', headerName: i18n.t('common.viewDetails'), width: 15, sortable: false, type: 'node', align: 'center', headerAlign: 'center' }
    ]

    let updateData: F3MDataGridRowData[] = isDefined(upgrades) ? upgrades.map((r, i) => ({
        id: r.id,
        application: r.application,
        fileName: r.fileName,
        version: r.version,
        date: r.date,
        download: <F3MDownload handleError={handleError} filename={r.fileName} request={() => applicationDownloadService.downloadApplication(r.id!)} />,
        view: r.hasGains || (isDefined(r.description) && r.description !== '') ? <IconButton className={globalClasses.hover} size='small' onClick={handleClick}><VisibilityIcon /></IconButton> : ''
    })) : []

    return (
        <>
            <F3MBreadcrumbs path={i18n.t("paths.updates.breadcrumb")} />
            <Grid container justify='space-between' alignItems='center' spacing={2}>
                {downCatDownBusArea ? Object.keys(downCatDownBusArea).map((elem, key) => {
                    const totalCards: number = Object.keys(downCatDownBusArea).length
                    const division: 4 | 6 | 12 = totalCards === 1 ? 12 : (totalCards % 2 === 0) ? 6 : 4
                    return (<F3MCategoryTable handleError={handleError} key={key} title={elem} division={division}
                        categoriesData={downCatDownBusArea[elem]} />)
                }) : null}
            </Grid>
            <F3MCard loading={loadingUpgr}>
                <Typography variant='h5' color='primary' gutterBottom>{i18n.t('updates.availableUpdates')}</Typography>
                <F3MDataGrid
                    columns={upgradeColumns}
                    rows={upgradeData}
                    loading={loadingUpgr}
                    pagination={true}
                    paginationMode='client'
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    sortMode='client'
                    sortField='date'
                    sortDirection='desc'
                />
            </F3MCard>
            <F3MCard loading={loadingUpdt}>
                <Typography variant='h5' color='primary' gutterBottom>{i18n.t('updates.availableUpgrades')}</Typography>
                <F3MDataGrid
                    columns={updateColumns}
                    rows={updateData}
                    loading={loadingUpdt}
                    pagination={true}
                    paginationMode='client'
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    sortMode='client'
                    sortField='date'
                    sortDirection='desc'
                />
            </F3MCard>
            <F3MPopover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            >
                {Boolean(up?.description) && <Typography variant='body1' color='textPrimary' gutterBottom>{up?.description}</Typography>}
                {up?.hasGains && <Link component="button" variant='body1' color='textPrimary' onClick={() => openGains()}>{i18n.t('updates.addedValue')}</Link>}
            </F3MPopover>
            <F3MSnackbar
                setSnackbar={setSnackbar}
                snackbar={snackbar}
            />
        </>
    )
}

export default Updates