import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { F3MCategoryTableProps } from './types'

import { F3MCard, F3MDataGrid, F3MDataGridColumnProps, F3MDataGridRowData, F3MDownload } from '..'
import i18n from '../../i18n'

import useStyles from './styles'
import { downloadCategoryDownloadsService } from '../../api/services/downloadCategoryDownloadsService'

const F3MCategoryTable = (props: F3MCategoryTableProps) => {

    const classes = useStyles()

    let productColumns: F3MDataGridColumnProps[] = [
        { field: 'description', headerName: i18n.t('common.description'), width: 30, sortable: true, type: 'string', align: 'left', headerAlign: 'left' },
        { field: 'download', headerName: i18n.t('common.download'), width: 15, sortable: false, type: 'node', align: 'center', headerAlign: 'center' }
    ]

    let productData: F3MDataGridRowData[] = (props.categoriesData).map((r, id) => ({
        id: id,
        description: r.downCatDownDescription,
        download: r.downCatDownId && <F3MDownload handleError={props.handleError} filename={r.downCatFilename} request={() => downloadCategoryDownloadsService.downloadCatDownload(r.downCatDownId)} />
    }))

    return (
        <Grid item xs={12} md={props.division} className={classes.category}>
            <F3MCard>
                <Typography variant='h5' color='primary' gutterBottom>{props.title}</Typography>
                <F3MDataGrid
                    columns={productColumns}
                    rows={productData}
                    pagination={true}
                    paginationMode='client'
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    sortMode='client'
                    sortField='date'
                    sortDirection='desc'
                />
            </F3MCard>
        </Grid>
    )
}

export default F3MCategoryTable