import React from 'react'
import { SvgIcon } from '@material-ui/core'

export const BackofficeIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M27.469,24.973c1.652-0.002,2.99-1.34,2.99-2.992V6.525c0-1.652-1.338-2.99-2.99-2.992h-6.033
                    c-1.373-3-4.914-4.32-7.915-2.949c-1.306,0.597-2.354,1.644-2.949,2.949H4.532c-1.65,0.002-2.989,1.34-2.991,2.992V21.98
                    c0.002,1.652,1.341,2.99,2.991,2.992h7.785l-0.665,1.994H6.028c-0.826,0-1.496,0.67-1.496,1.496s0.67,1.496,1.496,1.496h19.944
                    c0.824,0,1.496-0.67,1.496-1.496s-0.672-1.496-1.496-1.496h-5.625l-0.664-1.994H27.469z M16.001,1.04
                    c2.753,0,4.985,2.232,4.985,4.986c0,2.753-2.232,4.986-4.985,4.986c-2.754,0-4.986-2.232-4.986-4.986
                    C11.018,3.273,13.249,1.043,16.001,1.04z M4.532,4.53h5.685c-0.435,1.639-0.144,3.386,0.798,4.796v3.321l-2.113,0.704
                    c-0.366-1.032-1.5-1.571-2.531-1.205c-1.032,0.367-1.572,1.5-1.206,2.532c0.366,1.033,1.5,1.57,2.532,1.205
                    c0.668-0.236,1.158-0.812,1.287-1.508l2.688-0.897c0.203-0.067,0.34-0.257,0.341-0.471v-2.533c0.712,0.64,1.567,1.099,2.493,1.339
                    v2.921l-0.898,0.599c-0.938-0.64-2.216-0.398-2.855,0.541c-0.64,0.938-0.398,2.217,0.541,2.855
                    c0.938,0.641,2.217,0.398,2.855-0.541c0.427-0.623,0.476-1.432,0.127-2.104l1.006-0.669c0.139-0.091,0.222-0.248,0.222-0.415
                    v-3.017c0.165,0.014,0.33,0.026,0.499,0.026c0.168,0,0.332-0.012,0.498-0.026v2.02c0,0.166,0.082,0.322,0.222,0.415l1.006,0.67
                    c-0.15,0.28-0.229,0.592-0.23,0.908c-0.004,1.105,0.889,2.004,1.994,2.008s2.002-0.889,2.006-1.994
                    c0.004-1.104-0.889-2.002-1.994-2.006c-0.393-0.001-0.779,0.114-1.107,0.333l-0.898-0.599v-1.924c0.926-0.24,1.781-0.7,2.492-1.339
                    v2.034c0,0.189,0.107,0.362,0.275,0.447l2.75,1.375c0.182,1.082,1.207,1.814,2.293,1.632c1.082-0.184,1.813-1.209,1.629-2.292
                    c-0.18-1.084-1.207-1.815-2.289-1.633c-0.688,0.115-1.266,0.583-1.521,1.232l-2.139-1.069V9.326
                    c0.943-1.41,1.236-3.157,0.801-4.796h5.682c1.1,0,1.992,0.893,1.992,1.995v13.461H2.538V6.525C2.538,5.423,3.432,4.53,4.532,4.53z
                    M8.023,14.003C8.023,14.555,7.576,15,7.025,15c-0.55,0-0.997-0.445-0.997-0.997c0-0.55,0.447-0.997,0.997-0.997
                    C7.576,13.006,8.023,13.453,8.023,14.003z M13.508,16.994c0,0.551-0.446,0.998-0.997,0.998s-0.998-0.447-0.998-0.998
                    c0-0.549,0.447-0.998,0.998-0.998S13.508,16.445,13.508,16.994z M19.49,15c0.551,0,0.996,0.446,0.996,0.996
                    c0,0.553-0.445,0.998-0.996,0.998c-0.549,0-0.998-0.445-0.998-0.998C18.492,15.446,18.941,15,19.49,15z M23.979,14.003
                    c0-0.55,0.445-0.997,0.996-0.997c0.553,0,0.998,0.447,0.998,0.997c0,0.551-0.445,0.997-0.998,0.997
                    C24.424,15,23.979,14.555,23.979,14.003z M2.538,21.98v-0.996h26.923v0.996c0,1.102-0.893,1.994-1.992,1.994H4.532
                    C3.432,23.975,2.538,23.082,2.538,21.98z M26.471,28.463c0,0.275-0.223,0.498-0.498,0.498H6.028c-0.275,0-0.498-0.223-0.498-0.498
                    s0.223-0.5,0.498-0.5h19.944C26.248,27.963,26.471,28.188,26.471,28.463z M19.297,26.967h-6.593l0.665-1.994h5.264L19.297,26.967z"
                    />
                <rect x="15.503" y="21.98" width="0.996" height="0.998"/>
                <path d="M15.502,3.74v3.283h0.997V3.74l1.144,1.143l0.705-0.705l-1.994-1.994c-0.195-0.195-0.511-0.195-0.706,0
                    l-1.994,1.994l0.705,0.705L15.502,3.74z"/>
                <path d="M13.508,9.018h4.984c0.277,0,0.5-0.223,0.5-0.499V7.023h-0.998V8.02h-3.987V7.023h-0.998v1.496
                    C13.009,8.794,13.232,9.018,13.508,9.018z"/>
                <rect x="4.034" y="8.02" width="4.488" height="0.998"/>
                <rect x="4.034" y="10.015" width="2.991" height="0.998"/>
                <rect x="6.028" y="6.026" width="2.494" height="0.997"/>
                <rect x="4.034" y="6.026" width="0.997" height="0.997"/>
                <rect x="4.034" y="17.494" width="0.997" height="0.996"/>
                <rect x="6.028" y="17.494" width="0.997" height="0.996"/>
                <rect x="8.023" y="17.494" width="0.997" height="0.996"/>
                <rect x="26.969" y="17.494" width="0.998" height="0.996"/>
                <rect x="24.975" y="17.494" width="0.998" height="0.996"/>
                <rect x="22.98" y="17.494" width="0.998" height="0.996"/>
                <rect x="23.48" y="8.02" width="4.486" height="0.998"/>
                <rect x="23.48" y="10.015" width="2.99" height="0.998"/>
                <rect x="25.475" y="6.026" width="2.492" height="0.997"/>
                <rect x="23.48" y="6.026" width="0.996" height="0.997"/>
            </g>
        </SvgIcon>
    )
}