import React from 'react'

import { IconButton, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import CloseIcon from '@material-ui/icons/Close'

import { isDefined } from '../../utils'

import { F3MSnackbarProps } from './types'

import useStyles from './styles'

const F3MSnackbar = (props: F3MSnackbarProps) => {

    const classes = useStyles()

    const Close = () => {
        return (
            <IconButton size='small' color='inherit' onClick={closeSnackbar}>
                    <CloseIcon fontSize='small' />
                </IconButton>
        )
    }

    let snackbarProps: F3MSnackbarProps = {
        
        // ACTION
        action: isDefined(props.action) ? props.action : <Close/>,

        // Default ACHOR ORIGIN BOTTOM RIGHT
        anchorOrigin: isDefined(props.anchorOrigin) ? props.anchorOrigin : { vertical: 'bottom', horizontal: 'right' },

        // Default AUTO HIDE DURATION 6000
        autoHideDuration: isDefined(props.autoHideDuration) ? props.autoHideDuration : 6000,

        setSnackbar: props.setSnackbar,

        snackbar: props.snackbar
    }

    const closeSnackbar = (event?: React.SyntheticEvent<Element, Event> | undefined, reason?: string | undefined) => {
        if(reason !== 'clickaway') snackbarProps.setSnackbar(prevState => ({ ...prevState, open: false }))
    }

    return (
        <Snackbar
            anchorOrigin     = {snackbarProps.anchorOrigin}
            autoHideDuration = {snackbarProps.autoHideDuration}
            onClose          = {closeSnackbar}
            open             = {snackbarProps.snackbar.open}

        >
            <Alert
                className = {classes.alertRoot}
                severity  = {snackbarProps.snackbar.severity}
                elevation = {6}
                variant   = 'filled'
                onClose   = {closeSnackbar}
                action    = {snackbarProps.action}
            >
                {snackbarProps.snackbar.message}
            </Alert>
        </Snackbar>
    )
}

export default F3MSnackbar